import React, { Component, Fragment } from 'react';
import Highchart from './Highchart';

class PieChart extends Component {
    render() {
        const props = this.props;
        const options = {
            chart: {
                backgroundColor: null,
                type: 'pie',
                height: '100%',
                animation: true,
            },
            credits: {
                enabled: false
            },
            title: {
                text: props.title,
                verticalAlign: 'middle',
                y: 5,
                style: {
                    fontSize: '18px'
                }
            },
            subtitle: {
                text: props.subtitle,
                verticalAlign: 'middle',
                y: props.title === '' ? 5 : 25,
                style: {
                    fontSize: '14px'
                }
            },
            tooltip: {
                valuePrefix: '$',
                backgroundColor: '#1a1a1a',
                borderColor: '#1a1a1a',
                borderRadius: 5,
                valueDecimals: 2,
                style: {
                    color: 'white'
                },
                headerFormat: '<span style="border-radius:2px; font-size: 12px">{point.key} ',
                pointFormat: ' {point.y} ({point.percentage:.0f}%)</span>',
                enabled: props.hover,
                formatter: function () {
                    return this.key !== 'Hide' ? this.key+' $'+this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})+' ('+Math.round(this.percentage * 100) / 100+'%)' : false;
                }
            },
            plotOptions: {
                pie: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    },
                    shadow: false,
                    center: ['50%', '50%'],
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                },
                series: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    }
                }
            },
            series: [{
                animation: {
                    duration: 750,
                    easing: 'easeOutQuad'
                },
                name: 'Series 1',
                data: props.data,
                size: '105%',
                innerSize: '94%',
                showInLegend: false
            }]
        };
        return (
            <Fragment>
                <Highchart options={options} container={this.props.id} />
            </Fragment>
        );
    }
}

export default PieChart;