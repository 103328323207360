import React, { Fragment, useEffect, useState } from 'react';
import { Card, Row, Col, Button, Input } from 'reactstrap';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { MdCloudUpload, MdDelete, MdInsertDriveFile } from 'react-icons/md';
import '../OrderGuideList/OrderGuideUpload.scss';
import * as $ from 'jquery';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useOrderGuide from '../useOrderGuide';
import moment from 'moment';
import Moment from 'react-moment';
import useInput from '../../../../../SharedComponents/SharedEffects/useInput';
import numeral from 'numeral';


const OrderGuideDetailRow = (props) => {
  
    const { item, mode, items, setItems } = props;
    const [detPrice, setDetPrice] = useState(item.price);
    const [detUnitPrice, setDetUnitPrice] = useState(item.unit_price);

    const priceInput = useInput.useText('', { value: detPrice, set: (v) => setNewPrice(v) });
    const unitPriceInput = useInput.useText('', { value: detUnitPrice, set: (v) => setNewUnitPrice(v) });
    
    const setNewPrice = (v) => {
        const nUnitPrice = v / (item.unit_quantity * item.unit_size);

        const newItems = items.map((i, index) => {
            if(i.item_distributor_id !==item.item_distributor_id) {return i;}
            return {
                ...i,
                price: v,
                unit_price: nUnitPrice
            }
        });
        setDetPrice(v);
        setDetUnitPrice(nUnitPrice);
        setItems(newItems);
    };

    const setNewUnitPrice = (v) => {
        const nPrice = v * (item.unit_quantity * item.unit_size);

        const newItems = items.map((i, index) => {
            if(i.item_distributor_id !==item.item_distributor_id) {return i;}
            return {
                ...i,
                price: nPrice,
                unit_price: v
            }
        });
        setDetUnitPrice(v);
        setDetPrice(nPrice);
        setItems(newItems);
    };
    
  
    return (
        <Card>
            <Row className='px-4 py-3 text-left' style={{fontSize:'14px'}}>
                <Col xs={1}> {item.din} </Col>
                <Col xs={4} className='text-capitalize text-truncate'>{item.name}</Col>
                <Col xs={1} className='text-lowercase px-0'> {item.unit_measure} </Col>
                <Col xs={2} className='pr-5 '>
                    <ShowIf show={mode}>
                        <Input type='number' min="0" value={priceInput.value} onChange={priceInput.set} />
                    </ShowIf>
                    <ShowIf show={!mode}>
                        {numeral(priceInput.value).format('$0,0.00')}
                    </ShowIf>
                </Col>
                <Col xs={1} className='text-lowercase'> {item.pack_size} </Col>
                <Col xs={1} >
                    <ShowIf show={mode}>
                        <Input type='number' min="0" value={unitPriceInput.value} onChange={unitPriceInput.set} />                        
                    </ShowIf>
                    <ShowIf show={!mode}>
                        {numeral(unitPriceInput.value).format('$0,0.00')}
                    </ShowIf>
                </Col>
                {/* <Col xs={1} className='text-center'> {item.is_catch_weight ? 'Yes' : 'No'} </Col> */}
                <Col xs={2}> {item.brand} </Col>                
            </Row>
        </Card>        
    );
};

export default OrderGuideDetailRow;