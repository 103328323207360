import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useHeader from './useHeader';
import { Row, Container } from 'reactstrap';
import HeaderMenuButton from './HeaderMenuButton';
import HeaderSubHeader from './HeaderSubHeader';
import { mobileClassName } from '../../config/constants';
import HeaderLogo from './HeaderLogo';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import HeaderTitle from './HeaderTitle';
import HeaderRightButton from './HeaderRightButton';
import HeaderBackButton from './HeaderBackButton';
import HeaderTimespan from './HeaderTimespan';
import * as $ from 'jquery';


const Header = (props) => {
  const [height, setHeight] = useState(0);
  const isSidebarOpen = useSelector(({ userInterface }) => userInterface.isSidebarOpen);
  
  const {
    showLogo, showSearchbar, showCart, children, showSearchFilters,
    title, rightButton, showMenu, showBack, showReviewOrder, backFunct, timespan,
    showTitle
  } = props;

  // const logo = useSelector(state => state.company.logo);
  const activeLocation = useSelector(({ user }) => user.activeLocation);
  const companyLogoUrl = activeLocation && activeLocation.company && activeLocation.company.logo_url;
  const {
    clickMenu, enterSearch, clearSearch,
    setSearch, search, clickBack
  } = useHeader.useActions();

  const searchProps = {
    show: showSearchbar, setSearch,
    search, clearSearch, enterSearch,
    showFilters: showSearchFilters
  };

  useEffect(() => {
    const h = $('.header-container').height();
    setHeight(h);
  }, [children]);

  return (
    <div className={'header-relative' + (isSidebarOpen ? ' sidebar-is-open' : '')} style={{ height: `${height}px` }}>
      <Container className='header-container p-0' fluid={true}>
        <Row className={'header m-0 align-items-center' + mobileClassName}>
          <HeaderMenuButton show={showMenu} clickMenu={clickMenu} />
          <HeaderBackButton show={showBack} clickBack={backFunct ? backFunct : clickBack} />
          <HeaderLogo show={showLogo} />
          <HeaderTitle title={title} showTitle={showTitle} />
          <HeaderRightButton show={rightButton ? true : false} {...rightButton} />
          <HeaderTimespan show={timespan ? true : false} {...timespan} />          
        </Row>
        <HeaderSubHeader show={children ? true : false} subHeaders={children} />
      </Container>
    </div>
  );
};

const buttonShape = PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  onClick: PropTypes.func
});

const rightButtonShape = PropTypes.shape({
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  button: PropTypes.oneOfType([buttonShape, PropTypes.arrayOf(buttonShape)])
});

Header.propTypes = {
  showLogo: PropTypes.bool,
  showMenu: PropTypes.bool,
  showBack: PropTypes.bool,
  showSearchbar: PropTypes.bool,
  showSearchFilters: PropTypes.bool,
  showCart: PropTypes.bool,
  title: PropTypes.string,
  rightButton: rightButtonShape
};

Header.defaultProps = {
  showLogo: isMobile ? false : true,
  showSearchbar: false,
  showMenu: true,
  showBack: false,
  showSearchFilters: true,
  showCart: true,
  title: null,
  rightButton: null
};

export default Header;