import React from 'react';
import { Col, Button, Form, Row } from 'reactstrap';
import AdminLoginFormDropdown from './AdminLoginFormDropdown';
import { useHistory } from 'react-router-dom';
import useAdminLogin from './useAdminLogin';

const AdminLoginForm = (props) => {
  
  const history = useHistory();

  const {
    distributors, setDistributorSearch, setDistributor, distOptions,
    users, setUser, distributor, onSubmit, submitDisabled,
  } = useAdminLogin();

  const goToLogout = () => {
    history.push('/logout');
  };

  return (
    <Form className='admin-login-form flex-column m-auto' noValidate="novalidate" onSubmit={onSubmit}>
      <AdminLoginFormDropdown label='Distributors' placeholder='Select Distributor...' options={distOptions} setSearch={setDistributorSearch} set={setDistributor} />
      <AdminLoginFormDropdown label='Users' placeholder='Select user...' options={users} set={setUser} isDisabled={distributor ? false : true} />
      <Row className='m-0 text-right mt-4 justify-content-end'>
        <Col className='w-fc'>
          <Button disabled={submitDisabled()} type='submit' color='green' size='lg' block>Login As</Button>
        </Col>
        <Col className='w-fc'>
          <Button onClick={goToLogout} color='secondary' size='lg' block>Logout</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminLoginForm;