import React from 'react';
import { Container } from 'reactstrap';
import LoginHeader from '../Login/LoginHeader';
import logo from '../../assets/images/foodbam-logo.png';
import useLogin from '../Login/useLogin';
import SignupForm from './SignupForm';

const Signup = (props) => {
    const formProps = useLogin.useForm(props);
    

    return (
        <Container fluid={true} className='login-app p-0'>
            <LoginHeader logo={logo} />
            <SignupForm {...formProps} />
        </Container>
    );
};

export default Signup;
