import React from 'react';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import './Login.scss';
import LoginHeader from './LoginHeader';
import LoginForm from './LoginForm';
import useLogin from './useLogin';
import logo from '../../assets/images/foodbam-logo.png';

const Login = (props) => {
  const formProps = useLogin.useForm();

  return (
    <Container fluid={true} className='login-app p-0'>
      <LoginHeader logo={logo} />
      <LoginForm {...formProps} />
    </Container>
  );
};

export default Login;