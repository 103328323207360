import React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import orderGuideApi from '../../../../api/orderGuideApi';
import merchantApi from '../../../../api/merchantApi';
import distributorApi from '../../../../api/distributorsApi';
import { showModal } from "../../../../reducers/modals/modalsActions";
import { useSelector, useDispatch } from "react-redux";
import AlertModal from '../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { OGlimitOptions, OGExpirationDays } from "../../../../config/constants";
import OrderGuideDetailRow from './OrderGuideDetail/OrderGuideDetailRow';
import moment from 'moment';
import { localStorageConst } from '../../../../config/constants';

const useMerchants = (props) => {
  const [merchants, setMerchants] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [merchantDistributor, setMerchantDistributor] = useState([]);

  const distributorLocal = JSON.parse(localStorage.getItem(localStorageConst.keys.distributor));
  const distributorStore = useSelector(({ user }) => user.distributor);
  const merchantData = useSelector(({ dashboard }) => dashboard.data);  

  const getDistributorMerchants = async () => {

    await distributorApi.retrieveFromUser()
    .then((res) => {
      setDistributor(res.data.data);
      return merchantApi.getList();
    })
    .then((res) => {
      setMerchants(res.data.data);
    })
    .catch(error => { console.log(error) });

  };

  const getDistributorPartnerMerchants = () => {

    setDistributor(distributorStore);
    const newMerchants = merchantData.map(m => {
        return {                
            id: m.merchantId,
            nickname: m.merchant
        }
    });
    setMerchants(newMerchants);   

  };

  useEffect(() => {

    const distributor = !!distributorLocal ? distributorLocal : distributorStore;

    // if(distributor.isPartner){
    //   getDistributorPartnerMerchants();
    // }else{
      getDistributorMerchants();
    // }
    
  }, []);


  return { merchants, setMerchants, distributor, setDistributor, merchantDistributor, setMerchantDistributor };
}

const useApi = (distributorId, merchantId) => {

  const [orderGuideList, setOrderGuideList] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getOrderGuides = (newPage) => {
    if(distributorId){
      const nPage = (newPage) ? newPage : page;
      setIsLoading(true);
      orderGuideApi.getOrderGuide(distributorId, merchantId, nPage, OGlimitOptions).then((res) => {
          const result = res.data.data;
          if(nPage===1){
            setOrderGuideList(result.data);
          }else{
            setOrderGuideList(orderGuideList.concat(result.data));
          }
          setPage(nPage+1);
          setLoadMore(result._meta.pageCount > result._meta.currentPage);
          setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
    }
  };

  const resetOrderGuides = () => {
      setOrderGuideList([]);
      getOrderGuides(1);
  };

  useEffect(() => {
    getOrderGuides();
  }, []);


  return { orderGuideList, setOrderGuideList, page, setPage, isLoading, loadMore, getOrderGuides, resetOrderGuides };
}

const useDetailApi = (id) => {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [days, setDays] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const view = async () => {
    await orderGuideApi.getDetail(id).then((res) => {
      const result = res.data.data;
      setData(result);

      const nTitleDist = result.distributor.nickname;
      let nTitleMerch='';
      if(result.is_multiple){
        result.detail.map((m, i) =>
          nTitleMerch += m.merchant.nickname + (result.detail.length-1 === i ? '' : ', ')
        );
      }else{
        nTitleMerch = result.merchant.nickname;
      }

      const nTitle = nTitleDist; // + ' - ' + nTitleMerch;
      setTitle(nTitle);

      const newDays = result.is_multiple ? result.template.days_to_expire : OGExpirationDays;
      setDays(newDays);

      const newEndDate = moment(startDate).add(newDays, 'days');
      setEndDate(moment(newEndDate).toDate());

    })
    .catch(error => {
    });
  };

  useEffect(() => {
    view();
  }, []);


  return { data, title, days, startDate, endDate, setStartDate, setEndDate, view };
}


const useItemsApi = (id) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const getItems = () => {
    if(page>pageCount){ return;}
    const params = {
      page : page,
      limit : OGlimitOptions
    };
    setIsLoading(true);
    orderGuideApi.getItems(id, params).then((res) => {
      const result = res.data.data;
      setItems(items.concat(result.data));
      setPage(page+1);
      setPageCount(result._meta.pageCount);
      setLoadMore(result._meta.pageCount > result._meta.currentPage);
      setIsLoading(false);
    })
    .catch(error => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getItems(page);
  }, []);

  useEffect(() => {
    if(loadMore && !isLoading){
      getItems();
    }
  }, [page, isLoading]);


  return { items, setItems, getItems, page, isLoading, loadMore };
}

// const useItemRow = (props) => {
//   const {item, items, setItems} = props;
//   const dispatch = useDispatch();


//   const saveItem = (item) => {

//   };

//   return {
//     saveItem
//   }

// }

export default {
  useMerchants, useApi, useDetailApi, useItemsApi, //useItemRow
};
