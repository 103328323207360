import React, { Component } from 'react';
import FakeDropdown from '../../../../../../SharedComponents/FakeDropdown/_FakeDropdown';
import { Row, Col } from 'reactstrap';
// import sort from '../../../../assets/images/sort.png';
// import Ionicon from 'react-ionicons';
import '../../../../../../config/css/Basic.css';

class mDetailsSubheader extends Component {   
   
    render() {
        const {timespan, itemsPage} = this.props;
        const timespanTitle = timespan === 'yearly' ? 'Year to Date' : (timespan === 'monthly' ? 'Monthly' : (timespan === 'weekly' ? 'Weekly' : 'Custom'));
        const itemsPageTitle = itemsPage === 'itemsmissing' ? 'Items Missing' : (itemsPage === 'itemslost' ? 'Items Lost' : 'Items Won');
        return (
            <div className='sub-header container-fluid mobile'>
                <div className='sub-header-container row'>
                    <div className='col row'>
                        <FakeDropdown title={itemsPageTitle} modalId='ItemsPageModal' />
                        <div className='pad-sides-5'></div>
                        <FakeDropdown title={timespanTitle} modalId='ShowByOnDetailsModal'/>
                    </div>
                    <div className='pad-sides-5'></div>
                    <div className='col-2 fake-dropdown pad-sides-5 text-center' data-toggle="modal" data-target='#ShowMoreOptionsModal'>...</div>                    
                </div>
            </div>
        );
    }
}

export default mDetailsSubheader;