import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
// import { showHeaderLogo, showHeaderBack, showHeaderHomeButtons, setHeaderTitle, setHeaderBackUrl } from '../../../actions/headerActions';
// import { hideDesktopSubheader, hideMobileSubheader } from '../../../actions/subheaderActions';
import noImage from '../../../../../assets/images/no-image1.png';
import purchasedTag from '../../../../../assets/images/green-bookmark.png';
import orderGuideTag from '../../../../../assets/images/red-bookmark.png';

class ItemDetail extends Component {
    componentDidMount() {
        const action = this.props.dispatch;
        // action(showHeaderLogo(false));
        // action(showHeaderBack(true));
        // action(showHeaderHomeButtons(false));
        // action(setHeaderTitle('Item Details'));
        // action(hideDesktopSubheader());
        // action(hideMobileSubheader());
        // action(setHeaderBackUrl(null));
    }

    render() {
        const dashboardClass = isMobile ? 'container-fluid has-header mobile pad-sides-20' : 'container-fluid content-container has-header pad-sides-20';
        const item = JSON.parse(localStorage.getItem('itemDetail'));
        const lastPurchasedDate = moment(item.lastPurchasedDate);

        if (isMobile) {
            return (
                <div className={dashboardClass}>
                    <div className="row">
                        <div className='col-sm-12 pad-sides-10'>
                            <div className='client-card mobile no-padding pad-bottom-15'>
                                <div className="col-12 mar-bottom-10">
                                    {item.type === 'order_guide' ? <span className='tag'><img src={orderGuideTag} alt="Order Guide Tag" /> In your order guide</span> : null}
                                    {item.type === 'order_guide' && item.isPurchased ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : null}
                                    {item.isPurchased ? <span className='tag'><img src={purchasedTag} alt="Purchased Tag" /> Purchased</span> : null}
                                </div>
                                <div className="col-12 text-center">
                                    {!!item.imageUrl ? <img className='img-thumb-80' src={item.imageUrl} alt={item.description} /> :
                                        <img className='img-thumb-80' src={noImage} alt="none" />}
                                </div>

                                <div className="col-12 mar-top-15">
                                    <div className="row">
                                        <div className="col-12">{item.description}</div>
                                    </div>

                                    <div className="row mar-top-15">
                                        <div className="col-4 text-light-gray header-title">Brand</div>
                                    </div>

                                    <div className="row mar-top-15 body">
                                        <div className="col-4">{item.brand}</div>
                                    </div>

                                    <div className="row mar-top-15">
                                        <div className="col-4">Pack size</div>
                                        <div className="col-4">{item.packSize}</div>
                                    </div>

                                    <div className="row mar-top-15">
                                        <div className="col-4">{item.price}</div>
                                    </div>
                                </div>
                                <div className="col-12 text-right border-top mar-top-30 pad-vertical-10">
                                    <span className='tag'><img src={purchasedTag} alt="Purchased Tag" /> Last Purchased</span>
                                    <span>&nbsp;&nbsp;</span>
                                    <span className='tag'>{lastPurchasedDate.format('DD/MM/YYYY')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={dashboardClass}>
                    <div className="row mar-top-30">
                        <div className='col-sm-12 pad-sides-10'>
                            <div className='client-card row no-padding pad-bottom-15'>
                                <div className="col-12  mar-bottom-15">
                                    { item.type === 'order_guide' ? <span className='tag'>In your order guide <img src={orderGuideTag} alt="Order Guide Tag" /></span> : null }
                                    { item.type === 'order_guide' && item.isPurchased ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : null }
                                    { item.isPurchased ? <span className='tag'>Purchased <img src={purchasedTag} alt="Purchased Tag" /></span> : null }
                                </div>
                                <div className="col-2 text-center">
                                    {!!item.imageUrl ? <img className='img-thumb-80' src={item.imageUrl} alt={item.description} /> :
                                        <img className='img-thumb-80' src={noImage} alt="none" />}
                                </div>
                                <div className="col-10">
                                    <div className="row">
                                        <div className="col-12 font-regular text-capitalize"><h4>{item.description}</h4></div>
                                    </div>

                                    <div className="row mar-top-15">
                                        <div className="col-3 font-light-bold">Brand</div>
                                        <div className="col-4 font-regular">{item.brand}</div>
                                    </div>

                                    <div className="row mar-top-15">
                                        <div className="col-3 font-light-bold">Pack size</div>
                                        <div className="col-4 font-regular">{item.packSize}</div>
                                    </div>
                                </div>
                                <div className="col-12 text-right border-top mar-top-30 pad-vertical-10">
                                    <span className='tag'><img src={purchasedTag} alt="Purchased Tag" /> Last Purchased</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span className='tag'>{lastPurchasedDate.format('MM/DD/YYYY')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.dashboard.detailData,
        merchantId: state.dashboard.merchantId,
        timespan: state.userInterface.timespan,
        itemsPage: state.userInterface.itemsPage
    }
}

export default connect(mapStateToProps)(ItemDetail);
