import { createStore } from 'redux';
import { combineReducers } from 'redux';
import userInterface from './userInterface/userInterfaceReducer';
import user from './user/userReducer';
import modals from './modals/modalsReducer';
import header from './header/headerReducer';
import dashboard from './dashboard/dashboardReducer';
import orderGuide from './orderGuide/orderGuideReducer';
import distributors from './distributors/distributorsReducer';

const reducer = combineReducers({
  userInterface, user, modals, header, dashboard, orderGuide, distributors
});

export default createStore(reducer);