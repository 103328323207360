import axios from "axios";

const authenticate = (email, password) => {
  return axios.post('/site/authenticate', {
    email: email,
    password: password,
    secret: "dpWebApp"
  });
};

const adminAuth = (distributorId, userId) => {
  return axios.post('/site/admin-authentication', {
    userId: userId,
    distributorId: distributorId
  });
};
const addSignup = (password, mdsrId) => {
  return axios.post('/users', {
    password,
    mdsrId
  });
};


const distributorList = (token = false, search) => {
  if (!token) {
    token = localStorage.getItem('authKey');
  }

  const params = {nickname: search};

  return axios.get('/distributors', {params},{
    headers: {
      "Authorization": 'Bearer ' + token
    }
  });

}

const userList = (token, distributorId) => {

  if (!token) {
    token = localStorage.getItem('authKey');
  }

  return axios.get('/distributors/user-list/' + distributorId, {
    headers: {
      "Authorization": 'Bearer ' + token
    }
  });

}

export default {
  authenticate,
  adminAuth,
  distributorList,
  userList,
  addSignup
};