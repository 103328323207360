import React, { Component } from 'react';
import arrowDown from '../../assets/images/drop-down.png';
import './FakeDropdown.scss';

class FakeDropdown extends Component {
    render() {
        const props = this.props;
        return (
            <div className='col fake-dropdown' data-toggle="modal" data-target={'#'+props.modalId}>
                <span>{props.title}</span> <img src={arrowDown} alt='dropdown arrow' />
            </div>
        );
    }
}

export default FakeDropdown;