import React, { Component } from 'react';
import moment from 'moment';
import ModalBody from '../_ModalBody';
import { isMobile } from 'react-device-detect';
import CalendarDay from './_CalendarDay';
import { Row, Col } from 'reactstrap';

class CalendarWeeks extends Component {
    constructor(props) {
        super(props);
        this.refDays = [];
    }
    weeks = (date) => {
        const n = moment.duration(moment(date).endOf('month') - moment(date).startOf('month')).weeks() + 1;
        return n;
    }
    getCalendar = (cdate) => {
        const year = moment(cdate).year();
        const month = moment(cdate).format('MM');
        const cMonth = moment(cdate).get('month')+1;
        const date = moment(year+'-'+month+'-01');
        const daysInMonth = moment(date).daysInMonth();
        const dayStart = moment(date).day();
        const prevMonth = moment(date).subtract(1, 'months');
        const daysInPrevMonth = moment(prevMonth).daysInMonth();
        const weeks = this.weeks(date);
        const calendar = new Array(weeks);
        const squares = new Array(daysInMonth+dayStart);

        this.refDays = [];
        let d = 1-dayStart;
        for (let s = 0; s < squares.length; s++) {
            let dd;
            let gray = false;
            if (d < 1) {
                dd = daysInPrevMonth + d;
                gray = true;
            } else {
                dd = d;
            }
            squares[s] = {
                square: s,
                date: dd,
                gray: gray
            };
            this.refDays[s] = React.createRef();

            if (!gray){
                for (let item = 0; item < this.props.data.length; item++) {
                    const elem = this.props.data[item];
                    if (elem.month === cMonth && elem.year === year) {
                        if (elem.day === dd) {
                            squares[s].haveData = true;
                            squares[s].month = elem.month;
                            squares[s].spend = '$'+(Math.round(elem.volume * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                            squares[s].items = elem.quantity + ' ' +elem.unit_measure;
                            squares[s].price = '$'+(Math.round(elem.price * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' ' +elem.unit_measure;
                        }
                    }
                }
            }
            d ++;
        }

        for (let w = 0; w < calendar.length; w++) {
            calendar[w] = squares.slice(w+6*w, (w+6*w)+7)
            if (calendar[w].length < 7) {
                let c = 1;
                for (let i = calendar[w].length; i < 7; i++) {
                    calendar[w][i]= {
                        date: c,
                        gray: true
                    };
                    c++;
                }
            }
        }
        return calendar;
    };
    pickDate = (props, square) => {
        this.props.pickDate(props);
        this.unselectAll();
        this.refDays[square].current.select();
    };
    unselectAll = () => {
        for (let s = 0; s < this.refDays.length; s++) {
            if (this.refDays[s].current !== null) {
                this.refDays[s].current.unselect();    
            }
        }
    }
    render() {
        const rowStyle = isMobile ? 'm-0 align-items-center px-3' : 'm-0 border-bottom';
        const calendar = this.getCalendar(this.props.date);

        const calendarMap = calendar.map((c, i) =>
            <Row key={i} className={rowStyle}>
                <CalendarDay ref={this.refDays[c[0].square]} {...c[0]} onClick={this.pickDate} />
                <CalendarDay ref={this.refDays[c[1].square]} {...c[1]} onClick={this.pickDate} />
                <CalendarDay ref={this.refDays[c[2].square]} {...c[2]} onClick={this.pickDate} />
                <CalendarDay ref={this.refDays[c[3].square]} {...c[3]} onClick={this.pickDate} />
                <CalendarDay ref={this.refDays[c[4].square]} {...c[4]} onClick={this.pickDate} />
                <CalendarDay ref={this.refDays[c[5].square]} {...c[5]} onClick={this.pickDate} />
                <CalendarDay ref={this.refDays[c[6].square]} {...c[6]} onClick={this.pickDate} noBorder />
            </Row>
        )
        if (isMobile) {
            return (
                <div className='fb-background-white pad-bottom-15 border-bottom'>
                    {calendarMap}
                </div>
            );
        } else {
            return (
                <ModalBody customClass='m-0 p-0'>
                    {calendarMap}
                </ModalBody>
            );
        }
    }
}

export default CalendarWeeks;