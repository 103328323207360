import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
// import Ionicon from 'react-ionicons';

class CalendarDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false
        };
    };
    pickDate = () => {
        if (this.props.haveData) {
            this.props.onClick(this.props, this.props.square);
        }
    };
    unselect = () => {
        this.setState({
            selected: false
        });
    };
    select = () => {
        this.setState({
            selected: true
        });
    };
    render() {
        if (isMobile) {
            const style = 'col pad-top-15 calendar-height mobile';
            const gray = this.props.gray ? 'text-center text-gray size-12' : 'text-center size-12'
            const selectedStyle = {
                background: '#eef3f6',
                width: '20px',
                height: '20px',
                position: 'absolute',
                borderRadius: '20px',
                right: '50%',
                transform: 'translateX(50%)'
            }
            return (
                <div className={style} onClick={this.pickDate}>
                    <div className={gray}>
                        { this.state.selected ? <div style={selectedStyle} >{this.props.date}</div> : null}
                        {this.props.date}
                    </div>
                    <div className='text-center'>
                        { this.props.haveData ? 
                            <svg className='img-middle' version="1.1" id="Layer_1" x="0px" y="0px"
                                width="8px" height="8px" viewBox="0 0 8 8" enableBackground="new 0 0 8 8">
                                <circle cx="4" cy="4" r="4" fill='#feba5d'/>
                            </svg> : null 
                        }
                    </div>
                </div>
            );
        } else {
            const style = this.props.noBorder ? 'pad-vertical-20 calendar-height' : 'pad-vertical-20 border-right calendar-height';
            const gray = this.props.gray ? 'pad-sides-20 text-right text-gray' : 'pad-sides-20 text-right'
            return (
                <Col className={style}>
                    <div className={gray}>{this.props.date}</div>
                    { this.props.haveData ? 
                        <div className='pad-vertical-5 pad-sides-5'>
                            <svg className='img-middle' version="1.1" id="Layer_1" x="0px" y="0px"
                                width="14px" height="14px" viewBox="0 0 14 14" enableBackground="new 0 0 14 14">
                                <circle cx="7" cy="7" r="7" fill='#feba5d'/>
                            </svg>
                            <span>&nbsp;&nbsp;</span>{this.props.items}
                        </div> : null
                    }
                    {/* { this.props.haveData ? 
                        <div className='pad-vertical-5 pad-sides-5'>
                            <Ionicon className='img-middle' icon='md-pricetag' fontSize='16px' color='#a6b7c4'/>
                            <span>&nbsp;&nbsp;</span>{this.props.price}
                        </div> : null
                    }
                    { this.props.haveData ? 
                        <div className='pad-vertical-5 pad-sides-5'>
                            <Ionicon className='img-middle' icon='md-cart' fontSize='16px' color='#a6b7c4'/>
                            <span>&nbsp;&nbsp;</span>{this.props.spend}
                        </div> : null
                    } */}
                </Col>
            );
        }
    }
}

export default CalendarDay;