export default function reducer(state = {
  spinner: 0,
  isSidebarOpen: false,
  size: {
    width: window.innerWidth,
    //height: innerHeight()
  },

  timespan: 'monthly',
  startDate: '',
  endDate: '',
  merchant: [],
  itemsPage: 'itemsmissing',
  spinner: false,
  messages: [],
  isGuess: true,
  showInGraph : '$',
  sort: 'total_quantity', //total_quantity = Total Cases, total_purchased = Total Spend, unitPrice = Competitive Unit Price, price = Competitive Price
  asc: true

}, action) {
  switch (action.type) {
    case 'SET_SPINNER':
      return { ...state, spinner: action.payload ? state.spinner + 1 : state.spinner - 1};
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case 'CLOSE_SIDEBAR':
      return { ...state, isSidebarOpen: false };
    case 'SET_SIZE':
      return { ...state, size: action.payload };

    
    case 'SET_TIMESPAN':
        return { ...state, timespan: action.payload };
    case 'SET_STARTDATE':
      return { ...state, startDate: action.payload };
    case 'SET_ENDDATE':
      return { ...state, endDate: action.payload };
    case 'SET_MERCHANT':
      return { ...state, merchant: action.payload };
    case 'SET_PAGE':
        return { ...state, itemsPage: action.payload };
    case 'SET_SPINNER':
        return { ...state, spinner: action.payload };
    case 'SET_MESSAGES':
        return { ...state, messages: action.payload };
    case 'SET_IS_GUESS':
        return { ...state, isGuess: action.payload };
    case 'SET_SORT':
        return { ...state, sort: action.payload.sort, asc: action.payload.asc };
    case 'SET_SHOW_IN_GRAPH':
        return {...state, showInGraph:action.payload };

    default:
      return state;
  }
};
