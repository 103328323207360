import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ExtraLargeModal from '../ExtraLargeModal';
import ModalBody from '../_ModalBody';
import { isMobile } from 'react-device-detect';
import ModalHeader from '../_ModalHeader';
import ShowIf from '../../../SharedComponents/ShowIf/ShowIf';
import Header from '../../../SharedComponents/Header/Header';
// import Ionicon from 'react-ionicons';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import moment from 'moment';
import dashboardApi from '../../../api/dashboardApi';
// import { setMobileSubheader, setSubheaderTitle } from '../../../actions/subheaderActions';
// import { showHeaderBack, setHeaderTitle, setLoginHeader, setHeaderBackUrl } from '../../../actions/headerActions';
import TitleSubheaderM from '../../../Views/MainApp/Views/Dashboard/SubHeader/mobile/mTitleSubHeader';
import { spinner } from '../../../reducers/userInterface/userInterfaceActions';
import { setCalendarData, clearCalendarData, setDashboardData, setDashboardDetail, clearDashboardDetail } from '../../../reducers/dashboard/dashboardActions';
import CalendarWeeks from './_CalendarWeeks';
import * as $ from 'jquery';

// const $ = window.jQuery;

class CalendarModal extends Component {
    constructor(props) {
        super(props);
        this.refWeeks = React.createRef();
        this.state = {
            date: moment(),
            titleLabel: moment().format('MMMM YYYY'),
            items: null,
            price: null,
            spend: null
        };
    };
    componentDidMount = () => {
        const action = this.props.dispatch;
        if (isMobile) {
            const itemsPage = this.props.match.params.itemsPage
            // Set subheader
            // action(setMobileSubheader(mTitleSubheader));
            // action(setSubheaderTitle('...'));
            // Set header
            const section = itemsPage === 'itemslost' ? 'Items Lost' : (itemsPage === 'itemsmissing' ? 'Items Missing' : 'Items Won' );
            // action(showHeaderBack(true));
            // action(setHeaderTitle(section));
            // action(setLoginHeader(false));
            // action(setHeaderBackUrl(null));
            this.loadDashboardData();
            $('html, body').scrollTop(0);
        }
    }
    loadDashboardData = () => {
        const action = this.props.dispatch;
        const timespan = this.props.match.params.timespan
        const startDate = timespan === 'custom' ? this.props.startDate : null;
        const endDate = timespan === 'custom' ? this.props.endDate : null;
        if (this.props.data.data.length === 0) {
            action(spinner(true));
            dashboardApi.dashboard(timespan, startDate, endDate).then(({data}) => {
                action(setDashboardData(data.data, timespan));
                action(spinner(false));
                this.loadDetailData();
            });
        } else {
            this.loadCalendarData();
        }
    }
    capitalize = (str) => {
        return str.replace(/\w\S*/g, function(txt){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
    loadCalendarData = () => {
        const action = this.props.dispatch;
        const {itemId, idMerchant, itemsPage} = this.props.match.params;
        for (let i = 0; i < this.props.data.detail[itemsPage].items.length; i++) {
            const elem = this.props.data.detail[itemsPage].items[i];
            if (elem.id === parseInt(itemId, 10)) {
                // action(setSubheaderTitle(this.capitalize(elem.description)));
            }
        }
        action(clearCalendarData());
        action(spinner(true));
        dashboardApi.dashboardCalendar(idMerchant, itemId, itemsPage).then(({data})=>{
            action(spinner(false));
            action(setCalendarData(data.data));
        });
    }
    loadDetailData = () => {
        const action = this.props.dispatch;
        const merchantId = this.props.match.params.idMerchant;
        const timespan = this.props.match.params.timespan
        const startDate = timespan === 'custom' ? this.props.startDate : null;
        const endDate = timespan === 'custom' ? this.props.endDate : null;
        action(clearDashboardDetail());
        action(spinner(true));
        dashboardApi.dashboardDetail(timespan, merchantId, startDate, endDate).then(({data}) => {
            action(setDashboardDetail(data.data));
            action(spinner(false));
            this.loadCalendarData();
        });
    }
    changeMonth = (e) => {
        let newDate = this.state.date;
        if (e.currentTarget.dataset.payload === 'add') {
            newDate = moment(newDate).add(1, 'months');
        } else {
            newDate = moment(newDate).subtract(1, 'months');
        }

        if (isMobile) {
            this.refWeeks.current.unselectAll();
        }

        this.setState({
            date: newDate,
            titleLabel: moment(newDate).format('MMMM YYYY'),
            items: null,
            price: null,
            spend: null
        });
    };
    pickDate = (data) =>{
        this.setState({
            items: data.items,
            price: data.price,
            spend: data.spend
        })
    }
    render() {
        if (isMobile) {
            const itemid = this.props.itemDistId === 0 ?  this.props.match.params.itemId : this.props.itemDistId;
            const detailItems = this.props.data.detail[this.props.itemsPage].items;
            const elem = detailItems.filter(m => m.id === parseInt(itemid))[0];
            const section = this.props.match.params.itemsPage === 'itemslost' ? 'Items Lost' : (this.props.match.params.itemsPage === 'itemsmissing' ? 'Items Missing' : 'Items Won' );
            const defaultHeight = {
                height: '114px'
            };
            return (
                <Fragment>
                    <Header showMenu={false} showLogo={false} showBack={true} title={section} showCart={false}>
                        <TitleSubheaderM title={!!elem ? this.capitalize(elem.description) : ''}/>
                    </Header>
                    <div className='mobile fb-background-white'>
                        <Row className="row no-padding no-gutters border-bottom pad-vertical-15 pad-sides-15 align-items-center">
                            <Col xs={2} className='col-2'>
                                <span data-payload='sub' onClick={this.changeMonth} className='clickable'>
                                    <MdKeyboardArrowLeft size={28} color='#a6b7c4' />
                                </span>
                            </Col>
                            <Col xs={8} className='col-8 text-center'>
                                { this.state.titleLabel }
                            </Col>
                            <Col xs={2} className='col-2 text-right'>
                                <span data-payload='add' onClick={this.changeMonth} className='clickable'>
                                    <MdKeyboardArrowRight size={28} color='#a6b7c4' />
                                </span>
                            </Col>
                        </Row>
                    </div>
                    <div className='fb-background-white'>
                        <div className="row no-padding no-gutters border-bottom pad-vertical-15 pad-sides-15 size-12 align-items-center">
                            <div className='col text-center'>
                                SU
                            </div>
                            <div className='col text-center'>
                                M
                            </div>
                            <div className='col text-center'>
                                TU
                            </div>
                            <div className='col text-center'>
                                W
                            </div>
                            <div className='col text-center'>
                                TH
                            </div>
                            <div className='col text-center'>
                                F
                            </div>
                            <div className='col text-center'>
                                SA
                            </div>
                        </div>
                    </div>
                    <CalendarWeeks ref={this.refWeeks} pickDate={this.pickDate} data={this.props.calendarData} date={this.state.date} />
                    <div className='fb-background-white pad-vertical-15' style={defaultHeight}>
                        {this.state.items === null ? null :
                            <div className="row no-padding no-gutters pad-vertical-5 pad-sides-25 size-12 align-items-center">
                                <div className='col'>
                                    <svg className='img-middle' version="1.1" id="Layer_1" x="0px" y="0px"
                                        width="14px" height="14px" viewBox="0 0 14 14" enableBackground="new 0 0 14 14">
                                        <circle cx="7" cy="7" r="7" fill='#feba5d' />
                                    </svg>
                                    <span>&nbsp;&nbsp;</span>{section}
                                </div>
                                <div className='col text-right'>
                                    {this.state.items}
                                </div>
                            </div>
                        }
                        {/* {this.state.price === null ? null :
                            <div className="row no-padding no-gutters pad-vertical-5 pad-sides-25 size-12 align-items-center">
                                <div className='col'>
                                    <Ionicon className='img-middle' icon='md-pricetag' fontSize='16px' color='#a6b7c4' />
                                    <span>&nbsp;&nbsp;</span>Price
                                </div>
                                <div className='col text-right'>
                                    {this.state.price}
                                </div>
                            </div>
                        }
                        {this.state.spend === null ? null :
                            <div className="row no-padding no-gutters pad-vertical-5 pad-sides-25 size-12 align-items-center">
                                <div className='col'>
                                    <Ionicon className='img-middle' icon='md-cart' fontSize='16px' color='#a6b7c4' />
                                    <span>&nbsp;&nbsp;</span>Total Spend
                                </div>
                                <div className='col text-right'>
                                    {this.state.spend}
                                </div>
                            </div>
                        } */}
                    </div>
                </Fragment>
            );
        } else {
            const section = this.props.itemsPage === 'itemslost' ? 'Items Lost' : (this.props.itemsPage === 'itemsmissing' ? 'Items Missing' : 'Items Won' );
            const itemId = this.props.itemDistId;
            let itemName = '';
            for (let i = 0; i < this.props.data.detail[this.props.itemsPage].items.length; i++) {
                const elem = this.props.data.detail[this.props.itemsPage].items[i];
                if (elem.id === parseInt(itemId, 10)) {
                    itemName = this.capitalize(elem.description);
                    break;
                }
            }
            const title = section + ' - ' + itemName;
            return (
                <ExtraLargeModal modalId={this.props.modalId}>
                    <ModalHeader title={title}/>
                    <Row className='border-top m-0 d-flex align-items-center'>
                        <Col xs={2} onClick={this.changeMonth} className='text-left clickable p-4'>
                            <span data-payload='sub'>
                                <MdKeyboardArrowLeft size={28} color='#a6b7c4' />
                            </span>
                        </Col>
                        <Col xs={8}>
                            <h5 className="modal-title text-center mobile-100">{ this.state.titleLabel }</h5>
                        </Col>                        
                        <Col xs={2} onClick={this.changeMonth} className='text-right clickable p-4'>
                            <span data-payload='add'>
                                <MdKeyboardArrowRight size={28} color='#a6b7c4' />
                            </span>
                        </Col>                        
                    </Row>
                    <Row className="border-top border-bottom m-0 d-flex align-items-center">
                        <Col className='text-center border-right py-2'>SUN</Col>
                        <Col className='text-center border-right py-2'>MON</Col>
                        <Col className='text-center border-right py-2'>TUE</Col>
                        <Col className='text-center border-right py-2'>WED</Col>
                        <Col className='text-center border-right py-2'>THU</Col>
                        <Col className='text-center border-right py-2'>FRI</Col>
                        <Col className='text-center py-2'>SAT</Col>
                    </Row>
                    <CalendarWeeks data={this.props.calendarData} date={this.state.date} />
                    <ModalBody>
                        <Row>
                            <Col className='col'>
                                <svg className='img-middle' version="1.1" id="Layer_1" x="0px" y="0px"
                                    width="14px" height="14px" viewBox="0 0 14 14" enableBackground="new 0 0 14 14">
                                    <circle cx="7" cy="7" r="7" fill='#feba5d' />
                                </svg>
                                <span>&nbsp;&nbsp;</span>{section}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                {/* <Ionicon className='img-middle' icon='md-pricetag' fontSize='16px' color='#a6b7c4'/>
                                <span>&nbsp;&nbsp;</span>Price<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Ionicon className='img-middle' icon='md-cart' fontSize='16px' color='#a6b7c4'/>
                                <span>&nbsp;&nbsp;</span>Total Spend */}
                            </Col>
                        </Row>
                    </ModalBody>  
                </ExtraLargeModal>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        data: {
            data: state.dashboard.data,
            timespan: state.dashboard.timespan,
            detail: state.dashboard.detailData,
        },
        itemDistId: state.dashboard.itemDistId,
        calendarData: state.dashboard.calendarData,
        startDate: state.dashboard.startDate,
        endDate: state.dashboard.endDate,
        itemsPage: state.userInterface.itemsPage
    }
}

export default connect(mapStateToProps)(CalendarModal);