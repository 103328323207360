import axios from 'axios';

const orderGuidePreUpload = (files) => {
    let formData = new FormData();
    if (Array.isArray(files)) {
      files.forEach((file) => {
        formData.append('file', file.file);
      });
    } else {
      files = []
    }
    return axios.post('order-guide/preupload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
}

const orderGuideUpload = (params) => {
  return axios.post('order-guide/', params);
}

const upload = (params) => {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('merchantIds', JSON.stringify(params.merchantIds));
    formData.append('distributor_id', params.distributor_id);
    formData.append('fastResponse', params.fastResponse);
    formData.append('start_date', params.start_date);
    formData.append('end_date', params.end_date);
    return axios.post('order-guide/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

const manualUpload = (params) => {
  return axios.post('order-guide/manual-upload', params);
}

const getOrderGuide = (distributorId, merchantId, pageNumber, limit) => {
  const params = {
     distributor_id : distributorId,
     merchant_id : merchantId,
     page : pageNumber,
     limit : limit
  }
  
  return axios.get('/order-guide', { params });
}

const deleteOrderGuide = (id) => {
  return axios.delete(`/order-guide/${id}`);
}

const getAvailableMerchants = () => {
  return axios.get('order-guide/merchant-distributor');
}

const getAvailableTemplates = (id) => {
  return axios.get(`distributor-order-guide/${id}/retrieve-templates`);
}

const getDetail = (id) => {
  return axios.get(`/order-guide/${id}`);
}

const getItems = (id, params) => {
  return axios.get(`/order-guide/${id}/items`, {params});
}

const getMatchingTemplates = (distributorId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('distributor_id', distributorId);
    return axios.post('distributor-order-guide/check-for-template', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

const uploadTemplate = (distributorId, file, merchantIds) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('merchantIds', JSON.stringify(merchantIds));
  return axios.post(`distributor-order-guide/upload-template/${distributorId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
}

export default {
    getOrderGuide,
    orderGuidePreUpload,
    orderGuideUpload,
    deleteOrderGuide,
    getAvailableMerchants,
    getAvailableTemplates,
    upload,
    manualUpload,
    getDetail,
    getItems,
    getMatchingTemplates,
    uploadTemplate
}