import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './Views/App/App';
import * as serviceWorker from './serviceWorker';
import store from './reducers/store';
import * as $ from 'jquery';
import './config/customValidations';
import './config/scss/combinedScss.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './include/bootstrap';

window.jQuery = window.$ = $;

ReactDOM.render(
  <Provider store={store}>    
    <HashRouter>
      <App />      
    </HashRouter>
  </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
