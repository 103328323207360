import React from 'react';
import PropTypes from 'prop-types';
import {colorsRb} from '../../config/constants';

const IconUpload = props => (
<svg  width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Distributor-Portal---Upload-Order-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2---partner-dashboard---details" transform="translate(-667.000000, -95.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
            <g id="subheader" transform="translate(0.000000, 70.000000)">
                <g id="button" transform="translate(650.000000, 11.000000)">
                    <g id="icons/upload" transform="translate(17.000000, 14.000000)">
                        <path d="M12.9000667,4.03336667 C12.4332667,1.7334 10.4332667,0 8,0 C6.06673333,0 4.40006667,1.09993333 3.56673333,2.70003333 C1.56673333,2.90006667 0,4.59993333 0,6.66666667 C0,8.86653333 1.79996667,10.6666667 4,10.6666667 L12.6666667,10.6666667 C14.5,10.6666667 16,9.16666667 16,7.33333333 C16,5.56673333 14.6334667,4.1333 12.9000667,4.03336667 Z M9.06666667,6 L9.06666667,8.53333333 L6.93333333,8.53333333 L6.93333333,6 L4.66666667,6 L8,2.66666667 L11.3333333,6 L9.06666667,6 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

IconUpload.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconUpload.defaultProps = {
  activeColor: colorsRb.rbYellow,
  color: colorsRb.rbWhite,
  isActive: false,
  size: 16,
};

export default IconUpload;