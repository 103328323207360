import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import Select from 'react-select';

const OrderGuideUploadSelect = (props) => {
  const { label, id, placeholder, options, set, val } = props;

  return (
    <Fragment>
      <Select className='fb-select' classNamePrefix='select'
            id={id}
            placeholder={placeholder} 
            options={options} 
            onChange={set}
            value={val}
        />
    </Fragment>
  );
};

export default OrderGuideUploadSelect;