import React, { Fragment, useEffect, useState } from 'react';
import { Card, Row, Col, Button,  Popover, PopoverBody  } from 'reactstrap';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { MdCloudDownload, MdDelete, MdInsertDriveFile } from 'react-icons/md';
import './OrderGuideUpload.scss';
import * as $ from 'jquery';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useOrderGuide from '../useOrderGuide';
import moment from 'moment';
import Moment from 'react-moment';
import { useHistory } from "react-router-dom";
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { colors, OGUploadStatus } from '../../../../../config/constants';
import orderGuideApi from '../../../../../api/orderGuideApi';
import QuestionModal from '../../../../../SharedComponents/Modals/QuestionModal/QuestionModal';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import { useDispatch, useSelector } from 'react-redux';


const OrderGuideHistoryRow = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { data, orderGuideList, setOrderGuideList } = props;

    const [popoverOpenMan, setPopoverOpenMan] = useState(false);
    const [popoverOpenDown, setPopoverOpenDown] = useState(false);
    const [popoverOpenDel, setPopoverOpenDel] = useState(false);

    const toggleMan = () => setPopoverOpenMan(!popoverOpenMan);
    const toggleDown = () => setPopoverOpenDown(!popoverOpenDown);
    const toggleDel = () => setPopoverOpenDel(!popoverOpenDel);


    let newName = data.detail.map((ogd, i) => 
        <Fragment key={`ogd_`+i}>
            <span> {ogd.merchant.nickname} {data.detail.length-1 === i ? `` : <br/>}</span>  
        </Fragment>
    );

    const fullUser =  data.launching_user ? (data.launching_user.first_name ? data.launching_user.first_name : '') + ' ' + (data.launching_user.last_name ? data.launching_user.last_name: '') : '';

    const merchantName = data.is_multiple ? newName : (data.merchant ? data.merchant.nickname : '');
    
    const updateOrderGuide = () => {
        history.push(`/order-guide/${data.id}`);
    };

    const downloadOrderGuide = () => {
        const excelElement = document.createElement('a');
        excelElement.href = data.full_path;
        excelElement.click();
    };

    const deleteOG = () => {        
        orderGuideApi.deleteOrderGuide(data.id).then((res) => {
            const newOrderGuideList = orderGuideList.filter(og => (og.id !== data.id));
            setOrderGuideList(newOrderGuideList);
        })
        .catch(error => {
          
        });
    };

    const deleteOrderGuide = () => {
        const qProps = {
            message: `You are about to delete an order guide. This may take a few minutes`,
            menu: isMobile ? true : false,
            leftButton: {
              title: 'Cancel',
              color: 'orange',
            },
            rightButton: {
              title: 'Proceed',
              color: 'green',
              onClick: () => deleteOG()
            }
          };
          dispatch(showModal(QuestionModal, qProps));
    };
        
  
    return (
        <Card>
            <Row className='px-3 py-2 text-left d-flex align-items-center'>
                <Col xs={2} >{merchantName}</Col>
                <Col xs={2} className='text-truncate'>{data.short_name}</Col>
                <Col xs={1} className='text-truncate'>{fullUser}</Col>
                <Col xs={1}><Moment format="MM/DD/YY">{moment(data.start_date)}</Moment></Col>
                <Col xs={1}><Moment format="MM/DD/YY">{moment(data.end_date)}</Moment></Col>
                <Col xs={1}>{data.item_count}</Col>
                <Col xs={1} className='text-capitalize'>{data.type}</Col>
                <Col xs={1} className='text-capitalize'>{data.upload_status}</Col>
                <Col xs={2} className='d-flex flex-direction-row align-items-center'>                    
                    <Col className='px-3 d-flex justify-content-end'>
                        <ShowIf show={data.upload_status === OGUploadStatus.processed && data.precompute}>
                            <MdInsertDriveFile  color={colors.lightBlueText} onClick={() => updateOrderGuide()} style={{fontSize:'20px'}} id={`pop-og-man_`+data.id}/>
                            <IconBadge idPop={`pop-og-man_`+data.id} text='Update Order Guide Manually' FaIcon='' size={8} color={colors.black} customClass='TooltipCustomBlack'/>
                        </ShowIf>
                    </Col>
                    <Col className='px-3 d-flex justify-content-center'>
                        <ShowIf show={data.upload_status === OGUploadStatus.processed}>
                            <MdCloudDownload  color={colors.lightBlueText} onClick={() => downloadOrderGuide()} style={{fontSize:'20px'}} id={`pop-og-down_`+data.id}/>
                            <IconBadge idPop={`pop-og-down_`+data.id} text='Download Order Guide' FaIcon='' size={8} color={colors.black} customClass='TooltipCustomBlack'/>
                        </ShowIf>
                    </Col>
                    {/* <Col className='px-3 d-flex justify-content-start'>
                        <MdDelete  color={colors.lightBlueText} onClick={() => deleteOrderGuide()} style={{fontSize:'20px'}} id={`pop-og-del_`+data.id}/>
                        <IconBadge idPop={`pop-og-del_`+data.id} text='Delete Order Guide' FaIcon='' size={8} color={colors.black} customClass='TooltipCustomBlack'/>
                    </Col> */}
                </Col>
            </Row>
        </Card>        
    );
};

export default OrderGuideHistoryRow;