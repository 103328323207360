import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUserData } from "../../reducers/user/userAction";
import { clearDashboardData,clearDashboardDetail, clearGraphData, clearCalendarData } from "../../reducers/dashboard/dashboardActions";
import { clearSelectedMerchant,clearSelectedMerchants } from "../../reducers/orderGuide/orderGuideActions";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  localStorage.clear();
  dispatch(clearUserData());
  dispatch(clearDashboardData());
  dispatch(clearDashboardDetail());
  dispatch(clearGraphData());
  dispatch(clearCalendarData());

  dispatch(clearSelectedMerchant());
  dispatch(clearSelectedMerchants());


  history.push('/login');

};