
import React, {Fragment} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import DashboardList from "./DashboardList/DashboardList";
import ItemsPage from "./DashboardDetails/ItemsPage";
import { isMobile } from 'react-device-detect';
import LineGraphModal from '../../../../SharedComponents/Modals/GraphModal/LineGraphModal';
import CalendarModal from '../../../../SharedComponents/Modals/CalendarModal/CalendarModal';
import ItemDetail from './DashboardDetails/ItemDetail';
import AllModals from '../../../../SharedComponents/Modals/AllModals';
import ModalContent from '../../../../SharedComponents/Modal/ModalContent';
import ResizeListener from '../../../../SharedComponents/ResizeListener/ResizeListener';

const Dashboard = (props) => {
    
    return (
        <Fragment>
            <Switch>
                <Route exact path='/dashboard/:timespan' render = {(newProps) => <DashboardList {...newProps}/>}/>
                <Route exact path='/dashboard/details/:itemsPage/:idMerchant/:timespan' render = {(newProps) => <ItemsPage {...newProps}/>}/>
                
                { isMobile ? <Route exact path='/dashboard/details/:itemsPage/:idMerchant/:timespan/priceGraph/:itemId' component={LineGraphModal} /> : null}
                { isMobile ? <Route exact path='/dashboard/details/:itemsPage/:idMerchant/:timespan/calendar/:itemId' component={CalendarModal} /> : null}
                <Route exact path='/dashboard/details/item/:itemId/' component={ItemDetail} />
                <Route>
                    <Redirect to='/dashboard/weekly' />
                </Route>
            </Switch>
            <AllModals />
            <ModalContent /> 
            <ResizeListener />
        </Fragment>
        
    )
}

export default Dashboard;