export const saveSelectedMerchant = (merchant) => {
    return {
      type: 'SET_SELECTED_MERCHANT',
      payload: merchant
    }
};

export const clearSelectedMerchant = () => {
    return {
      type: 'CLEAR_SELECTED_MERCHANT'
    }
};

export const saveSelectedMerchants = (merchants) => {
    return {
      type: 'SET_SELECTED_MERCHANTS',
      payload: merchants
    }
};

export const clearSelectedMerchants = () => {
    return {
      type: 'CLEAR_SELECTED_MERCHANTS'
    }
};
  
  