import React, { Component } from 'react';
import { withRouter } from 'react-router';
import selected from '../../assets/images/selected.png';

class ModalLink extends Component {
    componentWillMount() {
        this.unlisten = this.props.history.listen(location => {
            this.setState({location});
        });
    };
    componentWillUnmount() {
        this.unlisten();
    };
    goTo = (e) => {
        e.preventDefault();
        if (this.props.path !== undefined) {
            this.props.history.push(this.props.path);
        }
        if (this.props.action !== undefined) {
            this.props.action(this.props.value);
        }
    };
    render() {
        const props = this.props;
        const selectedStyle = {
            position: 'absolute',
            right: '15px',
            top: '20px'
        };
        if (props.modalId === undefined) {
            return (
                <div data-dismiss="modal" onClick={this.goTo} className="modal-body">
                    {props.title}
                    {props.path === props.history.location.pathname ? <img style={selectedStyle} src={selected} alt='selected' /> : null}
                    {props.active ? <img style={selectedStyle} src={selected} alt='selected' /> : null}
                </div>
            );
        } else {
            return (
                <div data-keyboard={this.props.static ? 'false' : 'true'} data-backdrop={this.props.static ? 'static' : null} data-dismiss="modal" data-toggle="modal" data-target={'#'+props.modalId} onClick={this.goTo} className="modal-body">
                    {props.title}
                    {props.path === props.history.location.pathname ? <img style={selectedStyle} src={selected} alt='selected' /> : null}
                    {props.active ? <img style={selectedStyle} src={selected} alt='selected' /> : null}
                </div>
            );
        }
    }
}

export default withRouter(ModalLink);