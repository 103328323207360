import { toggleSidebar, spinner } from '../../reducers/userInterface/userInterfaceActions';
import useInput from '../SharedEffects/useInput';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { changeTourMoral } from '../../reducers/modals/modalsActions';
import { useRef, useState, useEffect } from 'react';
import qs from 'query-string'
import { setListSearch, setListSortByOptions, setListSortBy, setInventorySearch, setInventorySortBy } from '../../reducers/items/itemsActions';
import { sortOptions } from '../../config/constants';
import { saveHeaderIndexRevOrder } from '../../reducers/header/headerActions';
import * as $ from 'jquery';

const useActions = () => {
  const { set: setSearch, value: search, clearValue, setValue: setSearchValue } = useInput.useText(null);
  // const storeView = useSelector(({items}) => items.view);
  // const [view, setView] = useState(storeView);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const clickMenu = () => {
    dispatch(toggleSidebar());
  };

  const clickBack = () => {
    history.goBack();
  };

  const clearSearch = () => {
    
    const { pathname } = history.location;
    clearValue();
    const search='';
    
    $("HTML, BODY").animate({
      scrollTop: 0
    });

    history.push({
      pathname: pathname,
      search: search
    });
  };

  const enterSearch = () => {
    const { pathname } = history.location;

    $("HTML, BODY").animate({
      scrollTop: 0
    });
    

    history.push({
      pathname: pathname,
      search: search ? `?query=${search}` : ''
    });
  };
  const [openModalWC, setOpenModalWC] = useState(false);

  
  return {
    clickMenu,
    enterSearch,
    clearSearch,
    setSearch,
    search,
    clickBack,
    
  };
};

export default {
  useActions
};