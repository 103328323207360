import moment from 'moment';

export default function reducer(state = {
    data: [],
    detailData: {
        'itemsmissing': {
            'items': []
        },
        'itemslost': {
            'items': []
        },
        'itemswon': {
            'items': []
        },
    },
    calendarData: [],
    graphData: [],
    graphTitle: '',
    timespan: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    merchantId: 0,
    itemDistId: 0
}, action) {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, data: action.payload.data, timespan: action.payload.timespan };
        case 'SET_DETAIL_DATA':
            return { ...state, detailData: action.payload };
        case 'SET_GRAPH_DATA':
            return { ...state, graphData: action.payload.data, graphTitle: action.payload.title };
        case 'SET_CALENDAR_DATA':
            return { ...state, calendarData: action.payload };
        case 'SET_ITEM_DIST_ID':
            return { ...state, itemDistId: action.payload };
        case 'SET_MERCHANT_ID':
            return { ...state, merchantId: action.payload };
        case 'SET_DATA_DATES':
            return { ...state, startDate: action.payload.startDate, endDate: action.payload.endDate };
        case 'CLEAR_DATA':
            return { ...state, data: [] };
        case 'CLEAR_DETAIL':
            return { ...state,
                detailData: {
                    'itemsmissing': {
                        'items': []
                    },
                    'itemslost': {
                        'items': []
                    },
                    'itemswon': {
                        'items': []
                    },
                }
            };
        case 'CLEAR_GRAPH_DATA':
            return { ...state, graphData: [], graphTitle: '' };
        case 'CLEAR_CALENDAR_DATA':
            return { ...state, calendarData: [] };
        default:
            return state;
    }
}