
export default function reducer(state = {
    selectedMerchant: [],
    selectedMerchants: [],
  }, action) {
    switch (action.type) {
        case 'SET_SELECTED_MERCHANT':
            return {
            ...state,
            selectedMerchant: action.payload
            }
        case 'CLEAR_SELECTED_MERCHANT':
            return {
                ...state,
                selectedMerchant: []
            }
        case 'SET_SELECTED_MERCHANTS':
            return {
            ...state,
            selectedMerchants: action.payload
            }
        case 'CLEAR_SELECTED_MERCHANTS':
            return {
                ...state,
                selectedMerchants: []
            }
      default:
        return state;
    }
  };