import React, { Fragment, useEffect, useState } from 'react';
import { Container, Card, Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import * as $ from 'jquery';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useOrderGuide from '../useOrderGuide';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useParams } from "react-router-dom";
import Header from '../../../../../SharedComponents/Header/Header';
import OrderGuideDetailRow from './OrderGuideDetailRow';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { MdInfo, MdEdit } from 'react-icons/md';
import { IoMdCloudDownload } from 'react-icons/io';
import '../OrderGuideList/OrderGuideUpload.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { colors } from '../../../../../config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import ManualOGModal from './ManualOGModal';
import orderGuideApi from '../../../../../api/orderGuideApi';
import AlertModal from '../../../../../SharedComponents/Modals/AlertModal/AlertModal';
import { debounce } from 'lodash';


const OrderGuideDetail = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const id = parseInt(params.id);
    const { data, title, days, startDate, endDate, setStartDate, setEndDate } = useOrderGuide.useDetailApi(id);
    const { items, setItems, getItems, page, isLoading, loadMore } = useOrderGuide.useItemsApi(id);
    const [ editMode, setEditMode ] = useState(false);
    
    const itemsMap = items.map((item, i) => 
        <OrderGuideDetailRow key={`i_row_${i}`} index={i} item={item} mode={editMode} setItems={setItems} items={items}/>
    );

    const editOG = () => {
        setEditMode(!editMode);
    };

    const downloadOG = () => {
        const excelElement = document.createElement('a');
        excelElement.href = data.full_path;
        excelElement.click();
    };

    const addItem = () => {
        
    };

    const showManualModal = () => {
        if(items.length === 0){return;}
        const newItems = items.map((i, index) => {
            if(i.price !== '') {    // return i;
                return {
                    ...i,
                    price: i.is_catch_weight ? i.unit_price : i.price,
                }
            }
            return {
                ...i,
                price: 0
            }
        });
        setItems(newItems);

        const props = {
            startDate: startDate,
            endDate: endDate, 
            onOk: () => (moment().isSame(moment(startDate).format('YYYY-MM-DD'), 'day')) ? saveManualOG(newItems) : delayedClickUpload(newItems)
        };
        dispatch(showModal(ManualOGModal, props));
    };

    const saveManualOG = (newItems) => {
        const params = {
            order_guide_id: data.id,
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'), 
            items: newItems
        };        

        orderGuideApi.manualUpload(params).then((res) => {
            const result = res.data.data;
            setEditMode(!editMode);
            history.replace(`/order-guide`);
        })
        .catch(error => {
            setEditMode(!editMode);
            history.replace(`/order-guide`);
        });
        
    };

    const rightButton = {icon: editMode ? [] : [
        <Button className='btn-outlined' onClick={editOG} ><MdEdit  color={colors.green} style={{fontSize:'20px', marginTop: '-2px', marginRight: '7px'}}/>Edit</Button>,
        <Button onClick={downloadOG} color='green' ><IoMdCloudDownload  color='white' style={{fontSize:'20px', marginTop: '-2px', marginRight: '7px'}}/>Download</Button>,
    ]};

    const subHeader1 = <ShowIf show={editMode}>
        <Row className='header h-inherit py-2 px-5 d-flex align-items-center m-0 text-gray' style={{fontSize:'14px'}}>
            <Col xs={3}>
                <Row className='d-flex flex-direction-row align-items-center'>
                    Order Guide Date &nbsp;&nbsp;&nbsp;
                    <DatePicker id='startDateInput'
                        className={`selectInputDetail`}
                        selected={startDate}
                        dateFormat={"MMMM dd, yyyy"}
                        onChange={date => setStartDate(date)} 
                        minDate={moment().toDate()}/>
                </Row>
            </Col>
            <Col xs={3} className='d-flex flex-direction-row align-items-center'> 
                <Row className='d-flex flex-direction-row align-items-center'>
                    Next Price Change &nbsp;&nbsp;&nbsp;
                    <DatePicker id='endDateInput'
                        className={`selectInputDetail`}
                        selected={endDate}
                        dateFormat={"MMMM dd, yyyy"}
                        onChange={date => setEndDate(date)} 
                        minDate={moment(startDate).toDate()}/>
                </Row>                
            </Col>
            <Col xs={3}></Col>
            <Col xs={3} className='d-flex flex-direction-row align-items-end clearfix px-0'>
                <Col className='d-flex justify-content-end px-2'>
                    {/* <Button className='btn-outlined' color='green' onClick={addItem} block> Add New Item</Button>                 */}
                </Col>
                <Col className='d-flex justify-content-end px-0'>
                    <Button color='green' onClick={showManualModal} block> Save</Button>
                </Col>
            </Col>
            
        </Row>
    </ShowIf>;

    const subHeader2 = <Row className='header h-inherit py-3 px-5 d-flex align-items-center m-0 text-gray mb-3' style={{fontSize:'14px', lineSpacing:'14px'}}>
                        <Col xs={1} className='pl-4'> Item Code </Col>
                        <Col xs={4} className='pl-4'> Item Description </Col>
                        <Col xs={1} className='d-flex align-items-center px-0 text-center' style={{letterSpacing:'-0.5px'}}> 
                            Unit of Measure 
                            <span>
                                <IconBadge idPop={`pop-head-info_`+data.id} 
                                    text='UOM' 
                                    FaIcon={MdInfo} 
                                    color={colors.lightBlueText} 
                                    size={16} 
                                    customClass='TooltipCustomBlack' 
                                    noBack='svgIconDefault'/>
                            </span>
                        </Col>
                        <Col xs={2} className='pr-5'> Case Price </Col>
                        <Col xs={1}> Packsize </Col>
                        <Col xs={1} > Unit Price </Col>
                        {/* <Col xs={1} className='text-center'> Catch Weight </Col> */}
                        <Col xs={2}> Brand </Col>
                    </Row>;
  
    useEffect(() => {
        const newEndDate = moment(startDate).add(days, 'days');
        setEndDate(moment(newEndDate).toDate());
    }, [startDate, days]);


    const delayedClickUpload = debounce((newItems) => saveManualOG(newItems), 1500);

    return (
        <Container fluid={true} className={isMobile ? 'px-0' : 'px-5'}>
            <Header showMenu={false} showLogo={false} showBack={true} title={title+` (`+data.id+`)`} showCart={false} rightButton={rightButton}>
                {subHeader1}
                {subHeader2}
            </Header>
            <InfiniteScroll
                initialLoad = {false}
                pageStart={page}
                loadMore={getItems}
                hasMore={loadMore && !isLoading}
                useWindow={true}
            >
                {itemsMap}
            </InfiniteScroll>
        </Container>        
    );
};

export default OrderGuideDetail;