import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import useLogin from "../Login/useLogin";
import authenticateApi from "../../api/authenticateApi";
import usersApi from "../../api/usersApi";
import validate from "validate.js";
import { localStorageConst } from '../../config/constants';

const useAdminLogin = () => {
  const history = useHistory();
  const { doLogin } = useLogin.useLogin();
  const [distributors, setDistributors] = useState([]);
  const [distOptions, setDistOptions] = useState([]);
  const [distributor, setDistributor] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [distributorSearch, setDistributorSearch] = useState('');

  const token = localStorage.getItem(localStorageConst.keys.authKey);

  const getAllDistributors = (search) => {
    authenticateApi.distributorList(token, search)
      .then(({data})=>{
          let result = data.data; 
          if (result) {              
              const dOptions = result.map(distributor => {
                return {
                  label: distributor.nickname,
                  value: distributor.id
                }
              });
              setDistributors(dOptions);
              setDistOptions(dOptions);
          }
      })
      .catch(function (error) {
          if (error.response) {
              let errorMessage = error.response.data.data.message;
              alert(errorMessage);
          }
      });
  }

  useEffect(() => {    
    // const nDistOptions = distributors.filter(d => (d.label.toLowerCase().indexOf(distributorSearch.toLowerCase()) !== -1));
    // setDistOptions(nDistOptions);

    getAllDistributors(distributorSearch)
  }, [distributorSearch]);

 
  useEffect(() => {
    if (distributor) {
      authenticateApi.userList(token, distributor.value).then(({data}) => {
        let result = data.data;
        if (result) {
          const usersOptions = result.map(u => {
            return {
              label: u.first_name + " " + u.last_name,
              value: u.id
            }
          });
          setUser(null);
          setUsers(usersOptions);
        }        
      }).catch((err) => {
        console.error('AuthApi users error:', err);
      });
    }
  }, [distributor]);

  const submitDisabled = (e) => {
    const data = {
      user: user,
      distributor: distributor
    };
    const constrains = {
      user: {
        presence: true
      },
      distributor: {
        presence: true
      }
    }
    const result = validate(data, constrains);
    return result ? true : false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const params = {
      userId: user.value,
      merchantId: distributor.value
    };

    authenticateApi.adminAuth(distributor.value,user.value)
        .then(({data})=>{            
            let result = data.data;
            if (result) {
                doLogin(result);                
            }            
        })
        .catch(function (error) {
            if (error.response) {
                let errorMessage = error.response.data.data.message;
                alert(errorMessage);
                history.push('/logout');
            }
        })

  };

  return {
    onSubmit, distributor, distributors, setDistributorSearch, distOptions,
    setDistributor, users, setUser, user, submitDisabled
  };
};

export default useAdminLogin;