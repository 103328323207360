import React from 'react';
import './App.scss';
import AuthenticationRouter from '../AuthenticationRouter/AuthenticationRouter';
import MainApp from '../MainApp/MainApp';
import Modals from '../../SharedComponents/Modals/Modals';
import Spinner from '../../SharedComponents/Spinner/Spinner';
import useApi from '../../SharedComponents/SharedEffects/useApi';
import setDefaults from '../../config/api';
import initFullStory from '../../SharedComponents/FullStory/FullStory';


function App() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    initFullStory();
  }

  // useApi();
  setDefaults();
  
  return (
    <div className="App">
      <AuthenticationRouter />
      <Modals />
      <Spinner />
    </div>
  );
}

export default App;