import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from '../_Link';
import { setItemPage, setTimespan, spinner } from '../../../../../../reducers/userInterface/userInterfaceActions';
import { MdCloudDownload } from 'react-icons/md';
import { IoIosStats} from 'react-icons/io';
import { Row, Col, Button } from 'reactstrap';
// import { setHeaderBackUrl } from '../../../../actions/headerActions';
import { clearDashboardData, setDashboardData, setDashboardDetail, clearDashboardDetail, setGraphData, clearGraphData, selectedItemDistId } from '../../../../../../reducers/dashboard/dashboardActions';
import dashboardApi from '../../../../../../api/dashboardApi';
// import {showModal} from '../../../../masterComponents/Modal/ModalActions';
// import OrderGuide from '../OrderGuide/OrderGuide';
import IconUpload from '../../../../../../SharedComponents/Icons/IconUpload';
import {colorsRb} from '../../../../../../config/constants';
import '../../../../../../config/css/Basic.css';

class dDetailsSubheader extends Component {
    changeTimespan = (timespan) => {
        let action = this.props.dispatch;
        action(setTimespan(timespan));
        // action(setHeaderBackUrl('/dashboard/'+timespan));
        if (timespan !== 'custom') {
            this.loadDashboardData(timespan);
        }
    };

    loadDashboardData = (timespan, startDate, endDate) => {
        const { data } = this.props;
        const action = this.props.dispatch;
        if (data === undefined || data.data.length === 0 || timespan !== data.timespan || startDate !== undefined || endDate !== undefined) {
            action(spinner(true));
            action(clearDashboardDetail());
            action(clearDashboardData());
            dashboardApi.dashboard(timespan).then(({data})=>{
                action(spinner(false));
                action(setDashboardData(data.data, timespan));
                this.loadDashboardDetailData(timespan);
            });
        }
    };

    loadDashboardDetailData = (timespan) => {
        const action = this.props.dispatch;
        const merchantId = this.props.idMerchant;
        action(spinner(true));
        dashboardApi.dashboardDetail(timespan, merchantId).then(({data}) => {
            action(setDashboardDetail(data.data));
            action(spinner(false));
        });
    };

    changeItemsPage = (page) => {
        let action = this.props.dispatch;
        action(setItemPage(page));
        // Fake load detail data
        const oldDetailData = this.props.detailData;
        action(clearDashboardDetail());
        action(spinner(true));
        setTimeout(()=>{
            action(setDashboardDetail(oldDetailData));
            action(spinner(false));
        }, 50);
    };

    goToPriceGraph = () => {
        const action = this.props.dispatch;
        const merchantId = this.props.idMerchant;
        action(spinner(true));
        action(clearGraphData());
        action(selectedItemDistId('all'));
        dashboardApi.dashboardGraph(merchantId).then(({data}) => {
            action(setGraphData(data.data, 'Total Purchases'));
            action(spinner(false));
        });
    };

    uploadOG = () => {
        const props = this.props;
        const dispatch = this.props.dispatch;
        // dispatch(showModal(OrderGuide, 'idOGModal', { ...props}));
    }

    downloadFile = (fileUrl) => {
        const excelElement = document.createElement('a');
        excelElement.href = fileUrl;
        excelElement.click();
    }

    render() {
        const { itemsPage, timespan, idMerchant } = this.props;
        const id = '/'+idMerchant+'/';
        const url = '/dashboard/details/';
        return (
            <div className='sub-header container-fluid'>
                <Row className='sub-header-container row no-gutters align-items-center'>
                    <Col className='col header-buttons'>
                        <Row className='row no-gutters align-items-center timespan-header'>
                            <Link action={this.changeItemsPage} value='itemsmissing' path={url+'itemsmissing'+id+timespan} title='Items Missing' />
                            <Link action={this.changeItemsPage} value='itemslost' path={url+'itemslost'+id+timespan} title='Items Lost' />
                            <Link action={this.changeItemsPage} value='itemswon' path={url+'itemswon'+id+timespan} title='Items Won' />
                        </Row>
                    </Col>
                    <Col className='col header-buttons timespan-header text-center'>
                        {/* <button onClick={this.uploadOG}  className='btn fb-button-white mar-right-10'><IconUpload  size={18} color={colorsRb.rbDarkAqua} /><span>&nbsp;&nbsp;</span>Upload Order Guide</button> */}
                        <Button color='white' style={{fontSize:'14px'}} onClick={() => this.downloadFile(this.props.detailData.download)}>
                            <MdCloudDownload className='img-sub' size={18} color='#a6b7c4'/><span>&nbsp;&nbsp;</span>Download
                        </Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button  color='white' style={{fontSize:'14px'}} onClick={this.goToPriceGraph}  data-toggle='modal' data-target='#GraphId'>
                            <IoIosStats className='img-sub' size={18} color='#a6b7c4'/><span>&nbsp;&nbsp;</span>Show Graph
                        </Button>
                    </Col>
                    <Col className='col header-buttons'>
                        <Row className='row no-gutters align-items-center timespan-header'>
                            <Link action={this.changeTimespan} value='weekly' path={url+itemsPage+id+'weekly'} title='Weekly' />
                            <Link action={this.changeTimespan} value='monthly' path={url+itemsPage+id+'monthly'} title='Monthly' />
                            <Link action={this.changeTimespan} value='yearly' path={url+itemsPage+id+'yearly'} title='Year to Date' />
                            <Link static modalId='CustomDatePickerModalOnDetails' action={this.changeTimespan} value='custom' path={url+itemsPage+id+'custom'} title='Custom' />
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        detailData: state.dashboard.detailData,
        timespan: state.userInterface.timespan,
    }
}

export default connect(mapStateToProps)(dDetailsSubheader);
