import React, { useState } from 'react'
import './IconBadge.scss';
import { Tooltip } from 'reactstrap';


const IconBadge = (props) => {
    const { FaIcon, text, color, size, idPop, customClass, noBack } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => {
        setPopoverOpen(!popoverOpen);
    }

    setTimeout(() => {
        // setPopoverOpen(!popoverOpen);
    }, 20000);

    return (
        <div >
            {
                FaIcon ? <FaIcon className={noBack ? noBack : 'svgIcon'} size={size} color={color} id={idPop} /> : ''
            }

            {text ?
                <Tooltip className={customClass ? customClass : 'TooltipCustom'}
                    placement='top'
                    isOpen={popoverOpen}
                    target={idPop}
                    toggle={toggle}
                >{text}
                </Tooltip>
                : ''
            }

        </div>

    )
}


export default IconBadge;
