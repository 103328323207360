import { valHooks } from 'jquery';
import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, CustomInput } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import './OrderGuideUpload.scss';
import { useDispatch, useSelector } from 'react-redux';
import { saveSelectedMerchants, clearSelectedMerchants } from '../../../../../reducers/orderGuide/orderGuideActions';

const AllMerchantsModal = (props) => {
  const { id, onOk, btnLabel, btnSize, footerClass, color, allMerchants, onSelect } = props;
  const modalprops = useModal(id);
  const [merchants, setMerchants] = useState(allMerchants);
  const dispatch = useDispatch();

  const updateMerchantSelected = (merchantId) => {
        const updatedMerchants = merchants.map((m, index) => {
                                if (m.id !== merchantId) return m;
                                return {
                                ...m,
                                value: !m.value
                                };
                            });
                            
        setMerchants(updatedMerchants);
  };

  const allMerchantsMap =   merchants.map((m, index) => 
                                <Col xs={12} key={`m_${m.id}`} className='text-left py-2'>
                                    <CustomInput className='squared' type='checkbox' id={m.id} checked={m.value} onChange={() => updateMerchantSelected(m.id)} label={m.nickname} />
                                </Col>
                            );
  
  
    const onCancel = () => {
        modalprops.toggle();
        };

    const onClickOk = () => {
        const selectedMerch = merchants.filter(m => (m.value));
        onSelect(selectedMerch);
        dispatch(saveSelectedMerchants(selectedMerch));
        modalprops.toggle();
    };

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
        <ModalBody className='text-center'>
            <Row className='py-2 px-3'>
                <Col className='text-left'>
                    This customer is associated with multiple locations in FoodBAM. The order guide you are about to upload will be applied to the following locations. Please uncheck any that do not apply.
                </Col>
            </Row>
            <Row className='d-flex align-items-center flex-direction-column px-3'>
                {allMerchantsMap}
            </Row>
        </ModalBody>
        <ModalFooter>
            <Row className='w-100'>
                <Col className='w-100 text-left'>
                    <Button color={color} size={btnSize} onClick={onCancel} className='btn-outlined'>Cancel</Button>
                </Col>
                <Col className='w-100 text-right'>
                    <Button color={color} size={btnSize} onClick={onClickOk}>Proceed</Button>
                </Col>
            </Row>            
        </ModalFooter>
    </Modal>
  );
};

AllMerchantsModal.defaultProps = {
  footerClass: '',
  btnLabel: 'Ok!',
  btnSize: 'sm',
  color: 'green'
};

export default AllMerchantsModal;
