import React, { Component } from 'react';
import {connect} from 'react-redux';
import Link from '../_Link';
import { setTimespan, spinner } from '../../../../../../reducers/userInterface/userInterfaceActions';
import distributors from '../../../../../../api/dashboardApi';
import { setDashboardData, clearDashboardData } from '../../../../../../reducers/dashboard/dashboardActions';
import { Row, Col } from 'reactstrap';
import { showModal } from "../../../../../../reducers/modals/modalsActions";
import CustomDatePickerModal from '../../../../../../SharedComponents/Modals/CustomDatePickerModal/CustomDatePickerModal';
import * as $ from 'jquery';

class Buttons extends Component {
    changeTimespan = (timespan) => {
        let action = this.props.dispatch;
        action(setTimespan(timespan));
        this.loadDashboardData(timespan);
    };
    changeTimespanNoLoad = (timespan) => {
        let action = this.props.dispatch;
        action(setTimespan(timespan));

    };
    loadDashboardData = (timespan) => {
        let data = this.props.data;
        let action = this.props.dispatch;
        if (data.data.length === 0 || timespan !== data.timespan) {
            action(spinner(true));
            action(clearDashboardData());
            distributors.dashboard(timespan).then(({data})=>{
                action(setDashboardData(data.data, timespan));
                action(spinner(false));
            });
        }
    };
    render() {
        return (
            <Col>
                <Row className='d-flex align-items-center m-0 timespan-header'>
                    <Link action={this.changeTimespan} value='weekly' path='/dashboard/weekly' title='Weekly' className={`px-0`}/>
                    <Link action={this.changeTimespan} value='monthly' path='/dashboard/monthly' title='Monthly' className={`px-0`}/>
                    <Link action={this.changeTimespan} value='yearly' path='/dashboard/yearly' title='Year to Date' className={`px-0`}/>
                    <Link static action={this.changeTimespanNoLoad} value='custom' modalId='CustomDatePickerModal' path='/dashboard/custom' title='Custom' className={`px-0`}/>
                </Row>
            </Col>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        timespan: state.userInterface.timespan,
        itemsPage: state.userInterface.itemsPage,
        data: {
            data: state.dashboard.data,
            timespan: state.dashboard.timespan
        }
    }
}

export default connect(mapStateToProps)(Buttons);