import { useState } from "react";
import validate from 'validate.js';
import useInput from "../../SharedComponents/SharedEffects/useInput";
import authenticateApi from "../../api/authenticateApi";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, setUserActiveLocation } from "../../reducers/user/userAction";
import { localStorageConst, placeOrderViews } from '../../config/constants';
import { useHistory } from "react-router-dom";
import setDefaults from '../../config/api';

const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const doLogin = (data) => {
    if (!data.authKey) {
      data.authKey = localStorage.getItem(localStorageConst.keys.authKey);      
    }
    localStorage.setItem(localStorageConst.keys.userName, data.firstName + ' ' + data.lastName);
    localStorage.setItem(localStorageConst.keys.authKey, data.authKey);
    localStorage.setItem(localStorageConst.keys.currentRole, data.currentRole);
    localStorage.setItem(localStorageConst.keys.distributor, JSON.stringify(data.distributor));
    setDefaults();
    dispatch(setUserData(data));    
    
  };

  return { doLogin };
}

const useForm = (props) => {
  const [error, setError] = useState({});
  const { set: setEmail, value: email } = useInput.useText();
  const { set: setPassword, value: password } = useInput.useText();
  const { set: setConfirmPassword, value: confirmPassword } = useInput.useText();
  const { doLogin } = useLogin();
  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = {
      email: email,
      password: password
    };
    const constrains = {
      email: {
        presence: {
          allowEmpty: false
        },
        email: true
      },
      password: {
        presence: {
          allowEmpty: false
        }
      }
    };
    const result = validate(form, constrains);
    setError(result ? result : {});
    if (!result) {
      authenticateApi.authenticate(email, password).then(({ data }) => {
        doLogin(data.data);
      }).catch(error => {
        const res = error.response;
        console.error(error);
        const status = res.data.code;
        if (status == 400) {
          setError({ error: res.data.data.message });
        } else {
          setError({ error: 'Something went wrong.' });
        }
      });
    }
  };
  const onSubmitSU = (e) => {
    e.preventDefault();
    const form = {
      password: password,
      confirmPassword: confirmPassword
    };
    const constrains = {
      password: {
        presence: {
          allowEmpty: false
        }
      },
      confirmPassword: {
        presence: {
          allowEmpty: false,
        },
        equality: "password"
      }
    };
    const result = validate(form, constrains);
    setError(result ? result : {});

    if (!result) {
      authenticateApi.addSignup(password, props.match.params.mdsrId).then(({ data }) => {
        // doLogin(data.data);
        history.push('/login');

      }).catch(err => {
        console.error(err);
        const status = err.code;
        if (status === 400) {
          setError({ error: err.data.message });
        } else {
          setError({ error: 'Something went wrong.' });
        }
      });
    }
  };
  return { error, onSubmit, onSubmitSU, setEmail, setPassword, setConfirmPassword, password, email, confirmPassword }
};

export default {
  useForm, useLogin
};
