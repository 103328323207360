import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import MainApp from '../MainApp/MainApp';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import useAuthenticationRouter from './useAuthenticationRouter';
import AdminLogin from '../AdminLogin/AdminLogin';
import Signup from '../Signup/Signup';
import ChangePassword from '../ChangePassword/ChangePassword';

const AuthenticationRouter = () => {
  const { isLogin, currentRole, distributor } = useAuthenticationRouter();

  if (isLogin) {
    if (currentRole === 'Foodbam Admin') {
      return (
        <Switch>
          <Route exact path='/admin-login' component={AdminLogin} />
          <Route exact path='/logout' component={Logout} />
          <Route>
            <Redirect to='/admin-login' />
          </Route>
        </Switch>
      )
    }
    return (
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <Route path='/' component={MainApp} />
      </Switch>
    );

  };

  return (
    <Switch>
      <Route exact path='/change-password/:token' component={ChangePassword} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/signup/:mdsrId' component={Signup} />
      <Route>
        <Redirect to='/login' />
      </Route>
    </Switch>
  );
};

export default AuthenticationRouter;
