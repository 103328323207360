import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { MdArrowDropDown, MdArrowDropUp, MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { FaCalendar } from 'react-icons/fa';
import { IoMdCalendar } from 'react-icons/io';
import { IoIosStats} from 'react-icons/io';
import distributors from '../../../../../api/distributorsApi';
import { Link } from 'react-router-dom';
import { setCalendarData, clearCalendarData, selectedItemDistId, clearGraphData, setGraphData, setDashboardDetail, clearDashboardDetail } from '../../../../../reducers/dashboard/dashboardActions';
import dashboardApi from '../../../../../api/dashboardApi';
import savingsImg from '../../../../../assets/images/saving.png';
import { spinner, setShowInGraph } from '../../../../../reducers/userInterface/userInterfaceActions';

class ItemRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            savings: false,
            savingsHtml: null
        };
    };

    goToPriceGraph = (e) => {
        const itemId = e.currentTarget.id
        const action = this.props.dispatch;
        action(setShowInGraph('$'));
        if (isMobile) {
            const url = this.props.history.location.pathname;
            this.props.history.push(url + '/priceGraph/'+itemId);
        } else {
            this.loadGraphItemData(itemId);
        }
    };

    loadGraphItemData = (itemId) => {
        const action = this.props.dispatch;
        const {itemsPage,  idMerchant} = this.props.match.params;
        action(clearGraphData());
        action(selectedItemDistId(itemId));
        dashboardApi.dashboardGraphItem(idMerchant, itemId, itemsPage).then(({data}) => {
            action(setGraphData(data.data, this.props.description));
        });
    };

    goToCalendar = (e) => {
        const actions = this.props.dispatch;
        const itemId = e.currentTarget.id
        if (isMobile) {
            actions(selectedItemDistId(itemId));
            const url = this.props.history.location.pathname;
            this.props.history.push(url + '/calendar/'+itemId);
        } else {
            this.loadCalendarData(itemId);
        }
    };

    handleNotStocked = (isNotStocked) => {
        const merchantId = parseInt(this.props.match.params.idMerchant, 10);
        const itemDistId = this.props.id;
        distributors.stocks(merchantId, itemDistId, isNotStocked).then(() => {
            this.loadDashboardDetailData();
        });
    }

    loadDashboardDetailData = () => {
        const action = this.props.dispatch;
        const merchantId = this.props.match.params.idMerchant;
        const timespan = this.props.match.params.timespan
        const startDate = timespan === 'custom' ? this.props.startDate : null;
        const endDate = timespan === 'custom' ? this.props.endDate : null;
        action(clearDashboardDetail());
        action(spinner(true));
        dashboardApi.dashboardDetail(timespan, merchantId, startDate, endDate).then(({data}) => {            
            action(setDashboardDetail(data.data));
            action(spinner(false));            
        });
    }

    loadCalendarData = (itemId) => {
        const actions = this.props.dispatch;
        const { idMerchant, itemsPage } = this.props.match.params;
        actions(clearCalendarData());
        actions(selectedItemDistId(itemId));
        actions(spinner(true));
        dashboardApi.dashboardCalendar(idMerchant, itemId, itemsPage).then(({data})=>{
            actions(setCalendarData(data.data));
            actions(spinner(false));
        });
    };
    changeSavings = (e) => {
        if (!this.state.savings){
            this.loadSavingsData();
        }
        this.setState({ savings: !this.state.savings });
    };

    goToDetails = () => {
        this.props.history.push('/dashboard/details/'+this.props.itemsPage+'/'+this.props.merchantId+'/'+this.props.timespan);
    };

    setItem = () => {        
        localStorage.setItem('itemDetail', JSON.stringify(this.props));
    };

    loadSavingsData = () => {
        const merchantId =  parseInt(this.props.match.params.idMerchant, 10);
        const itemDistId = this.props.id;
        const timespan = this.props.match.params.timespan;
        let savingsData = null;
        let savingsHtml = null;
        this.setState({ savingsHtml: savingsHtml });
        distributors.savings(merchantId, itemDistId, timespan).then(({data})=>{
            if (data.data.length !== 0){
                savingsData = data.data;
                savingsHtml = savingsData.map((savingsRow, index) =>
                    (isMobile) ?
                        <div className='grid-border-top fb-slide-in-down' key = {this.props.id + '-' + index}>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col text-capitalize'>
                                     {<Link to={'/dashboard/details/item/' + savingsRow.id} className="text-dark-blue" onClick={this.setItem}>
                                         {savingsRow.description}
                                     </Link> }
                                 </div>
                             </div>
                             <div className='row no-margin mar-top-15 mar-bottom-15 grid-border-top'>
                             </div>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col dashboard-items-header'>
                                     Brand
                                 </div>
                                 <div className='col text-right'>
                                     {savingsRow.brand}
                                 </div>
                             </div>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col dashboard-items-header'>
                                     UOM
                                 </div>
                                 <div className='col text-right text-lowercase'>
                                     {savingsRow.unitMeasure}
                                 </div>
                             </div>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col dashboard-items-header'>
                                     Packsize
                                 </div>
                                 <div className='col text-right text-lowercase'>
                                     {savingsRow.packSize}
                                 </div>
                             </div>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col dashboard-items-header'>
                                     Price Guidance
                                 </div>
                                 <div className='col text-right text-lowercase'>
                                     ${(Math.round(savingsRow.price * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {savingsRow.Uom}
                                 </div>
                             </div>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col dashboard-items-header'>
                                     Unit Price Guidance
                                     </div>
                                 <div className='col text-right text-lowercase'>
                                     ${(Math.round(savingsRow.unitPrice * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {savingsRow.packSizeUnit}
                                 </div>
                             </div>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col dashboard-items-header'>
                                     Total Spend
                                     </div>
                                 <div className='col text-right'>
                                     ${(Math.round(savingsRow.totalPurchased * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                 </div>
                             </div>
                             <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                 <div className='col dashboard-items-header'>
                                     Total Cases
                                     </div>
                                 <div className='col text-right'>
                                     {Math.round(savingsRow.totalQuantity * 100) / 100}
                                 </div>
                             </div>
                         </div>
                    :
                        <div className='col grid-border-savings fb-slide-in-down' key = { this.props.id + '-' + index}>
                            <div className='top-savings'>
                                <div className='quarter-circle'>
                                    <div className='text-savings'>$</div>
                                </div>
                            </div>
                            <div className='row no-gutters pad-top-15 pad-bottom-15 pad-sides-5 dashboard-items-detail'>
                                <div className='col-1'></div>
                                <div className='col-4 text-capitalize'>{savingsRow.description}</div>
                                <div className='col-1'>{savingsRow.brand}</div>
                                <div className='col-1 text-lowercase'>{savingsRow.unit_measure}</div>
                                <div className='col-1 text-lowercase'>{savingsRow.packSize}</div>
                                <div className='col-3 row no-gutters align-items-center'>
                                    <div className='col-7 text-lowercase pl-2'>${(Math.round(savingsRow.price * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})} {savingsRow.Uom}</div>                                   
                                    <div className='col-5 text-lowercase pl-2'>${(Math.round(savingsRow.unitPrice * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})} {savingsRow.packSizeUnit}</div>
                                </div>
                                {/* <div className='col-1 no-padding'>${(Math.round(savingsRow.totalPurchased * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div> */}
                                <div className='col-1 pl-1'>{Math.round(savingsRow.totalQuantity * 100) / 100}</div>
                            </div>
                        </div>
                );
                this.setState({ savingsHtml: savingsHtml});
            }
        });
    };

    render() {
        // If showDin is true that means we are on Items Won Section       
        const { showDin, showNotStocked } = this.props;
        const savingsTitle = (isMobile) ?
            <div onClick={this.changeSavings} className='row pad-sides-15'>
                <div className='col dashboard-items-savings pad-vertical-5'>
                    <div className='col text-left'><img src={savingsImg} alt='saving img'/> YOUR ITEM</div>
                    <div className='col text-right'>
                        {
                            this.state.savings ? <MdArrowDropUp className='clickable' size={16} color='#ffffff' /> : <MdArrowDropDown className='clickable' size={16} color='#ffffff' />
                        }
                    </div>
                </div>
            </div>
            :
            <div onClick={this.changeSavings} className={'clickable col grid-border-savings'}>
                <div className='row dashboard-items-savings font-weight-bold'>
                  <div className='col pad-vertical-5 d-flex align-items-center justify-content-center'>
                    $ YOUR ITEM <span>&nbsp;</span>
                    {
                        this.state.savings ? <MdArrowDropUp className='clickable' size={16} color='#ffffff' /> : <MdArrowDropDown className='clickable' size={16} color='#ffffff' />
                    }                    
                  </div>
                </div>
            </div>

        if (isMobile) {
            return (
                <div className='col-sm-12 col-md-6 pad-sides-15'>
                    <div className='container-detail mobile'>                        
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col text-capitalize'>
                                {<Link to={'/dashboard/details/item/' + this.props.id} className="text-dark-blue" onClick={this.setItem}>
                                    {this.props.description}
                                </Link> }
                            </div>
                        </div>
                        <div className='row m-0 mar-top-15 mar-bottom-15 grid-border-top'>
                        </div>
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                Generic Group
                            </div>
                            <div className='col text-right'>
                                {this.props.group}
                            </div>
                        </div>
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                Brand
                                </div>
                            <div className='col text-right'>
                                {this.props.brand}
                            </div>
                        </div>
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                UOM
                                </div>
                            <div className='col text-right text-lowercase'>
                                {this.props.unitMeasure}
                            </div>
                        </div>
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                Packsize
                                </div>
                            <div className='col text-right text-lowercase'>
                                {this.props.packSize}
                            </div>
                        </div>
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                { showDin ? 'Price' : 'Price Guidance' }
                            </div>
                            <div className={'col text-right '+(( !showDin && (Math.round(this.props.price * 100) / 100) !== 0) ? 'text-lowercase' : '')}>
                                {
                                    ( !showDin && (Math.round(this.props.price * 100) / 100) === 0) ?
                                    'Not Enough Data'
                                    :
                                    <Fragment>
                                        { !showDin ? '~' : ''} ${(Math.round(this.props.price * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {this.props.unitMeasure}
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                Rebate
                            </div>
                            <div className='col text-right text-lowercase'>
                                {this.props.rebate}
                            </div>
                        </div>
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                { showDin ? 'Unit Price' : 'Unit Price Guidance' }
                            </div>
                            <div className={'col text-right '+(( !showDin && (Math.round(this.props.unitPrice * 100) / 100) !== 0) ? 'text-lowercase' : '')}>
                                {
                                    ( !showDin && (Math.round(this.props.unitPrice * 100) / 100) === 0) ?
                                    'Not Enough Data'
                                    :
                                    <Fragment>
                                        { !showDin ? '~' : ''} ${(Math.round(this.props.unitPrice * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {this.props.packSizeUom}
                                    </Fragment>
                                }
                            </div>
                        </div>
                        {
                            showDin ?
                            <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                <div className='col dashboard-items-header'>
                                    Total Spend
                                    </div>
                                <div className='col text-right'>
                                    ${(Math.round(this.props.totalPurchased * 100) / 100).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                            </div>
                            :
                            null
                        }
                        {showNotStocked ? 
                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                                <div className='col dashboard-items-header'>
                                    Not Stocked
                                </div>
                                <div className='col text-right no-padding'>
                                { this.props.not_stocked === 1 ? 
                                    <div className='col clickable'>
                                        <span onClick={()=> this.handleNotStocked(0)}><MdCheckBox className='clickable' size={18} color='#46cc7e' /></span> 
                                    </div> 
                                :   <div className='col clickable' onClick={()=> this.handleNotStocked(1)}>
                                        <span><MdCheckBoxOutlineBlank className='clickable' size={18} color='#a6b7c4'/></span>
                                    </div>
                                }
                                </div>
                        </div>
                        : null }

                        <div className='row pad-sides-15 mar-top-15 mar-bottom-15'>
                            <div className='col dashboard-items-header'>
                                Total Cases
                            </div>
                            <div id={this.props.id} onClick={this.goToCalendar} className='col text-right'>
                                <span><IoMdCalendar className='img-sub' size={18} color='#a6b7c4'/></span>
                                <span>&nbsp;&nbsp;</span>
                                <span>{Math.round(this.props.totalQuantity * 100) / 100}</span>
                            </div>
                        </div>
                        <div className='row pad-sides-15'>
                            <div className='col mar-top-15 mar-bottom-15'>
                                <div className='col'>
                                    <div className='col mar-top-5 mar-bottom-5 text-center'>
                                        <button id={this.props.id} onClick={this.goToPriceGraph} className='btn fb-button-white size-17 text-bold mobile-100'>
                                            <IoIosStats size={18} color='#a6b7c4'/> Price Graph
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.savings) ? this.state.savingsHtml : null}
                        {this.props.haveSavings !== undefined ? (this.props.haveSavings ? savingsTitle : null) : null}
                    </div>
                </div>
            );
        } else {
            const padTop1 = {
                paddingTop: '1px'
            };
            const padTop3 = {
                paddingTop: '3px'
            };
            const padRight10 ={
                paddingRight: '10px'
            }
            return (
              <Fragment>
                <div className='col border-top'>
                    <div className='row no-gutters mar-top-15 mar-bottom-15 dashboard-items-detail'>
                        <div style={ padRight10 } className='col-1'>{this.props.group}</div>
                        { showDin ? <div className='col-1'>{this.props.din}</div> : null }
                        <div style={ padRight10 } className={ showDin ? 'col-2 text-capitalize pad-sides-5' : ( showNotStocked ? 'col-3 text-capitalize' : 'col-4 text-capitalize' ) }>
                            {<Link to={'/dashboard/details/item/' + this.props.id} className="text-dark-blue" onClick={this.setItem}>
                                {this.props.description}
                            </Link> }
                        </div>
                        <div className='col-1'>{this.props.brand}</div>
                        <div className='col-1 text-lowercase'>{this.props.unitMeasure}</div>
                        <div className='col-1 text-lowercase'>{this.props.packSize}</div>
                        <div className='col-3 row no-gutters align-items-center'>
                            <div className={'col-4 '+(( !showDin && (Math.round(this.props.price * 100) / 100) !== 0) ? 'text-lowercase' : '')}>
                                {
                                    ( !showDin && (Math.round(this.props.price * 100) / 100) === 0) ?
                                    'Not Enough Data'
                                    :
                                    <Fragment>
                                        { !showDin ? '~' : ''} ${(Math.round(this.props.price * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})} {this.props.unitMeasure}
                                    </Fragment>
                                }
                            </div>
                            <div className='col-3 text-lowercase'>{this.props.rebate}</div>
                            <div className={'col-5 '+(( !showDin && (Math.round(this.props.unitPrice * 100) / 100) !== 0) ? 'text-lowercase' : '')}>
                                {
                                    ( !showDin && (Math.round(this.props.unitPrice * 100) / 100) === 0) ?
                                    'Not Enough Data'
                                    :
                                    <Fragment>
                                        { !showDin ? '~' : ''} ${(Math.round(this.props.unitPrice * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})} {this.props.packSizeUom}
                                    </Fragment>
                                }
                            </div>
                        </div>
                        { showDin ? <div className='col-1 no-padding'>${(Math.round(this.props.totalPurchased * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div> : null}
                         { showNotStocked ? 
                            <div className='col-1'>{ this.props.not_stocked === 1 ? <div className='col-1 clickable'><span onClick={()=> this.handleNotStocked(0)}><MdCheckBox className='clickable' size={18} color='#46cc7e' /></span> </div> 
                        : <div className='col-1 clickable' onClick={()=> this.handleNotStocked(1)}><span ><MdCheckBoxOutlineBlank size={18} color='#a6b7c4'/></span></div> }</div> : null }
                        <div className='col-1 d-flex'>
                            <span style={padTop3} >{Math.round(this.props.totalQuantity * 100) / 100}</span>
                            <span>&nbsp;&nbsp;</span>
                            <span style={padTop1} className='clickable' onClick={this.goToCalendar} id={this.props.id} data-toggle='modal' data-target='#CalendarModal'><IoMdCalendar size={18} color='#a6b7c4'/></span> <span>&nbsp;&nbsp;</span>
                            <span style={padTop1} className='clickable' onClick={this.goToPriceGraph} id={this.props.id} data-toggle='modal' data-target='#GraphIdItem'><IoIosStats size={18} color='#a6b7c4'/></span>
                        </div>
                    </div>
                </div>
                {(this.state.savings) ? this.state.savingsHtml : null}
                {this.props.haveSavings !== undefined ? (this.props.haveSavings ? savingsTitle : null) : null}
              </Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {        
        startDate: state.dashboard.startDate,
        endDate: state.dashboard.endDate,
    }
}

export default connect(mapStateToProps)(ItemRow);

// export default ItemRow;
