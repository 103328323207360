import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import BottomModal from './BottomModal';
import ModalLink from './_ModalLink';
import ModalTitle from './_ModalTitle';
import { setTimespan, setItemPage, spinner, setSort, setShowInGraph } from '../../reducers/userInterface/userInterfaceActions'
import { isMobile } from 'react-device-detect';
// import { setHeaderBackUrl } from '../../actions/headerActions';

import dashboardApi from '../../api/dashboardApi';
import { setDashboardData, clearDashboardData, clearDashboardDetail, setDashboardDetail, setDashboardDataDates, clearGraphData, setGraphData } from '../../reducers/dashboard/dashboardActions';
import CustomDatePickerModal from './CustomDatePickerModal/CustomDatePickerModal';
import MessageModal from './MessageModal/MessageModal';
import LineGraphModal from './GraphModal/LineGraphModal';
import CalendarModal from './CalendarModal/CalendarModal';
import * as $ from 'jquery';

// const $ = window.jQuery;


class AllModals extends Component {
    componentDidMount = () => {
        $('.modal').on('shown.bs.modal', function (e) {
            const button = $(e.relatedTarget);
            button.one('focus', function (e) { $(this).blur(); });
        });
    };
    changeTimespan = (timespan) => {
        const action = this.props.dispatch;
        action(setTimespan(timespan));
        // action(setHeaderBackUrl('/dashboard/'+timespan));
        if (timespan !== 'custom') {
            this.loadDashboardData(timespan);
        }
    };
    changeItemsPage = (page) => {
        const action = this.props.dispatch;
        action(setItemPage(page));
        //  ke load detail data
        const oldDetailData = this.props.data.detailData;
        action(clearDashboardDetail());
        action(spinner(true));
        setTimeout(()=>{
            action(setDashboardDetail(oldDetailData));
            action(spinner(false));
        }, 50);
    };
    loadDashboardData = (timespan, startDate, endDate) => {
        const data = this.props.data;
        const action = this.props.dispatch;
        const details = this.props.location.pathname.substring(0,18) === '/dashboard/details';
        if (data.data.length === 0 || timespan !== data.timespan || startDate !== undefined || endDate !== undefined) {
            action(spinner(true));
            if (details) {
                action(clearDashboardDetail());
            }
            action(clearDashboardData());
            dashboardApi.dashboard(timespan, startDate, endDate).then(({data})=>{
                action(setDashboardData(data.data, timespan));
                action(spinner(false));
                if (details) {
                    this.loadDashboardDetailData(timespan, startDate, endDate);
                }
            });
        }
    };
    loadDashboardDetailData = (timespan, startDate, endDate) => {
        const action = this.props.dispatch;
        const merchantId = this.props.selectedMerchantId; //this.props.idMerchant; 
        action(spinner(true));
        dashboardApi.dashboardDetail(timespan, merchantId, startDate, endDate).then(({data}) => {
            action(setDashboardDetail(data.data));
            action(spinner(false));
        });
    };
    loadCustomDashboardData = (startDate, endDate) => {
        const action = this.props.dispatch;
        action(setDashboardDataDates(startDate, endDate));
        this.loadDashboardData('custom', startDate, endDate);
    };
    setSort = (sort) => {
        const action = this.props.dispatch;
        action(setSort(sort, true));
    };

    setShowInGraph = (showIn) => {
        const action = this.props.dispatch;
        action(setShowInGraph(showIn));
        // Make a fake load graph data
        const oldData = this.props.data.graph.slice();
        const oldTitle = this.props.data.graphTitle;
        action(clearGraphData());
        action(spinner(true));
        setTimeout(()=>{
            action(setGraphData(oldData, oldTitle));
            action(spinner(false));
        }, 2000);
    };

    goToPriceGraph = () => {
        const url = this.props.history.location.pathname;
        this.props.history.push(url + '/priceGraph/all');
    }

    downloadDetail = () => {        
        var newlink = document.createElement('a');
        newlink.setAttribute('href', this.props.data.detailData.download);
        document.body.appendChild(newlink);
        newlink.click();
        document.body.removeChild(newlink);
    }

    render() {
        const {timespan, itemsPage, selectedMerchantId, sort, showInSelect} = this.props;
        const id = '/'+selectedMerchantId+'/'; // TODO: - Change after getting id from api
        if (isMobile) {
            return (
                <Fragment>
                    <BottomModal modalId='ShowByModal'>
                        <ModalTitle title='Show By' />
                        <ModalLink value='weekly' action={this.changeTimespan} title='Weekly' path='/dashboard/weekly' />
                        <ModalLink value='monthly' action={this.changeTimespan} title='Monthly' path='/dashboard/monthly' />
                        <ModalLink value='yearly' action={this.changeTimespan} title='Year to Date' path='/dashboard/yearly' />
                        <ModalLink static modalId='CustomDatePickerModal' value='custom' action={this.changeTimespan} title='Custom' path='/dashboard/custom' />
                    </BottomModal>
                    <BottomModal modalId='ShowByOnDetailsModal'>
                        <ModalTitle title='Show By' />
                        <ModalLink value='weekly' action={this.changeTimespan} title='Weekly' path={'/dashboard/details/'+itemsPage+id+'weekly'} />
                        <ModalLink value='monthly' action={this.changeTimespan} title='Monthly' path={'/dashboard/details/'+itemsPage+id+'monthly'} />
                        <ModalLink value='yearly' action={this.changeTimespan} title='Year to Date' path={'/dashboard/details/'+itemsPage+id+'yearly'} />
                        <ModalLink static modalId='CustomDatePickerModalOnDetails' value='custom' action={this.changeTimespan} title='Custom' path={'/dashboard/details/'+itemsPage+id+'custom'} />
                    </BottomModal>
                    <BottomModal modalId='ItemsPageModal'>
                        <ModalTitle title='Show' />
                        <ModalLink value='itemsmissing' action={this.changeItemsPage} title='Items Missing' path={'/dashboard/details/itemsmissing'+id+timespan} />
                        <ModalLink value='itemslost' action={this.changeItemsPage} title='Items Lost' path={'/dashboard/details/itemslost'+id+timespan} />
                        <ModalLink value='itemswon' action={this.changeItemsPage} title='Items Won' path={'/dashboard/details/itemswon'+id+timespan} />
                    </BottomModal>
                    <BottomModal modalId='ShowMoreOptionsModal'>
                        <ModalTitle title='MORE OPTIONS' />
                        <ModalLink value='sort' modalId='SortByItemsPageModal' title='Sort'/>
                        <ModalLink value='analytics' action={this.goToPriceGraph} title='Analytics'/>
                        <ModalLink value='download' action={this.downloadDetail} title='Download'/>
                    </BottomModal>
                    <BottomModal modalId='SortByItemsPageModal'>
                        <ModalTitle title='Sort By' />
                        <ModalLink action={this.setSort} active={ sort === 'price' } value='price' title={ itemsPage === 'itemswon' ? 'Price' : 'Price Guidance' } />
                        <ModalLink action={this.setSort} active={ sort === 'unitPrice' } value='unitPrice' title={ itemsPage === 'itemswon' ? 'Unit Price' : 'Unit Price Guidance' } />
                        {
                            itemsPage === 'itemswon' ?
                            <ModalLink action={this.setSort} active={ sort === 'total_purchased' } value='total_purchased' title='Total Spend' />
                            : null
                        }
                        <ModalLink action={this.setSort} active={ sort === 'total_quantity' } value='total_quantity' title='Total Cases' />
                    </BottomModal>
                    <BottomModal modalId='ShowGraphTypeModal'>
                        <ModalLink action={this.setShowInGraph} active={showInSelect ==='$'} value='$' title='$'  />
                        <ModalLink action={this.setShowInGraph} active={showInSelect ==='cs'} value='cs' title='cs' />
                        <ModalLink action={this.setShowInGraph} active={showInSelect ==='%'} value='%' title='%' />
                    </BottomModal>
                    <CustomDatePickerModal modalId='CustomDatePickerModal' onGo={this.loadCustomDashboardData}/>
                    <CustomDatePickerModal modalId='CustomDatePickerModalOnDetails' onGo={this.loadCustomDashboardData}/>
                    <MessageModal modalId='MessageModal'/>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <LineGraphModal  modalId='GraphId' showDropdown showIn={this.props.showIn} title={this.props.graphTitle} />
                    <LineGraphModal  modalId='GraphIdItem' showIn={this.props.showIn} title={this.props.graphTitle}/>
                    <CustomDatePickerModal modalId='CustomDatePickerModal' onGo={this.loadCustomDashboardData}/>
                    <CustomDatePickerModal modalId='CustomDatePickerModalOnDetails' onGo={this.loadCustomDashboardData}/>
                    <MessageModal modalId='MessageModal'/>
                    <CalendarModal itemDistId={this.props.selectedItemDistId} modalId='CalendarModal'/>
                </Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        timespan: state.userInterface.timespan,
        itemsPage: state.userInterface.itemsPage,
        messages: state.userInterface.messages,
        sort: state.userInterface.sort,
        data: {
            data: state.dashboard.data,
            detailData: state.dashboard.detailData,
            timespan: state.dashboard.timespan,
            graph: state.dashboard.graphData,
            graphTitle: state.dashboard.graphTitle
        },
        selectedMerchantId: state.dashboard.merchantId,
        selectedItemDistId: state.dashboard.itemDistId,
        showInSelect : state.userInterface.showInGraph
    }
}

export default withRouter(connect(mapStateToProps)(AllModals));
