import React, { Fragment, useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { MdCloudUpload, MdDelete, MdRefresh } from 'react-icons/md';
import './OrderGuideUpload.scss';
import * as $ from 'jquery';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import useOrderGuide from '../useOrderGuide';
import OrderGuideHistoryRow from './OrderGuideHistoryRow';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from "react-router-dom";
import { colors } from '../../../../../config/constants';


const OrderGuideHistory = (props) => {
    const history = useHistory();
    const [merchant, setMerchant] = useState(props.merchant);
    const [distributor, setDistributor] = useState(props.distributor);
    const { orderGuideList, setOrderGuideList, page, setPage, isLoading, loadMore, getOrderGuides, resetOrderGuides } = useOrderGuide.useApi(distributor.id, merchant.id);
    const [ogMap, setOgMap] = useState('');

    // let orderGuidesMap = orderGuideList.map((og, index) => 
    //     <OrderGuideHistoryRow key={`og_row_${index}`} data={og} orderGuideList={orderGuideList} setOrderGuideList={setOrderGuideList}/>
    // );

    const refreshHistory = () => {
        setOgMap('');
        resetOrderGuides();
    };

    useEffect(() => {
        setOgMap(orderGuideList.map((og, index) => 
            <OrderGuideHistoryRow key={`og_row_${index}`} data={og} orderGuideList={orderGuideList} setOrderGuideList={setOrderGuideList}/>
        ));
    }, [orderGuideList])

 
    return (
        
        <Card>
            <Card>
                <Row className='p-3 d-flex align-items-center'>
                    <Col className='text-left text-main'>
                        Price Update History
                    </Col>

                    <Col className='w-fc text-right text-gray clickable'>
                        <MdRefresh color={colors.lightBlueText} style={{fontSize:'2em'}} onClick={() => refreshHistory() }/> Refresh
                    </Col>
                </Row>
            </Card>
            <Card>
                <Row className='px-3 py-2 text-left text-gray d-flex align-items-center'>
                    <Col xs={2}>Merchant Name</Col>
                    <Col xs={2}>File Name</Col>
                    <Col xs={1}>User Name</Col>
                    <Col xs={1}>Upload Date</Col>
                    <Col xs={1}>Expires On</Col>
                    <Col xs={1}>Number of Items</Col>
                    <Col xs={1}>Type</Col>
                    <Col xs={1}>Status</Col>
                    <Col xs={2}></Col>
                </Row>
            </Card>
            <InfiniteScroll
                initialLoad = {false}
                pageStart={page}
                loadMore={getOrderGuides}
                hasMore={loadMore && !isLoading}
                useWindow={true}
            >
                {ogMap}
            </InfiniteScroll> 
            
        </Card>
        
        
    );
};

export default OrderGuideHistory;