
import React, {Fragment} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Container } from 'reactstrap';
import useOrderGuide from '../useOrderGuide';
import Header from '../../../../../SharedComponents/Header/Header';
import OrderGuideUpload from './OrderGuideUpload';

const OrderGuideList = (props) => {    
    const { merchants, distributor, merchantDistributor } = useOrderGuide.useMerchants(props);
    
    return (
        <Container fluid={true} className={isMobile ? 'px-0' : ''}>
            <Header showMenu={true} showLogo={false} showBack={false} title='Upload Order Guide' showCart={false} />
            <OrderGuideUpload merchants={merchants} distributor={distributor} />
        </Container>
    )
}

export default OrderGuideList;