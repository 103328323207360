import React, { Component } from 'react';

class ModalBody extends Component{
    render() {
        const customClass = this.props.customClass === undefined ? '' : this.props.customClass;
        let props = {
            className: "modal-body "+ customClass,
        }
        if (this.props.style) {
            props.style = this.props.style;
        }
        return (
            <div {...props} >
                {this.props.children}
            </div>
        )
    }
}

export default ModalBody;
