import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import './Header.scss';
import ShowIf from '../ShowIf/ShowIf';
import { isMobile } from 'react-device-detect';

const HeaderTimespan = (props) => {
    const { show, struct } = props;

    return (
        <ShowIf show={show}>
            <ShowIf show={!isMobile}>
                <Col xs={3} className=' px-0'>
                    {struct}
                </Col>
            </ShowIf>            
        </ShowIf>
    );
};

export default HeaderTimespan;