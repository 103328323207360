import React, {useState} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Col } from 'reactstrap';
import OrderGuide from './Views/OrderGuide/OrderGuide';
import Dashboard from './Views/Dashboard/Dashboard';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { colors, localStorageConst } from '../../config/constants';
import { useHistory } from "react-router-dom";

const MainAppRouter = (props) => {
  const { isSidebarOpen } = props;  
  const distributorLocal = JSON.parse(localStorage.getItem(localStorageConst.keys.distributor));
  const distributorStore = useSelector(({ user }) => user.distributor);
  const [distributor, setDistributor] = useState(!!distributorLocal ? distributorLocal : distributorStore);
  
  return (
    <Col className={'p-0 main-app-content' + (isSidebarOpen ? ' sidebar-is-open' : '')}>
      <Switch>
        {/* {distributor.isPartner ? 
          <Route path='/dashboard' render = {(newProps) => <Dashboard {...newProps}/>}/> 
          : 
          <Route path='/order-guide' component={OrderGuide} />
        } */}
        <Route path='/dashboard' render = {(newProps) => <Dashboard {...newProps}/>}/> 
        <Route path='/order-guide' component={OrderGuide} />
        <Route>
          <Redirect to={ distributor.isPartner ? '/dashboard' : '/order-guide'} />
        </Route>
      </Switch>
    </Col>
  );
};

export default MainAppRouter;
