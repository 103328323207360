export default function reducer(state = {
  authKey: localStorage.getItem('authKey') ? localStorage.getItem('authKey') : 'g31cr6dupg0qxvvIcGVm1UnIBXcHhVDB',
  isLogin: localStorage.getItem('authKey') ? true : false,
  currentRole: localStorage.getItem('currentRole') ? localStorage.getItem('currentRole') : null,
  distributor: localStorage.getItem('distributor') ? localStorage.getItem('distributor') : null,
  
}, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...action.payload,
        isLogin: true
      };
    case 'CLEAR_USER_DATA':
      return {
        authKey: 'g31cr6dupg0qxvvIcGVm1UnIBXcHhVDB',
        currentRole: null,
        isLogin: false,
        distributor: null
      };
    
    default:
      return state;
  }
};
