import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import ClientCard from './_ClientCard';
import { Container, Row, Col } from 'reactstrap';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { setTimespan, spinner, setMessages } from '../../../../../reducers/userInterface/userInterfaceActions';
import { hideDesktopSubheader, setMobileSubheader } from '../../../../../reducers/subheader/subheaderActions';
import HomeSubHeaderMobile from '../SubHeader/mobile/HomeSubHeader';
import HomeSubHeaderDesktop from '../SubHeader/desktop/_Buttons';
// import { showHeaderLogo, showHeaderBack, showHeaderHomeButtons, setHeaderTitle, setLoginHeader } from '../../actions/headerActions';
import Header from '../../../../../SharedComponents/Header/Header';
import distributors from '../../../../../api/dashboardApi';
import { setDashboardData, clearDashboardData } from '../../../../../reducers/dashboard/dashboardActions';
import messages from '../../../../../api/messageApi';
import * as $ from 'jquery';
// const $ = window.jQuery;

class DashboardList extends Component {
    
    componentDidMount = () => {
        // Update Timespan at start
        const action = this.props.dispatch;
        const timespan = this.props.timespan.timespan; //this.props.match.params.timespan
        action(setTimespan(timespan));
        // Set subheader
        // action(hideDesktopSubheader());
        // action(setMobileSubheader(HomeSubHeaderMobile));
        // Set header
        // action(showHeaderLogo(true));
        // action(showHeaderBack(false));
        // action(showHeaderHomeButtons(true));
        // action(setHeaderTitle('Partner Dashboard'));
        // action(setLoginHeader(false));
        this.loadMessages();
        this.loadDashboardData();
        $('html, body').scrollTop(0);
    };
    loadMessages = () => {
      const action = this.props.dispatch;
      action(spinner(true));
      let distributorMessages = {};
      messages.getMessages().then(({data})=>{
          distributorMessages = data.data;
          action(setMessages(distributorMessages));
          action(spinner(false));
          if (data.data.length !== 0) {
              $('#MessageModal').modal('show');
          }
      }).catch(err => {
        action(spinner(false));
        console.error(err);
      });
    };
    loadDashboardData = () => {
        
        const data = this.props.data;
        const action = this.props.dispatch;
        const timespan = this.props.match.params.timespan;
        if (data.data.length === 0 || timespan !== data.timespan) {
            action(spinner(true));
            action(clearDashboardData());
            distributors.dashboard(timespan).then(({data})=>{
                action(setDashboardData(data.data, timespan));
                action(spinner(false));
            })
            .catch(err => {
                action(spinner(false));
                console.error(err);
            });
        }
    };
    
    render() {
        
        // const dashboardClass = isMobile ? 'container-fluid has-sub-header mobile pad-sides-20' : 'container-fluid content-container has-header pad-sides-20';
        const clientsData = this.props.data.data;
        const clientsMap = clientsData.map((c)=>
            <ClientCard {...c} key={c.merchantId} dispatch={this.props.dispatch} id={c.merchantId} history={this.props.history} timespan={this.props.match.params.timespan} />
        );
        const timespanButtons = {struct: <HomeSubHeaderDesktop/>};
        
        return (
            <Container fluid={true} className={isMobile ? 'px-1' : 'px-5'} >
                <ShowIf show={!isMobile}>
                    <Header showMenu={true} showLogo={true} showBack={false} title='Partner Dashboard' showCart={false} timespan={timespanButtons}/>                    
                </ShowIf>
                <ShowIf show={isMobile}>
                    <Header showMenu={true} showLogo={false} showBack={false} title='Partner Dashboard' showCart={false}>
                        <HomeSubHeaderMobile/>
                    </Header>                    
                </ShowIf>                
                <Row className={isMobile ? 'mt-2' : 'mt-4'} >
                    { clientsMap }
                </Row>                
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        timespan: {
            timespan: state.userInterface.timespan,
            itemsPage: state.userInterface.itemsPage,
            messages: state.userInterface.messages
        },
        data: {
            data: state.dashboard.data,
            timespan: state.dashboard.timespan
        }
    }
}

export default connect(mapStateToProps)(DashboardList);
