import React, { Component } from 'react';

class ModalCloseButton extends Component{
    render() {
        return (
            <button type="button" className="close" data-dismiss="modal">&times;</button>
        )
    }
}

export default ModalCloseButton;

