import React from 'react';
import { Container } from "reactstrap";
import './AdminLogin.scss';
import AdminLoginHeader from './AdminLoginHeader';
import { useSelector } from 'react-redux';
import AdminLoginForm from './AdminLoginForm';
import logo from '../../assets/images/foodbam-logo.png';

const AdminLogin = (props) => { 

  return (
    <Container fluid={true} className='p-0 admin-login-app'>
      <AdminLoginHeader logo={logo} />
      <AdminLoginForm />
    </Container>
  )
};

export default AdminLogin;