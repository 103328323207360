import React, { Component } from 'react'
// import Ionicon from 'react-ionicons';
import { IoMdAlert } from 'react-icons/io';
import { isMobile } from 'react-device-detect';
import * as $ from 'jquery';


class HoverWarning extends Component {
    componentWillMount = () => {
        this.id = 'alert'+this.makeId();   
    };
    componentDidMount = () => {
        const popoverClass = ( this.props.popoverClass === undefined || this.props.popoverClass === null ) ? '' : ' '+this.props.popoverClass;
        const template = '<div class="popover text-center popover-shadow' + popoverClass + '"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        $('#'+this.id).popover({
            template: template,
            content: this.props.content,
            delay: {
                "show": ( this.props.showDelay === undefined || this.props.showDelay === null ) ? 0 : parseInt(this.props.showDelay, 10),
                "hide": isMobile ? 0 : ( this.props.hideDelay === undefined || this.props.hideDelay === null ) ? 500 : parseInt(this.props.hideDelay, 10) 
            }
        });
        
    }
    makeId = () => {
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    render() {
        const { style, className, color, fontSize } = this.props;
        
        return (
            <span id={this.id} data-html="true" data-trigger="hover" data-placement="top" style={style} className={className} >
                <IoMdAlert fontSize={ fontSize } color={ color } /></span>
        );
    }
}

export default HoverWarning;