import { valHooks } from 'jquery';
import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, CustomInput } from 'reactstrap';
import useModal from '../../../../../SharedComponents/Modals/useModal';
import '../OrderGuideList/OrderGuideUpload.scss';
import moment from 'moment';
import Moment from 'react-moment';

const ManualOGModal = (props) => {
  const { id, onOk, btnLabel, btnSize, footerClass, color, startDate, endDate } = props;
  const modalprops = useModal(id);
  
    const onCancel = () => {
        modalprops.toggle();
        };

    const onClickOk = () => {
        if(onOk){
            onOk();
        }
        modalprops.toggle();
    };

  return (
    <Modal {...modalprops} backdrop='static' keyboard={false}>
        <ModalBody className='text-center'>
            <Row className='py-2 px-3'>
                <Col className='text-center'>
                    {
                        `You’re about to update pricing for the following date range ${moment(startDate).format('MMMM DD, yyyy')} - ${moment(endDate).format('MMMM DD, yyyy')}`
                    }                    
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Row className='w-100'>
                <Col className='w-100 text-left'>
                    <Button color={color} size={btnSize} onClick={onCancel} className='btn-outlined'>Cancel</Button>
                </Col>
                <Col className='w-100 text-right'>
                    <Button color={color} size={btnSize} onClick={onClickOk}>Proceed</Button>
                </Col>
            </Row>            
        </ModalFooter>
    </Modal>
  );
};

ManualOGModal.defaultProps = {
  footerClass: '',
  btnLabel: 'Ok!',
  btnSize: 'sm',
  color: 'green'
};

export default ManualOGModal;
