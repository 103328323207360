import React, { Component } from 'react';

class LargeModal extends Component {
    render() {
        const props = this.props;
        const customClass = props.customClass === undefined ? '' : props.customClass;
        return (
            <div className="modal fade"  id={props.modalId}>
                <div className={"modal-dialog modal-lg "+customClass}>
                    <div className="modal-content">
                        {props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default LargeModal;