import React, { Component } from 'react';
import ModalCloseButton from './_ModalClose';

class ModalHeader extends Component{
    render() {
        const props = this.props;
        return (
            <div className="modal-header">
                <h5 className="modal-title text-center mobile-100"> {props.title}</h5>
                { this.props.noButton ? null : <ModalCloseButton /> }
            </div>
        )
    }
}

export default ModalHeader;

