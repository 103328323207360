import React, { Component, Fragment } from 'react'
import LegendRow from './_LegendRow';

class Legend extends Component {
    render() {
        const allData = this.props.data;
        const legendMap = allData.map((c, i)=>
            <LegendRow key={i} {...c} />
        );
        return (
            <Fragment>
                {legendMap}
            </Fragment>
        );
    }
}

export default Legend;