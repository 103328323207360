import React, { Component } from 'react';

class ModalTitle extends Component {
    render() {
        const props = this.props;
        return (
            <div className="modal-header">
                <h5 className="modal-title text-center">{props.title}</h5>
            </div>
        );
    }
}

export default ModalTitle;