import React, { Component } from 'react';
import { withRouter } from 'react-router'
import FakeDropdown from '../../../../../../SharedComponents/FakeDropdown/_FakeDropdown';
import { Container, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';

class mHomeSubHeader extends Component {
    componentWillMount() {
        this.unlisten = this.props.history.listen(location => {
            this.setState({location});
        });
    };
    componentWillUnmount() {
        this.unlisten();
    };
    render() {
        const path = this.props.history.location.pathname;
        const timespan = path === '/dashboard/monthly' ? 'Monthly' : (path === '/dashboard/weekly' ? 'Weekly' : (path === '/dashboard/yearly' ? 'Year to Date' : 'Custom'));
        return (
            <Row className={`header d-flex align-items-center m-0 ${isMobile ? 'isMobile' : ''}`}>
                <Col className='px-3'>
                    <FakeDropdown title={timespan} modalId='ShowByModal' />
                </Col>                    
            </Row>
        );
    }
}

export default withRouter(mHomeSubHeader);