import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { Container, Card, Row, Col, Button } from 'reactstrap';
import { MdArrowDownward, MdArrowUpward} from 'react-icons/md';
// import Ionicon from 'react-ionicons';
import { setSort } from '../../../../../reducers/userInterface/userInterfaceActions';
import * as $ from 'jquery';
// const $ = window.jQuery;

class ItemsRowHeader extends Component {
    componentDidMount = () => {
        if (!isMobile) {
            const headerElem = $('#itemsTableHeader');
            const lockOffset = headerElem.offset().top-80;
            $(window).scroll( function() {
                const currentScroll = $(window).scrollTop();
                if (currentScroll >= lockOffset) {
                    headerElem.css('width', headerElem.outerWidth()+'px');
                    headerElem.addClass('locked');
                } else {
                    headerElem.removeClass('locked');
                    headerElem.css('width', 'initial');
                }
            });
        }
    };
    changeSort = (e) => {
        const sortActive = this.props.sort;
        const ascActive = this.props.asc;
        const sort = e.currentTarget.dataset.sort;
        const action = this.props.dispatch;
        if (sort === sortActive) {
            action(setSort(sort, !ascActive));
        } else {
            action(setSort(sort, true));
        }

    };
    render() {
        // If showDin is true that means we are on Items Won Section
        const { showDin, sort, asc, showNotStocked } = this.props;
        const props = this.props;
        if (isMobile) {
            return (
                <Col xs={12} className='my-2' style={{fontSize:'14px'}}>
                    <Card className='border-top'>
                        <Row className='mt-3 text-center'>
                            <Col className='col'>{props.title}</Col>
                        </Row>
                        <Row className='m-3'>
                            <Col xs={3} className='text-left pl-0'>{this.props.percentage}</Col>
                            <Col xs={4} className='text-right border-left'>{this.props.totalQty}</Col>
                            <Col xs={5} className='text-right border-left pr-0'>{this.props.totalCase}</Col>
                        </Row>
                    </Card>
                </Col>
            );
        } else {
            return (
                <Fragment>
                    <Row className='mx-0 my-3 dashboard-items-headerMain font-weight-bold'>
                        <Col xs={7} className='text-left'>{this.props.title}</Col>
                        <Col xs={1} className='text-right'>{this.props.percentage}</Col>
                        <Col xs={2} className='text-right border-left'>{this.props.totalQty}</Col>
                        <Col xs={2} className='text-right border-left'>{this.props.totalCase}</Col>
                    </Row>
                    <div id='itemsTableHeader' className='col border-top border-bottom'>
                        <div className='row no-gutters dashboard-items-header mar-top-15 mar-bottom-15'>
                            <div className='col-1'>Generic Group</div>
                            { showDin ? <div className='col-1'>DIN</div> : null }
                            <div className={ showDin ? 'col-2' : ( showNotStocked ? 'col-3' : 'col-4') }>Description</div>
                            <div className='col-1'>Brand</div>
                            <div className='col-1'>UOM</div>
                            <div className='col-1'>Packsize</div>
                            <div className='row no-gutters col-3 align-items-center'>
                                <div data-sort='price' onClick={this.changeSort} className={'clickable col-4 d-flex align-items-center '+(sort === 'price' ? 'text-black' : '')}>
                                    { showDin ? 'Price' : 'Market Index' } <span>&nbsp;</span>
                                    {
                                        ( sort==='price' && !asc ) ?
                                            <MdArrowUpward className='clickable' size={18} color={ sort === 'price' ? '#143357' : '#a6b7c4' }/>
                                            :
                                            <MdArrowDownward className='clickable' size={18} color={ sort === 'price' ? '#143357' : '#a6b7c4' } />
                                    }
                                </div>
                                <div className='col-3'>Rebate</div>
                                <div data-sort='unitPrice' onClick={this.changeSort} className={'clickable col-5 d-flex align-items-center '+(sort === 'unitPrice' ? 'text-black' : '')}>
                                    { showDin ? 'Unit Price' : 'Unit Market Index' } <span>&nbsp;</span>
                                    {
                                        ( sort==='unitPrice' && !asc ) ?
                                            <MdArrowUpward className='clickable' size={18} color={ sort === 'unitPrice' ? '#143357' : '#a6b7c4' }/>
                                            :
                                            <MdArrowDownward className='clickable' size={18} color={ sort === 'unitPrice' ? '#143357' : '#a6b7c4' } />
                                    }
                                </div>
                            </div>
                            {
                                showDin ?
                                <div data-sort='total_purchased' onClick={this.changeSort} className={'clickable col-1 d-flex align-items-center '+(sort === 'total_purchased' ? 'text-black' : '')}>
                                    Total Spend <span>&nbsp;</span>
                                    {
                                        ( sort==='total_purchased' && !asc ) ?
                                            <MdArrowUpward className='clickable' size={18} color={ sort === 'total_purchased' ? '#143357' : '#a6b7c4' }/>
                                            :
                                            <MdArrowDownward className='clickable' size={18} color={ sort === 'total_purchased' ? '#143357' : '#a6b7c4' } />
                                    }
                                </div>
                                :
                                null
                            }
                            { showNotStocked ? <div className='col-1'>Not Stocked</div> : null }
                            <div data-sort='total_quantity' onClick={this.changeSort} className={'clickable col-1 d-flex align-items-center '+(sort === 'total_quantity' ? 'text-black' : '')}>
                                Total Cases <span>&nbsp;</span>
                                {
                                    ( sort==='total_quantity' && !asc ) ?
                                        <MdArrowUpward className='clickable' size={18} color={ sort === 'total_quantity' ? '#143357' : '#a6b7c4' }/>
                                        :
                                        <MdArrowDownward className='clickable' size={18} color={ sort === 'total_quantity' ? '#143357' : '#a6b7c4' } />
                                }
                            </div>
                        </div>
                        <div className='row no-gutters dashboard-items-header'>
                            <div className={`row no-gutters col-4 ${ showDin || showNotStocked ? 'offset-6':'offset-7'}`}>
                                <div data-sort='price' onClick={this.changeSort} className='col-4'>
                                    { sort === 'price' ? <div className='sort-active absolute-100' ></div> : null }
                                </div>
                                <div data-sort='unitPrice' onClick={this.changeSort} className='col-5 offset-3'>
                                    { sort === 'unitPrice' ? <div className='sort-active absolute-100' ></div> : null }
                                </div>
                            </div>
                            {
                                showDin ?
                                <div data-sort='total_purchased' onClick={this.changeSort} className='col-1'>
                                    { sort === 'total_purchased' ? <div className='sort-active absolute-100' ></div> : null }
                                </div>
                                :
                                null
                            }
                            <div data-sort='total_quantity' onClick={this.changeSort} className={`col-1 ${ showNotStocked ? 'offset-1':''}`}>
                                { sort === 'total_quantity' ? <div className='sort-active absolute-100' ></div> : null }
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

export default ItemsRowHeader;
