import React, { Component } from 'react';

class BottomModal extends Component {
    render() {
        const props = this.props;
        return (
            <div className="modal fade fade-down fb-modal-bottom" id={props.modalId}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        {props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomModal;