import React, { Component } from 'react';

class mTitleSubheader extends Component {
    goToPriceGraph = () => {
        const url = this.props.history.location.pathname;
        this.props.history.push(url + '/priceGraph');
    };
    render() {
        return (
            <div className='sub-header container-fluid mobile'>
                <div className='sub-header-container row no-gutters align-items-center'>
                    <div className='col text-center size-14'>
                       {this.props.title}
                    </div>
                </div>
            </div>
        );
    }
}

export default mTitleSubheader;