import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SidebarHeader from './SidebarHeader';
import SidebarBody from './SidebarBody';
import { connect, useSelector, useDispatch } from 'react-redux';
import './Sidebar.scss';
import { localStorageConst } from '../../config/constants';

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const open = useSelector(state => state.userInterface.isSidebarOpen);
  const distributorLocal = JSON.parse(localStorage.getItem(localStorageConst.keys.distributor));
  const distributorStore = useSelector(({ user }) => user.distributor);
  const [distributor, setDistributor] = useState(!!distributorLocal ? distributorLocal : distributorStore);
  
  return (
    <Col className={'sidebar m-0 p-0' + (open ? ' sidebar-open' : '')}>
      <Container className="p-0">
        <SidebarHeader distributor={distributor}/>
        <SidebarBody />
      </Container>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const { userInterface } = state;
  return {
    open: userInterface.isSidebarOpen
  };
};

export default connect(mapStateToProps)(Sidebar);
