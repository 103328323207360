import { useState } from "react";
import validate from 'validate.js';
import useInput from "../../SharedComponents/SharedEffects/useInput";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import usersApi from "../../api/usersApi";
import AlertModal from '../../SharedComponents/Modals/AlertModal/AlertModal';
import { showModal } from '../../reducers/modals/modalsActions';

const useForm = (tokenSecret) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState({});
  const { set : setPassword, value: password } = useInput.useText();
  const { set : setConfirmPassword, value: confirmPassword } = useInput.useText();
  const { set : setToken, value: token } = useInput.useText(tokenSecret);

  const goToLogin = () => {
    history.push('/login');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = {
      token: token,
      password: password,
      confirmPassword: confirmPassword
    };
    const constrains = {
      token: {
        presence: {
          allowEmpty: false
        }
      },
      password: {
        presence: {
          allowEmpty: false
        }
      },
      confirmPassword: {
        presence: {
          allowEmpty: false
        },
        equality: "password"
      }
    };

    const result = validate(form, constrains);
    setError(result ? result : {});

    if (!result) {
      const params = {
        password: password,
        password_repeat: confirmPassword
      };
      usersApi.resetPassword(token, params).then(({data}) => {
        dispatch(showModal(AlertModal, {message: data && data.data.message, onOk: () => goToLogin() }));
      }).catch(err => {
        console.error(err);
        const status = err.code;
        if (status === 400) {
          setError({error: err.data.message});
        } else {
          setError({error: 'Something went wrong.'});
        }
      });
    }
  };

  return { error, onSubmit, setPassword, password, setConfirmPassword, confirmPassword }
};

export default {
  useForm
};
