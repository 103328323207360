import React, { Component } from 'react';
import LargeModal from '../LargeModal';
import ModalBody from '../_ModalBody';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// import Ionicon from 'react-ionicons';
import { isMobile } from 'react-device-detect';
import { IoMdCalendar } from 'react-icons/io';
import { Row, Col, Button } from 'reactstrap';
import '../../../config/css/Basic.css'

class CustomDatePickerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().toDate(),
            endDate: moment().toDate()
        };
    };
    handleStart = (date) => {
        this.setState({
            startDate: date
        });
    };
    handleEnd = (date) => {
        this.setState({
            endDate: date
        });
    };
    openStartCalendar = () => {
        this._startCalendar.setOpen(true);
    };
    openEndCalendar = () => {
        this._endCalendar.setOpen(true);
    };
    preventType = (e) => {
        e.preventDefault();
    };
    go = () => {
        const {startDate, endDate} = this.state;
        this.props.onGo(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
    };
    render() {
        if (isMobile) {
            return (
                <LargeModal modalId={this.props.modalId}>
                    <ModalBody>
                        <Row className='row no-gutters align-items-center justify-content-center mar-bottom-20 mar-top-30'>
                            <Col className='col-4 text-bold'>
                                Start Date:<span>&nbsp;&nbsp;</span>
                            </Col>
                            <Col className='col-8'>
                                <DatePicker popperPlacement="bottom-end" 
                                    onKeyDown={this.preventType} 
                                    locale='en' 
                                    selected={this.state.startDate} 
                                    dateFormat="MM/dd/yyyy" 
                                    onChange={this.handleStart} 
                                    ref={(c) => this._startCalendar = c}
                                />
                                <span onClick={this.openStartCalendar} className='calendar-button clickable'>
                                    <IoMdCalendar size={22} color='#555' />
                                </span>
                            </Col>
                        </Row>
                        <Row className='row no-gutters align-items-center justify-content-center mar-bottom-20'>
                            <Col className='col-4 text-bold'>
                                End Date:<span>&nbsp;&nbsp;</span>
                            </Col>
                            <Col className='col-8'>
                                <DatePicker popperPlacement="bottom-end" 
                                    onKeyDown={this.preventType} 
                                    locale='en' 
                                    selected={this.state.endDate} 
                                    dateFormat="MM/dd/yyyy" 
                                    onChange={this.handleEnd} 
                                    ref={(c) => this._endCalendar = c}
                                />
                                <span onClick={this.openEndCalendar} className='calendar-button clickable'>
                                    <IoMdCalendar size={22} color='#555' />
                                </span>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-center justify-content-center mar-bottom-20'>
                            <div className='col text-center'>
                                <Button color='green' data-dismiss="modal" onClick={this.go} className='btn fb-button-green mobile-100 block'>Go</Button>
                            </div>
                        </Row>
                    </ModalBody>
                </LargeModal>
            );
        } else {
            return (
                <LargeModal modalId={this.props.modalId} customClass='mar-top-100'>
                    <ModalBody>
                        <Row className='d-flex align-items-center justify-content-center mar-bottom-20 mar-top-30'>
                            <Col xs={2} className='text-right text-bold'>
                                Start Date:<span>&nbsp;&nbsp;</span>
                            </Col>
                            <Col xs={3} >
                                <DatePicker onKeyDown={this.preventType} 
                                    locale='en' 
                                    selected={this.state.startDate} 
                                    dateFormat="MM/dd/yyyy"
                                    onChange={this.handleStart} 
                                    ref={(c) => this._startCalendar = c}
                                />
                                <span className='calendar-button clickable' onClick={this.openStartCalendar}>
                                    <IoMdCalendar size={22} color='#555' />
                                </span>
                            </Col>
                            <Col xs={2} className='text-right text-bold'>
                                End Date:<span>&nbsp;&nbsp;</span>
                            </Col>
                            <Col xs={3} >
                                <DatePicker onKeyDown={this.preventType} 
                                    locale='en' 
                                    selected={this.state.endDate} 
                                    dateFormat="MM/dd/yyyy" 
                                    onChange={this.handleEnd} 
                                    ref={(c) => this._endCalendar = c}
                                />
                                <span className='calendar-button clickable' onClick={this.openEndCalendar} >
                                    <IoMdCalendar size={22} color='#555' />
                                </span>
                            </Col>
                            <Col xs={2} className='text-center'>
                                <Button color='green' data-dismiss="modal" onClick={this.go} className='btn fb-button-green pad-sides-20 block'>Go</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </LargeModal>
            );
        }
    }
}

export default CustomDatePickerModal;