export const setDashboardData = (data, timespan) => {
    return {
        type: 'SET_DATA',
        payload: {
            data: data,
            timespan: timespan
        }
    }
};

export const clearDashboardData = () => {
    return {
        type: 'CLEAR_DATA'
    }
};

export const setDashboardDetail = (data) => {
    return {
        type: 'SET_DETAIL_DATA',
        payload: data
    }
};

export const clearDashboardDetail = () => {
    return {
        type: 'CLEAR_DETAIL',
    };
};

export const selectedMerchantId = (id) => {
    return {
        type: 'SET_MERCHANT_ID',
        payload: id,
    }
};

export const selectedItemDistId = (id) => {
    return {
        type: 'SET_ITEM_DIST_ID',
        payload: id,
    }
};

export const setDashboardDataDates = (startDate, endDate) => {
    return {
        type: 'SET_DATA_DATES',
        payload: {
            startDate: startDate,
            endDate: endDate
        }
    }
};

export const setGraphData = (data, title) => {
    return {
        type: 'SET_GRAPH_DATA',
        payload: {
            data: data,
            title: title
        }
    }
};

export const clearGraphData = () => {
    return {
        type: 'CLEAR_GRAPH_DATA'
    }
};

export const setCalendarData = (data) => {
    return {
        type: 'SET_CALENDAR_DATA',
        payload: data
    }
};

export const clearCalendarData = () => {
    return {
        type: 'CLEAR_CALENDAR_DATA'
    }
};
