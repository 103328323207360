import React, { Component } from 'react';
import {connect} from 'react-redux';
import { isMobile } from 'react-device-detect';
import LargeModal from '../LargeModal';
import ModalHeader from '../_ModalHeader';
import ModalBody from '../_ModalBody';
import messages from '../../../api/messageApi';
import { spinner, setMessages } from '../../../reducers/userInterface/userInterfaceActions';
import * as $ from 'jquery';

class MessageModal extends Component {
    handleMessage = (date) => {
        // const $ = window.jQuery;
        const action = this.props.dispatch;
        let distributorMessages = this.props.timespan.messages;
        action(spinner(true));
        messages.readMessages(distributorMessages).then(({data})=>{
            if (data.data){
                distributorMessages = {};
                action(setMessages(distributorMessages));
                action(spinner(false));
                $('#MessageModal').modal('hide');
            }
        });
    };

    render() {
        const messages = this.props.timespan.messages;
        let lineMessages = '';
        for (let i = 0; i < messages.length; i++) {
          lineMessages += messages[i].message + ' \n';
        }

        return (
            <LargeModal modalId={this.props.modalId}>
                <ModalHeader title ='Messages' />
                <ModalBody>
                    <div className='row no-gutters align-items-center'>
                        <div className='col text-left'>
                            {lineMessages}
                        </div>
                    </div>
                    <div className='row no-gutters align-items-center'>
                        <div className='col text-center'>
                            <button onClick={this.handleMessage} className={ isMobile ? 'btn fb-button-green-outline size-17 text-bold mobile-100' : 'btn fb-button-green-outline size-17 text-bold' }>Close</button>
                        </div>
                    </div>
                </ModalBody>
            </LargeModal>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        timespan: {
            timespan: state.userInterface.timespan,
            itemsPage: state.userInterface.itemsPage,
            messages: state.userInterface.messages
        },
        data: {
            data: state.dashboard.data,
            timespan: state.dashboard.timespan
        }
    }
}

export default connect(mapStateToProps)(MessageModal);
