import React, {Component} from 'react';
import {connect} from 'react-redux';
import back from '../../../assets/images/back.png';
import { isMobile } from 'react-device-detect';
import ModalBody from '../_ModalBody';
import ModalCloseButton from '../_ModalClose';
import * as $ from 'jquery';
import LineChart from '../../Highcharts/LineChart';
// import { setHeaderBackUrl, showHeaderBack, setHeaderTitle, setLoginHeader, hideHeader } from '../../../actions/headerActions';
// import { hideMobileSubheader, hideDesktopSubheader } from '../../../actions/subheaderActions';
import ExtraLargeModal from '../ExtraLargeModal';
import Select from 'react-select';
import { setShowInGraph,spinner } from '../../../reducers/userInterface/userInterfaceActions';
import dashboardApi from '../../../api/dashboardApi';
import { setGraphData, clearGraphData, setDashboardData, setDashboardDetail, clearDashboardDetail } from '../../../reducers/dashboard/dashboardActions';
import uniqid from 'uniqid';
import { Row, Col } from 'reactstrap';

// const $ = window.jQuery;

class LineGraphModal extends Component {
    changeSelect = (selected) => {
        const action = this.props.dispatch;
        action(setShowInGraph(selected.value))
        // Make a fake load graph data
        const oldData = this.props.graphData.slice();
        const oldTitle = this.props.graphTitle;
        action(clearGraphData());
        action(spinner(true));
        setTimeout(()=>{
            action(setGraphData(oldData, oldTitle));
            action(spinner(false));
        }, 2000);
    };
    openMobileModal = (e) => {
        $('#ShowGraphTypeModal').modal();
    };
    goBack = () => {
        this.props.history.goBack();
    };
    componentDidMount = () => {
        const action = this.props.dispatch;
        if (isMobile) {
            this.selectRef = React.createRef();
            // Set subheader
            // action(hideMobileSubheader());
            // action(hideDesktopSubheader());
            // Set header
            // action(showHeaderBack(true));
            // action(setHeaderTitle('Partner Dashboard'));
            // action(setLoginHeader(false));
            // action(setHeaderBackUrl(null));
            this.loadDashboardData();
            // action(hideHeader(true));
            $('html, body').scrollTop(0);
        }
    }
    loadDashboardData = () => {
        const action = this.props.dispatch;
        const timespan = this.props.match.params.timespan
        const startDate = timespan === 'custom' ? this.props.startDate : null;
        const endDate = timespan === 'custom' ? this.props.endDate : null;
        if (this.props.data.data.length === 0) {
            action(spinner(true));
            dashboardApi.dashboard(timespan, startDate, endDate).then(({data}) => {
                action(setDashboardData(data.data, timespan));
                action(spinner(false));
                this.loadDetailData();
            });
        } else {
            this.loadGraphData();
        }
    }
    loadDetailData = () => {
        const action = this.props.dispatch;
        const merchantId = this.props.match.params.idMerchant;
        const timespan = this.props.match.params.timespan
        const startDate = timespan === 'custom' ? this.props.startDate : null;
        const endDate = timespan === 'custom' ? this.props.endDate : null;
        action(clearDashboardDetail());
        action(spinner(true));
        dashboardApi.dashboardDetail(timespan, merchantId, startDate, endDate).then(({data}) => {
            action(setDashboardDetail(data.data));
            action(spinner(false));
            this.loadGraphData();
        });
    }
    loadGraphData = () => {       
        const action = this.props.dispatch;
        const {itemId, itemsPage, idMerchant} = this.props.match.params;
        let merchantId = this.props.match.params.idMerchant;
        action(clearGraphData());
        action(spinner(true));
        if (itemId === 'all') {
            for (let i = 0; i < this.props.data.data.length; i++) {
                const elem = this.props.data.data[i];
                if (elem.merchantId === parseInt(idMerchant, 10)) {
                    // action(setHeaderTitle(this.capitalize(elem.merchant)));
                    break;
                }
            }
            dashboardApi.dashboardGraph(merchantId).then(({data }) => {
                action(setGraphData(data.data, 'Total Purchases'));
                action(spinner(false));
            });
        } else {
            for (let i = 0; i < this.props.data.detail[itemsPage].items.length; i++) {
                const elem = this.props.data.detail[itemsPage].items[i];
                if (elem.id === parseInt(itemId, 10)) {
                    // action(setHeaderTitle(this.capitalize(elem.description)));
                    break;
                }
            }
            dashboardApi.dashboardGraphItem(merchantId, itemId, itemsPage).then(({data }) => {
                action(setGraphData(data.data, ''));
                action(spinner(false));
            });
        }


    }
    capitalize = (str) => {
        return str.replace(/\w\S*/g, function(txt){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
    componentWillUnmount = () => {
        const action = this.props.dispatch;
        // action(hideHeader(false));
    };
    render(){
        let type='all';
        const options = [
            {value: '$', label: '$'},
            {value: 'cs', label: 'cs'},
            {value: '%', label: '%'}
        ];
        const nickname = JSON.parse(localStorage.getItem('distributor')) === null ? '' : JSON.parse(localStorage.getItem('distributor')).nickname;
        if (isMobile){
            type = (this.props.match.params.itemId === 'all') ? 'all' : 'item';
        } else {
            type = (this.props.modalId === 'GraphId') ? 'all' : 'item';
        }
        const graphData = this.props.graphData;
        let showInValue = this.props.showIn;
        let showInOption = options.filter(opt => opt.value == showInValue);

        let graphDataFinal = [];
        let timeline = [];
        if (graphData.length) {
            let valueSerie;
            for (let i = 0; i < graphData.length; i++) {
                let graphDataSeries = [];
                for (let j = 0; j < graphData[i].data.length; j++) {
                    if (i === 0) {
                        timeline.push(graphData[i].data[j].monthYear);
                    }
                    if (showInValue === '$') {
                        if (type === 'item') {
                            if (graphData[i].data[j].price === 0 && j !== 0) {
                                graphData[i].data[j].price = graphData[i].data[j - 1].price;
                            }
                            valueSerie = graphData[i].data[j].price;
                            graphDataSeries.push(valueSerie);
                        } else {
                            valueSerie = graphData[i].data[j].sales;
                            graphDataSeries.push(valueSerie);
                        }
                    } else if (showInValue === 'cs') {
                        valueSerie = graphData[i].data[j].cases;
                        graphDataSeries.push(valueSerie);
                    } else {
                        valueSerie = graphData[i].data[j].percentage;
                        graphDataSeries.push(valueSerie);
                    }
                }
                const obj = {
                    name: graphData[i].name === 'Best Price' ? 'Best Competitor Price' : graphData[i].name,
                    data: graphDataSeries
                }
                
                graphDataFinal.push(obj);
            }
        }
        
        // Sort Properly
        if (graphDataFinal.length) {
            graphDataFinal.sort((a, b) => {
                if (a.name === nickname) return -1;
                if (b.name === nickname) return 1;
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
            if (type === 'all') {
                let arrayNew = [];
                let modlegend = isMobile ? 2 : 3;
                for (let k = 0; k < graphDataFinal.length; k++) {
                    let objArray = {};
                    if (k === 0) {
                        objArray =
                            {
                                "name": graphDataFinal[k].name,
                                "data": graphDataFinal[k].data,
                                "color": "#434348"
                            };                        
                        arrayNew.push(objArray);
                    } else {
                        if (k !== 0 && arrayNew.length % modlegend === 0) {
                            objArray =
                                {
                                    "name": " ",
                                    "data": [],
                                    "color": "#fff"
                                };
                            arrayNew.push(objArray);
                            objArray =
                                {
                                    "name": graphDataFinal[k].name,
                                    "data": graphDataFinal[k].data,
                                    "color": getRandomColor(k)
                                };
                            arrayNew.push(objArray);
                        } else {
                            objArray =
                            {
                                "name": graphDataFinal[k].name,
                                "data": graphDataFinal[k].data,
                                "color": getRandomColor(k)
                            };
                            arrayNew.push(objArray);                           
                        }
                    }
                }
                graphDataFinal = arrayNew;
            }
        }

        function getRandomColor(k) {
            if (k < 9) {
                //First 9 colors by default
                let arrayColor = ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b'];
                return arrayColor[k];
            } else {
                let letters = '0123456789ABCDEF'.split('');
                let color = '#';
                for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }
        }

        

        if (isMobile){
            const itemId = this.props.match.params.itemId;
            const fullScreen = {
                height: '100vh',
                width: '100%'
            };
            const header = {
                height: '45px',
                position: 'absolute',
                width: '100%',
                zIndex: '1'
            }
            const padRight = { paddingRight: '10px' }
            return (
                <div className='force-landscape'>
                    <div className='fb-background-white' style={fullScreen}>
                        <div className='row no-gutters' style={header}>
                            <div className='col-1 mar-top-5'>
                                <span>&nbsp;&nbsp;</span><span className='clickable' onClick={this.goBack}><img src={back} alt='back' /></span>
                            </div>
                            { itemId ==='all' ?
                                <div className='col-11 mar-top-5 d-flex align-items-baseline justify-content-end' style={padRight}>
                                    Show in: <span>&nbsp;&nbsp;</span>
                                    <Select className='hide-menu'  style={{width:'75px'}}
                                        onFocus={this.openMobileModal} 
                                        name='showInGraph' 
                                        searchable={false} 
                                        clearable={false} 
                                        onChange={this.changeSelect} 
                                        value={showInOption}
                                        options={options} />
                                </div>
                            :
                                null
                            }
                        </div>
                        <LineChart data={graphDataFinal} xAxis={timeline} showIn = {this.props.showIn} title = {this.props.graphTitle} id={itemId === 'all' ? `graph-all` : `graph-item`}/>
                    </div>
                </div>
            )
        } else {
            const inline = {
                position: 'absolute',
                width: '100%',
                zIndex: '1',
                top: '33px'
            };
            
            return(
                <div>
                    <ExtraLargeModal  modalId={this.props.modalId}>
                        <ModalBody>
                            <ModalCloseButton/>
                        </ModalBody>
                        { this.props.showDropdown ?
                            <ModalBody style={inline}>
                                <Row>
                                    <Col xs={11} className='d-flex align-items-center justify-content-end'>
                                        Show in: <span>&nbsp;&nbsp;</span>
                                        <Select className='' style={{width:'75px'}} 
                                            name='showInGraph' 
                                            searchable={false} 
                                            clearable={false} 
                                            onChange={this.changeSelect} 
                                            value={showInOption}
                                            options={options} />
                                    </Col>
                                </Row>
                            </ModalBody>
                        :
                            null
                        }
                        <ModalBody>
                            <LineChart data={graphDataFinal} xAxis={timeline} showIn = {this.props.showIn} title = {this.props.graphTitle} id={this.props.showDropdown ? `graph-all` : `graph-item`} />
                        </ModalBody>
                        <ModalBody></ModalBody>
                    </ExtraLargeModal>
                </div>
            )

        }
    }
}

const mapStateToProps = (state) => {
    return {
        showIn : state.userInterface.showInGraph,
        graphData: state.dashboard.graphData,
        graphTitle: state.dashboard.graphTitle,
        data: {
            data: state.dashboard.data,
            detail: state.dashboard.detailData,
            timespan: state.dashboard.timespan
        }
    }
}
export default connect(mapStateToProps)(LineGraphModal);
