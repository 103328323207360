import axios from 'axios';

const getAvailableMerchants = () => {
  return axios.get('merchant/merchant-distributor');
}

const getList = () => {
    return axios.get('merchant/list');
  }

const getChildren = (id) => {
return axios.get(`merchant/${id}/children`);
}

const getAllowedChildren = (id) => {
  return axios.get(`merchant/${id}/allowed-children`);
}

const getAllowedChildrenPartner = (id) => {
  return axios.get(`merchant/${id}/allowed-children-partner`);
}

export default {
    getAvailableMerchants, getList, getChildren, getAllowedChildren, getAllowedChildrenPartner
}