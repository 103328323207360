import React, { Component } from 'react'

class LegendRow extends Component {
    render() {
        const props = this.props;
        return (
            <div className='row no-margin mar-top-20 mar-bottom-20'>
                <div className='col-6 size-12 text-ellipsis text-left pl-4 pr-0'>
                    <svg version="1.1" id="Layer_1" x="0px" y="0px"
                        width="12px" height="12px" viewBox="0 0 12 12" enableBackground="new 0 0 12 12">
                        <circle cx="5" cy="7" r="3" fill={props.color}/>
                    </svg> <span>&nbsp;&nbsp;</span>
                    {props.name}
                </div>
                <div className='col-6 size-12 text-right pr-4 pl-0'>
                    ${props.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})} ({props.perc})
                </div>
            </div>
        );
    }
}

export default LegendRow;