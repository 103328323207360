import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Button, Form } from 'reactstrap';
import LoginFormInput from './LoginFormInput';
import { showModal } from '../../reducers/modals/modalsActions';
import ForgotPasswordModal from '../../SharedComponents/Modals/ForgotPasswordModal/ForgotPasswordModal';

const LoginForm = (props) => {
  const { setEmail, email, setPassword, password, onSubmit, error } = props;
  const history = useHistory();
  const dispatch = useDispatch();


  return (
    <Form className='login-form flex-column m-auto' noValidate="novalidate" onSubmit={onSubmit}>
      <Col className='mt-5 text-center mb-5'>
        <h4>Sign In</h4>
      </Col>
      <LoginFormInput label='Email' type='email' placeholder='Email' value={email} set={setEmail} error={error.email ? error.email[0] : ''} />
      <LoginFormInput label='Password' type='password' placeholder='Password' value={password} set={setPassword} error={error.password ? error.password[0] : ''} />
      <Col className='text-center mt-5'>
        <Button color='blue' size='lg' className='w-100' type='submit'>SIGN IN</Button>
      </Col>
      <Col className='text-danger text-center'>{error.error ? error.error : ''}</Col>
      <Col className='mt-5 text-center text-blue clickable' onClick={() => dispatch(showModal(ForgotPasswordModal))}>FORGOT PASSWORD</Col>

    </Form>
  );
};

export default LoginForm;
