import axios from "axios";

const merchantLogin = (params) => {
  return axios.post('users/merchant-login', params);
};

const currentuser = () => {
  return axios.get('users/current-user');
};

const requestPasswordReset = (email) => {
  let baseURL = process.env.REACT_APP_API_URL;
  baseURL = baseURL.replace('dp', 'v1');
  return axios.post('users/request-password-reset', {email}, {baseURL});
};

const resetPassword = (token, params) => {
  let baseURL = process.env.REACT_APP_API_URL;
  baseURL = baseURL.replace('dp', 'v1');
  return axios.post('users/reset-password', params, {baseURL, params: {token}});

};

const changeLocation = (activeLocationId, id) => {
  const params = {
    active_location_id: activeLocationId,
    id: id
  }
  return axios.put(`users/${id}`, params);
};

export default {
  merchantLogin,
  currentuser,
  requestPasswordReset,
  resetPassword,
  changeLocation
};
