import { useSelector } from "react-redux"

const useAuthenticationRouter = () => {
  const isLogin = useSelector(state => state.user.isLogin);
  const currentRole = useSelector(state => state.user.currentRole);
  const authKey = useSelector(state => state.user.authKey);
  const distributorStorate = useSelector(state => state.user.distributor);
  const distributor = localStorage.getItem('distributor') ? localStorage.getItem('distributor') : distributorStorate;

  return { isLogin, currentRole, authKey, distributor }
};

export default useAuthenticationRouter;