import React, { Component, Fragment } from 'react';
import * as $ from 'jquery';
import Highchart from './Highchart';
import HighchartsReact from "highcharts-react-official";
import { isMobile } from 'react-device-detect';
import ShowIf from '../ShowIf/ShowIf';
// const $ = window.jQuery;

class LineChart extends Component {
    render() {
        let showIn = this.props.showIn;
        const container = '#'+this.props.id;
        let chart = {
            type: 'spline'
        };
        
        if (isMobile) {
            chart.height = (9 /16 * 100) + '%';
            chart.style = {
                marginTop: '5px'
            };
            // if ($('html, body').height() !== 0) {
            //     if (this.props.title === '') {
            //         chart.height = $('html, body').height() - 25;
            //         chart.style = {
            //             marginTop: '25px'
            //         };
            //     } else {
            //         chart.height = $('html, body').height() - 5;
            //         chart.style = {
            //             marginTop: '5px'
            //         };
            //     }
            // } else {
            //     if (this.props.title === '') {
            //         chart.height = $('html, body').width() - 25;
            //         chart.style = {
            //             marginTop: '25px'
            //         };
            //     } else {
            //         chart.height = $('html, body').width() - 5;
            //         chart.style = {
            //             marginTop: '5px'
            //         };
            //     }
            // }
        }
        const options = {
            chart: {
                ...chart,
                events: {
                    redraw: function(){
                        const jChart=$(container).highcharts();
                        for (let i = 0; i < jChart.series.length; i++) {
                            const serie = jChart.series[i];
                            let ceroAtStart = false;
                            for (let ii = 0; ii < serie.data.length; ii++) {
                                const point = serie.data[ii]; 
                                if (ii === 0) {
                                    if (point.y === 0) {
                                        ceroAtStart = true;
                                    } else {
                                        ceroAtStart = false;
                                    }
                                } else {
                                    if (point.y !== 0 && ceroAtStart) {
                                        ceroAtStart = false;
                                    }
                                }
                                if (ceroAtStart) {
                                    point.noTooltip = true;
                                    point.marker = {
                                        enabled: false,
                                        states: {
                                            hover: {
                                                enabled: false
                                            }
                                        }
                                    };
                                }
                            }
                            serie.setData(serie.data);
                        }
                    }
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: this.props.title,
                style: {
                    fontWeight: 'bold',
                    height: '40px'
                }
            },
            tooltip: {
                backgroundColor: '#000000',
                borderColor: '#000000',
                borderRadius: 5,
                formatter: function () {
                    if (!this.point.noTooltip > 0) {
                        if (showIn === '$') {
                            return '<span style="color:#FFFFFF;"> $'+ this.y.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2})+'</span>';
                        } else {
                            const num = showIn === 'cs' ? 0 : 2;
                            return '<span style="color:#FFFFFF;">'+ this.y.toLocaleString('en', {minimumFractionDigits: num, maximumFractionDigits: num})+' '+showIn+'</span>';
                        }
                    } else {
                        return false
                    }
                }
            },
            xAxis: {
                categories: this.props.xAxis,
                tickWidth: 1,
                lineWidth: 1,
                labels: {
                    style: {
                        color: '#376386'
                    }
                }
            },
            yAxis: {
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                lineWidth: 1,
                title: {
                    text: ''
                },
                labels: {
                    formatter: function () {
                        if (showIn === '$') {
                            return showIn +  this.value.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
                        } else {
                            return this.value.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' ' + showIn;
                        }
                    }
                },
                style: {
                    color: '#376386'
                }
            },
            series: this.props.data,
            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 4
                    }
                }
            },
            legend: {
                itemStyle: {
                    fontWeight: "regular"
                }
            }
        };

        return (
            <Fragment>
                <ShowIf show={this.props.data.length !== 0}>
                    <Highchart options={options} container={this.props.id} />
                </ShowIf>
                <ShowIf show={this.props.data.length === 0}>
                    {null}
                </ShowIf>
            </Fragment>
        );
    }
}

export default LineChart;
