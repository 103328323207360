import React, { Component, Fragment } from 'react'
import PieChart from '../../../../../SharedComponents/Highcharts/PieChart';
import Legend from './_Legend';
import { isMobile } from 'react-device-detect';
import IconBadge from '../../../../../SharedComponents/IconBadge/IconBadge';
import { MdInfo } from 'react-icons/md';
import HoverWarning from '../../../../../SharedComponents/HoverWarning/HoverWarning';
import * as $ from 'jquery';
// const $ = window.jQuery;

class DoublePieChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingExclamation: true,
            distStyle: {},
            compStyle: {}
        };
    };
    componentDidMount = () => {
        this.timeout = setTimeout(function () {
            this.setState({ loading: false });
        }.bind(this), 500);
    };
    componentWillUnmount = () => {
        clearTimeout(this.timeout)
    };
    render() {
        const props = this.props;
        const { distSales, competitorSales } = props;
        const colors = ['#db71fb', '#fddb72', '#dbcaf6', '#005d6f', '#f76ec1', '#8e2f0f', '#c99e27', '#5f98ef', '#ae626b', '#19bbd7', '#763ada', '#421fde', '#fa8641', '#5f1840', '#0d2e5c'];
        let distributor = {
            data: [{
                y: 100,
                color: '#dce8f2',
                name: 'Hide'
            }],
            hover: false,
            title: '',
            subtitle: 'No order has been placed'
        };

        let competitor = {
            data: [{
                y: 100,
                color: '#dce8f2',
                name: 'Hide'
            }],
            hover: false,
            title: '',
            subtitle: 'No order has been placed'
        };

        if (distSales.sales !== undefined){
            distributor.data[0]= {
                y: distSales.sales,
                name: distSales.distributor,
                color: '#36c371',
                perc: distSales.perc
            }
            distributor.data[1] = {
                y: (100 - parseFloat(distSales.perc)) * (distSales.sales/(parseFloat(distSales.perc))),
                color: '#dce8f2',
                name: 'Hide',
            };
            distributor.hover = isMobile ? false : true;
            distributor.title = Math.round(parseFloat(distSales.perc))+'%';
            distributor.subtitle = '$'+(Math.round(distSales.sales * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }

        if (competitorSales.length > 0) {
            competitor.data = [];
            let addSales = 0;
            let addPerc = 0;
            for (let i = 0; i < competitorSales.length; i++) {
                const e = competitorSales[i];
                const push = {
                    y: e.sales,
                    name: e.distributor,
                    color: colors[i],
                    perc: e.perc
                };
                addSales += e.sales;
                addPerc += parseFloat(e.perc);
                competitor.data.push(push);
            }
            const dist = {
                y: (100 - addPerc) * (addSales/addPerc),
                color: '#dce8f2',
                name: 'Hide'
            };
            competitor.data.push(dist);
            competitor.hover = isMobile ? false : true;
            competitor.title = Math.round(addPerc)+'%';
            competitor.subtitle = '$'+(Math.round(addSales * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }
        let dataAll = [];
        if (distributor.data[0].name !== 'Hide'){
            dataAll.push(distributor.data[0]);
        }
        for (let i = 0; i < competitor.data.length-1; i++) {
            const e = competitor.data[i];
            dataAll.push(e);
        }
        
        const square = {
            background:'#dce8f2',
            width: '80%',
            margin: '0 auto',
            paddingTop: '80%',
            borderRadius: '100px'
        }

        if (!this.state.loading) {
            if (this.state.loadingExclamation) {
                setTimeout(function () {
                    // Position ! perfectly
                    const distId = '#mer-' + this.props.id + '-chart-01';
                    const compId = '#mer-' + this.props.id + '-chart-02';
                    let distStyle = {};
                    let compStyle = {};
                    if (distributor.data.length === 1) {
                        const subtitle = $(distId + ' .highcharts-subtitle');
                        const y = parseInt(subtitle.attr('y'), 10);
                        const h = subtitle[0].getBoundingClientRect().height;
                        distStyle = {
                            top: y + h+'px'
                        };
                    }
                    if (competitor.data.length === 1) {
                        const subtitle = $(compId + ' .highcharts-subtitle');
                        const y = parseInt(subtitle.attr('y'), 10);
                        const h = subtitle[0].getBoundingClientRect().height;
                        compStyle = {
                            top: y + h+'px'
                        };
                    }
                    this.setState({
                        loadingExclamation: false,
                        distStyle: distStyle,
                        compStyle: compStyle
                    });
                }.bind(this), 100);
            }
        }

        return (
            <Fragment>
                <div className='row no-margin mar-top-10 no-gutters mar-bottom-10'>
                    <div className='col-6 text-center'>
                        {this.state.loading ? <div style={square}></div> : <PieChart {...distributor} id={'mer-' + this.props.id + '-chart-01'} />}
                        { ( distributor.data.length === 1 && !this.state.loadingExclamation ) ? 
                            // <HoverWarning style={this.state.distStyle} className='absolute-50-75' fontSize='18px' color='#fce25a' content='Data will appear when an order is placed during the selected time period.' /> : null 
                            <IconBadge idPop={`pop-dist-info_`+this.props.id} 
                                    text='Data will appear when an order is placed during the selected time period.' 
                                    FaIcon={MdInfo} 
                                    color={'#fce25a'}
                                    size={20}                                     
                                    noBack='svgIconPie'/>
                            : null 
                        }
                    </div>
                    <div className='col-6 text-center'>
                        {this.state.loading ? <div style={square}></div> : <PieChart {...competitor} id={'mer-' + this.props.id + '-chart-02'} />}
                        { ( competitor.data.length === 1 && !this.state.loadingExclamation ) ? 
                            // <HoverWarning style={this.state.compStyle} className='absolute-50-75' fontSize='18px' color='#fce25a' content='Data will appear when an order is placed during the selected time period.' /> : null 
                            <IconBadge idPop={`pop-comp-info_`+this.props.id} 
                                    text='Data will appear when an order is placed during the selected time period.' 
                                    FaIcon={MdInfo} 
                                    color={'#fce25a'} 
                                    size={20} 
                                    noBack='svgIconPie'/>
                            : null 
                        }
                    </div>
                </div>
                {!this.state.loading ? (isMobile ? <Legend data={dataAll} /> : null) : null}
            </Fragment>
        );
    }
}

export default DoublePieChart;