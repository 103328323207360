import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { Container, Row, Col } from 'reactstrap';
import { setTimespan, spinner, setItemPage } from '../../../../../reducers/userInterface/userInterfaceActions';
import { setSubheaderTitle } from '../../../../../reducers/subheader/subheaderActions';
// import { showHeaderLogo, showHeaderBack, showHeaderHomeButtons, setHeaderTitle, setHeaderBackUrl } from '../../../actions/headerActions';
import { saveHeaderContent } from '../../../../../reducers/header/headerActions';
import DetailsSubheaderD from '../SubHeader/desktop/dDetailsSubHeader';
import DetailsSubheaderM from '../SubHeader/mobile/mDetailsSubHeader';
import Header from '../../../../../SharedComponents/Header/Header';
import ItemRow from './_ItemRow';
import ItemsRowHeader from './_ItemsRowHeader';
import dashboardApi from '../../../../../api/dashboardApi';
import { setDashboardData, setDashboardDetail, selectedMerchantId, clearDashboardDetail } from '../../../../../reducers/dashboard/dashboardActions';
import '../../../../../config/css/Basic.css';

import * as $ from 'jquery';
// const $ = window.jQuery;

class ItemsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
        };
    };

    componentDidMount = () => {
        const action = this.props.dispatch; 
        const timespan = this.props.match.params.timespan
        const itemsPage = this.props.match.params.itemsPage;
        const merchantId = this.props.match.params.idMerchant;
         
        action(setTimespan(timespan));
        action(setItemPage(itemsPage));
        action(selectedMerchantId(merchantId));
        //Set subheader
        // action(setDesktopSubheader(DetailsSubheaderD));
        // action(setMobileSubheader(DetailsSubheaderM));
        //Set header
        // action(showHeaderLogo(false));
        // action(showHeaderBack(true));
        // action(showHeaderHomeButtons(false));
        // action(setHeaderBackUrl('/dashboard/'+timespan));
        const headerProps = {
            showMenu: false,
            showLogo: false,
            showBack: true,
            showCart: false,
            title: this.state.title,
            backFunct: () => {
                this.clickBack()
            }
        };
        action(saveHeaderContent(headerProps));
        this.loadDashboardData();
        $('html, body').scrollTop(0);
    };
    loadDashboardData = () => {
        const action = this.props.dispatch;
        const merchantId = parseInt(this.props.match.params.idMerchant, 10);
        const timespan = this.props.match.params.timespan
        const startDate = timespan === 'custom' ? this.props.startDate : null;
        const endDate = timespan === 'custom' ? this.props.endDate : null;
        if (this.props.data.length === 0) {
            action(spinner(true));
            dashboardApi.dashboard(timespan, startDate, endDate).then(({data}) => {
                action(setDashboardData(data.data, timespan));
                for(let i=0; i<data.data.length; i++) {
                    let row = data.data[i]
                    if (row.merchantId === merchantId) {
                        this.setState({ title: row.merchant });
                        // action(setSubheaderTitle(row.merchant));
                        break;
                    }
                }
                action(spinner(false));
                this.loadDetailData();
            });
        } else {
            for(let i=0; i<this.props.data.length; i++) {
                let row = this.props.data[i]
                if (row.merchantId === merchantId) {
                    this.setState({ title: row.merchant });
                    // action(setSubheaderTitle(row.merchant));
                    break;
                }
            }
            this.loadDetailData();
        }
    }
    loadDetailData = () => {
        const action = this.props.dispatch;
        const merchantId = this.props.match.params.idMerchant;
        const timespan = this.props.match.params.timespan
        const startDate = timespan === 'custom' ? this.props.startDate : null;
        const endDate = timespan === 'custom' ? this.props.endDate : null;
        action(clearDashboardDetail());
        action(spinner(true));        
        dashboardApi.dashboardDetail(timespan, merchantId, startDate, endDate).then(({data}) => {
            action(setDashboardDetail(data.data));
            action(spinner(false));
        });
    }

    clickBack = () => {
        this.props.history.push('/dashboard/'+this.props.match.params.timespan);
    }

    render() {      
        // const dashboardClass = isMobile ? 'container-fluid has-sub-header mobile pad-sides-15' : 'container-fluid has-sub-header pad-sides-15';
        const dashboardClass = isMobile ? 'mobile pad-sides-15' : 'mt-4 pad-sides-15';
        const section = this.props.match.params.itemsPage.slice(5);
        const showDin = this.props.match.params.itemsPage === 'itemswon';
        const showNotStocked = this.props.match.params.itemsPage === 'itemsmissing';

        let header = {
            title: section === 'missing' ? 'Items Missing From Order Guide' : 'Total Items ' + section.charAt(0).toUpperCase() + section.slice(1),
            percentage: '0%',
            totalCase: '0 cs',
            totalQty: '$0.00',
            showDin: showDin,
            showNotStocked : showNotStocked,
            sort: this.props.sort,
            asc: this.props.asc,
            dispatch: this.props.dispatch
        };
        let detailData = this.props.detailData[this.props.itemsPage];
        let items;
        let existData = detailData === undefined ? false : (detailData.length === 0 ? false : (detailData.items.length === 0 ? false : true));
        if (existData) {
            header.percentage = detailData.percentage;
            header.totalQty = detailData.totalQty.toLocaleString('en')+' cs';
            header.totalCase = '$'+(Math.round(detailData.totalCase * 100) / 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            items = detailData.items;
           
            switch(this.props.sort) {
                case 'price':
                    this.props.asc ?
                        detailData.items.sort(function(a,b) { return a.price - b.price; }) :
                        detailData.items.sort(function(a,b) { return b.price - a.price; });
                    break;

                case 'unitPrice':
                    this.props.asc ?
                        detailData.items.sort(function(a,b) {  return a.unitPrice - b.unitPrice; }) :
                        detailData.items.sort(function(a,b) {  return b.unitPrice - a.unitPrice; });
                    break;

                case 'total_purchased':
                    this.props.asc ?
                        detailData.items.sort(function(a,b) { return a.totalPurchased - b.totalPurchased; }) :
                        detailData.items.sort(function(a,b) { return b.totalPurchased - a.totalPurchased; });
                    break;
                case 'total_quantity':
                    this.props.asc ?
                        detailData.items.sort(function(a,b) { return a.totalQuantity - b.totalQuantity; }) :
                        detailData.items.sort(function(a,b) { return b.totalQuantity - a.totalQuantity; });
                    break;
                default:
                    break;
            }           
            
        }
        return (
            <Container fluid={true} className={dashboardClass}>
                <Header showMenu={false} backFunct={() =>this.clickBack()} showLogo={false} showBack={true} title={this.state.title} showCart={false}>
                    <ShowIf show={!isMobile}> <DetailsSubheaderD {...this.props.match.params}/> </ShowIf>
                    <ShowIf show={isMobile}> <DetailsSubheaderM {...this.props.match.params}/>  </ShowIf>
                </Header>
                <div className={isMobile ? 'row' : 'container-detail'} >
                    <ShowIf show={existData}>
                        <ItemsRowHeader {...header} />
                        {items === undefined ? '' : items.map((item, index) => <ItemRow match={this.props.match} history={this.props.history} showDin={showDin} showNotStocked={showNotStocked} savings={false} rowId={index} dispatch={this.props.dispatch} {...item} key={item.id + '-' + index}/>)}
                    </ShowIf>
                    <ShowIf show={!existData}>
                        <div className={isMobile ? 'container-detail mobile pad-vertical-15 row mar-top-15 mar-bottom-15' : 'row mar-top-15 mar-bottom-15'}>
                            <div className='col text-center'>No Items Found.</div>
                        </div>
                    </ShowIf>                    
                </div>                
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        timespan: state.userInterface.timespan,
        itemsPage: state.userInterface.itemsPage,
        merchantId: state.dashboard.merchantId,
        data: state.dashboard.data,
        detailData: state.dashboard.detailData,
        spinner: state.userInterface.spinner,
        sort: state.userInterface.sort,
        asc: state.userInterface.asc,
        startDate: state.dashboard.startDate,
        endDate: state.dashboard.endDate,
        isAvailableToSort: state.userInterface.isAvailableToSort,
    }
}

export default connect(mapStateToProps)(ItemsPage);
