import axios from 'axios';
import moment from 'moment';

const all = () =>{
    return axios.get('/distributors');
};

const dashboard = (filter, startDate, endDate) => {
    let params = {
        filterType : filter
    }
    if(filter=='custom'){
        params.startDate = (startDate=='') ? moment().format('yyyy-MM-DD') : startDate;
        params.endDate = (endDate=='') ? moment().format('yyyy-MM-DD') : endDate;
    }
    return axios.get('/dashboard', { params });
};

const dashboardDetail = (filter, merchant, startDate, endDate) => {
    return axios.get('/dashboard/'+merchant+'/detail', {
        params: {
            filterType: filter,
            startDate: startDate,
            endDate: endDate
        }
    });
};

const dashboardGraph = (merchant) => {
    return axios.get('/dashboard/' + merchant + '/graph');
};

const dashboardGraphItem = (merchant, itemDistId, itemsPage) => {
    return axios.get('/dashboard/' + merchant + '/graph/' + itemDistId, {
        params: {
            page: itemsPage
        }
    });
};

const dashboardCalendar = (merchant, item, page) => {
    return axios.get('/dashboard/'+merchant+'/calendar/'+item, {
        params: {
            page: page
        }
    });
};

export default {
    all: all,
    dashboard: dashboard,
    dashboardDetail: dashboardDetail,
    dashboardGraph: dashboardGraph,
    dashboardGraphItem: dashboardGraphItem,
    dashboardCalendar: dashboardCalendar
};
