import axios from 'axios';
import moment from 'moment-timezone';


const get = () => {
  return axios.get('/distributors');
};

const getById = (id) => {
  const timezone=moment.tz.guess();
  const params = {timezone: timezone};
  return axios.get(`/distributors/${id}`, {params});
};

const list = (params) => {
  return axios.get('distributors/list', {
    params: params
  });
};

const retrieveFromUser = () => {
  const token = localStorage.getItem('authKey');
  const options = {};
  if (token) {
    options.headers = {
      'Authorization' : 'Bearer ' + token
    };
  }
  return axios.get('distributors/from-user', options);
};

const savings = (merchantId, itemDistributorId, filter) => {
  return axios.post('/distributors/savings', {
      merchantId: merchantId,
      itemDistributorId: itemDistributorId,
      filterType: filter
  });
};

const stocks = (merchantId, itemDistributorId, notStocked) =>{
  return axios.post('/distributors/stocks', {
      merchant_id : merchantId,
      item_distributor_id : itemDistributorId,
      not_stocked : notStocked
  });
}


export default {
  get, getById, list, retrieveFromUser,
  savings: savings,
  stocks : stocks
};
