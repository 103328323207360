import React, { Component } from 'react'
import { isMobile } from 'react-device-detect';
import DoublePieChart from './_DoublePieChart';
import { Card, Row, Col, Button } from 'reactstrap';

class ClientCard extends Component {
    goToDetails = () => {
        this.props.history.push('/dashboard/details/itemsmissing/'+this.props.merchantId+'/'+this.props.timespan);
    };
    render() {
        const props = this.props;
        const nickname = JSON.parse(localStorage.getItem('distributor')) === null ? '' : JSON.parse(localStorage.getItem('distributor')).nickname;
        return (

            <Col className={ isMobile ? 'col-12 px-4' : 'col-sm-6 col-md-4 col-lg-4 col-xl-3 px-2'}>
                <Card className={`py-3 client-card shadow-none border ${isMobile ? `mb-2` : `mb-4`}`}>
                    <Row className='mt-3 mb-4 px-3 d-flex align-item-center justify-content-center text-capitalize' style={{height:'50px', fontSize:'17px'}} > {props.merchant} </Row>
                    <Row className='m-0 mt-30'>
                        <Col className='text-center text-uppercase text-bold' style={{fontSize:'12px'}} >{ nickname }</Col>
                        <Col className='text-center text-uppercase text-bold' style={{fontSize:'12px'}} >Competitors</Col>
                    </Row>
                    <DoublePieChart {...props}/>
                    <Row className='my-4'>
                        <Col className={`text-center ${isMobile ? `px-4` : ``}`} >
                            <Button onClick={this.goToDetails} className='btn-outlined text-bold' style={{fontSize:'17px'}} block={isMobile ? true : false} >See Details</Button>
                        </Col>
                    </Row>
                </Card>
            </Col>            
        );
    }
}

export default ClientCard;