export const saveHeaderContent = (contents) => {
    return {
        type: 'SET_HEADER_CONTENTS_REVIEW_ORDER',
        payload: contents
    }
};


export const saveHeaderIndexRevOrder = (index) => {
    return {
        type: 'SET_HEADER_INDEX_REVIEW_ORDER',
        payload: index
    }
};


//////////////////////////////////////////////////

// export const showHeaderLogo = (bool) => {
//     return {
//         type: 'SHOW_LOGO',
//         payload: bool
//     }
// }
// export const showHeaderBack = (bool) => {
//     return {
//         type: 'SHOW_BACK',
//         payload: bool
//     }
// }
// export const showHeaderHomeButtons = (bool) => {
//     return {
//         type: 'SHOW_HOME_BUTTONS',
//         payload: bool
//     }
// }
// export const setHeaderTitle = (title) => {
//     return {
//         type: 'SET_TITLE',
//         payload: title
//     }
// }
// export const setHeaderBackUrl = (url) => {
//     return {
//         type: 'SET_BACK_URL',
//         payload: url
//     }
// }
// export const setLoginHeader = (bool) => {
//     return {
//         type: 'SET_LOGIN',
//         payload: bool
//     }
// }
// export const hideHeader = (bool) => {
//     return {
//         type: 'HIDE_HEADER',
//         payload: bool
//     }
// }