import React from 'react';
import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import './ChangePassword.scss';
import ChangePasswordHeader from './ChangePasswordHeader';
import ChangePasswordForm from './ChangePasswordForm';
import useChangePassword from './useChangePassword';
import logo from '../../assets/images/foodbam-logo.png';

const ChangePassword = (props) => {
  const { match } = props;
  const { token } = match.params;
  const  formProps = useChangePassword.useForm(token);

  return (
    <Container fluid={true} className='reset-app p-0'>
      <ChangePasswordHeader logo={logo} />
      <ChangePasswordForm {...formProps}/>
    </Container>
  );
};

export default ChangePassword;
