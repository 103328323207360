export const spinner = (bool) => {
  return {
    type: 'SET_SPINNER',
    payload: bool
  };
};

export const toggleSidebar = () => {
  return {
    type: 'TOGGLE_SIDEBAR',
  };
};

export const closeSidebar = () => {
  return {
    type: 'CLOSE_SIDEBAR',
  };
};

export const setSize = (w, h) => {
  return {
    type: 'SET_SIZE',
    payload: {
      width: w,
      height: h
    }
  };
};


/////////////////////////


export const setTimespan = (timespan) => {
  return {
      type: 'SET_TIMESPAN',
      payload: timespan
  }
}

export const saveStartDate = (startDate) => {
  return {
      type: 'SET_STARTDATE',
      payload: startDate
  }
}

export const saveEndDate = (endDate) => {
  return {
      type: 'SET_ENDDATE',
      payload: endDate
  }
}

export const saveMerchant = (merchant) => {
  return {
      type: 'SET_MERCHANT',
      payload: merchant
  }
}

export const setItemPage = (page) => {
  return {
      type: 'SET_PAGE',
      payload: page.toLowerCase()
  }
}
// export const spinner = (bool) => {
//   return {
//       type: 'SET_SPINNER',
//       payload: bool
//   }
// }

export const setMessages = (messages) => {
  return {
      type: 'SET_MESSAGES',
      payload: messages
  }
}
export const isGuess = (bool) => {
  return {
      type: 'SET_IS_GUESS',
      payload: bool
  }
}
export const setSort = (sort, asc) => {
  return {
      type: 'SET_SORT',
      payload: {
          sort: sort,
          asc: asc
      }
  }
}

export const setShowInGraph = (showIn) =>{
  return {
      type:'SET_SHOW_IN_GRAPH',
      payload : showIn
  }
}