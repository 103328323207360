
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import OrderGuideList from "./OrderGuideList/OrderGuideList";
import OrderGuideDetail from "./OrderGuideDetail/OrderGuideDetail";


const OrderGuide = (props) => {
    
    return (
        <Switch>        
            <Route exact path='/order-guide'>
                <OrderGuideList/>
            </Route>
            <Route exact path={`/order-guide/:id`}>
                <OrderGuideDetail/>
            </Route>
            <Route>
                <Redirect to='/order-guide' />
            </Route>
        </Switch>
        
    )
}

export default OrderGuide;