import React from 'react';
import { Col } from 'reactstrap';
import './Header.scss';
import ShowIf from '../ShowIf/ShowIf';
import logo from '../../assets/images/foodbam-logo.png';

const HeaderLogo = (props) => {
  const { show } = props;
  
  return (
    <ShowIf show={show}>
      <Col className='w-fc'>
        <img src={logo} alt='Company Logo' className='company-logo' />
      </Col>
  </ShowIf>
  );
};

export default HeaderLogo;