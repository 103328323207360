import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Button, Form } from 'reactstrap';
import { showModal } from '../../reducers/modals/modalsActions';
import ForgotPasswordModal from '../../SharedComponents/Modals/ForgotPasswordModal/ForgotPasswordModal';
import LoginFormInput from '../Login/LoginFormInput';

const SignupForm = (props) => {
    const { setPassword, password, setConfirmPassword, confirmPassword, onSubmitSU, error } = props;
    const history = useHistory();
    const dispatch = useDispatch();


    return (
        <Form className='login-form flex-column m-auto' noValidate="novalidate" onSubmit={onSubmitSU}>
            <Col className='mt-5 text-center mb-5'>
                <h4>Create Account</h4>
            </Col>
            {/* <LoginFormInput label='Email' type='email' placeholder='Email' value={email} set={setEmail} error={error.email ? error.email[0] : ''} /> */}
            <LoginFormInput label='Password' type='password' placeholder='Password' value={password} set={setPassword} error={error.password ? error.password[0] : ''} />
            <LoginFormInput label='Confirm Password' type='password' placeholder='Confirm Password' value={confirmPassword} set={setConfirmPassword} error={error.confirmPassword ? error.confirmPassword[0] : ''} />
            <Col className='text-center mt-5'>
                <Button color='blue' size='lg' className='w-100' type='submit'>SIGN UP</Button>
            </Col>
            <Col className='text-danger text-center'>{error.error ? error.error : ''}</Col>
            {/* <Col className='mt-5 text-center text-blue clickable' onClick={() => dispatch(showModal(ForgotPasswordModal))}>FORGOT PASSWORD</Col> */}

        </Form>
    );
};

export default SignupForm;
