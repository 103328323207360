import axios from 'axios';

const getMessages = () =>{
    return axios.get('/distributors/messages');
};

const readMessages = (messages) => {
    return axios.post('/distributors/messages', messages);
};

export default {
    getMessages: getMessages,
    readMessages: readMessages
};