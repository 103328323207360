import React, { Component } from 'react';
import { withRouter } from 'react-router';
import '../Dashboard.scss';

class Link extends Component {
    componentWillMount() {
        this.unlisten = this.props.history.listen(location => {
            this.setState({location});
        });
    };
    componentWillUnmount() {
        this.unlisten();
    };
    goTo = (e) => {
        e.preventDefault();
        this.props.history.push(this.props.path);
        this.props.action(this.props.value);
    };
    render() {
        const props = this.props;
        if (props.modalId === undefined) {
            return (
                <a href={props.path} onClick={this.goTo} className={`col-3 clickable header-link ${props.path === props.history.location.pathname ? `active` : `` }` + (props.className ? ` ${props.className}` : '')}>
                    <div>{props.title}</div>
                    {/* {props.path === props.history.location.pathname ? <div className='header-link active absolute-100' ></div> : null} */}
                </a>
            );
        } else {
            return (
                <a data-keyboard={this.props.static ? 'false' : 'true'} data-backdrop={this.props.static ? 'static' : null} data-toggle="modal" onClick={this.goTo} data-target={'#'+props.modalId} className={`col-3 clickable header-link ${props.path === props.history.location.pathname ? `active` : `` }` + (props.className ? ` ${props.className}` : '')}>
                    <div>{props.title}</div>
                    {/* {props.path === props.history.location.pathname ? <div className='header-link active absolute-100' ></div> : null} */}
                </a>
            )
        }
    }
}

export default withRouter(Link);