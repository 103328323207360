import React, { Fragment, useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isMobile } from 'react-device-detect';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import './OrderGuideUpload.scss';
import * as $ from 'jquery';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';
import { allowedOGFileTypes, OGFileSizeLimit } from "../../../../../config/constants";
import excelIcon from '../../../../../assets/images/excel-icon.jpg';
import excelSvg from '../../../../../assets/images/excel-icon.svg';
import csvISvg from '../../../../../assets/images/csv-icon.svg';
import OrderGuideHistory from './OrderGuideHistory';
import orderGuideApi from '../../../../../api/orderGuideApi';
import merchantApi from '../../../../../api/merchantApi';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../reducers/modals/modalsActions';
import AlertModal from "../../../../../SharedComponents/Modals/AlertModal/AlertModal";
import AllMerchantsModal from './AllMerchantsModal';
import NoTemplateModal from './NoTemplateModal';
import { colors, localStorageConst, OGExpirationDays } from '../../../../../config/constants';
import OrderGuideUploadSelect from './OrderGuideUploadSelect';
import { saveSelectedMerchant, clearSelectedMerchant, saveSelectedMerchants, clearSelectedMerchants } from '../../../../../reducers/orderGuide/orderGuideActions';

import { debounce } from 'lodash';

const OrderGuideUpload = (props) => {
    const dispatch = useDispatch();
    const { merchants } = props;
    const distributorLocal = JSON.parse(localStorage.getItem(localStorageConst.keys.distributor));
    const distributorStore = useSelector(({ user }) => user.distributor);    
    const [distributor, setDistributor] = useState(!!distributorLocal ? distributorLocal : distributorStore);
    const selectedMerchantStore = useSelector(({ orderGuide }) => orderGuide.selectedMerchant);
    const selectedMerchantsStore = useSelector(({ orderGuide }) => orderGuide.selectedMerchants);
    

    const [options, setOptions] = useState([]);
    const [merchant, setMerchant] = useState(selectedMerchantStore);
    const [selectedMerchants, setSelectedMerchants] = useState(selectedMerchantsStore);
    const [showMore, setShowMore] = useState(selectedMerchants.length>0 ? true : false);
    const [showSelectedFile, setShowSelectedFile] = useState(false);
    const [file, setFile] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(moment(new Date()).add(OGExpirationDays, 'days').toDate());
    const [days, setDays] = useState(0);
    const [historyMap, setHistoryMap] = useState('');

    
    const changeMerchant = (e, show) => {
        setMerchant(e);
        getAvailableTemplates();
        if(show){            
            dispatch(saveSelectedMerchant(e));
            showAllMerchants(e);
        }
    };
    
    const getAvailableTemplates = () => {
        orderGuideApi.getAvailableTemplates(distributor.id).then((res) => {
            const result = res.data.data.data;            
            const daysToExpire = (result.length>0) ? result[0].days_to_expire : OGExpirationDays;
            setDays(daysToExpire);
            const newEndDate = moment(startDate).add(daysToExpire, 'days');
            setEndDate(moment(newEndDate).toDate());
        })
        .catch(error => { });
    };

    const showAllMerchants = (e) => {
        setShowMore(false);
        if(distributor.isPartner){
            merchantApi.getAllowedChildrenPartner(e.value)
            .then((res) => {
                const result = res.data.data;
                if(result.length > 1){
                    const allMerchants = result.map(m => {
                        return {                
                            id: m.id,
                            nickname: m.nickname, 
                            value: true,
                        }
                    });
                    
                    const props = {
                        allMerchants: allMerchants,
                        onSelect: setSelectedMerchants,
                    };
                    dispatch(showModal(AllMerchantsModal, props));
                }else{
                    setSelectedMerchants(result);
                    dispatch(saveSelectedMerchants(result));
                }
                
            })
            .catch(error => { });        
        }else{
            merchantApi.getAllowedChildren(e.value)
            .then((res) => {
                const result = res.data.data;
                if(result.length > 1){
                    const allMerchants = result.map(m => {
                        return {                
                            id: m.id,
                            nickname: m.nickname, 
                            value: true,
                        }
                    });
                    
                    const props = {
                        allMerchants: allMerchants,
                        onSelect: setSelectedMerchants,
                    };
                    dispatch(showModal(AllMerchantsModal, props));
                }else{
                    setSelectedMerchants(result);
                    dispatch(saveSelectedMerchants(result));
                }
                
            })
            .catch(error => { });        
        }        
    };

    const openFileDialog = () => {
        $('#file-upload').click();
    };

    const selectFile = () => {
        const newFile = $('#file-upload').prop('files')[0];
        if(newFile){
            setFile(newFile);
            setShowSelectedFile(true);
        }else{
            setShowSelectedFile(false);
        }        
    };

    const deleteFile = () => {
        setFile(null);
        setShowSelectedFile(false);     
    };

    const resetUpload = () => {
        setTimeout(() => {
            deleteFile();
            setStartDate(new Date());
            getAvailableTemplates();
            setHistoryMap('');
            setHistoryMap(<OrderGuideHistory merchant={merchant} distributor={distributor}/>);
        }, 2000);
    };

    const upload = () => {
        const params = {
            file : file,
            fastResponse : 1,
            merchantIds : selectedMerchants.map(sm => { return sm.id; }),
            distributor_id : distributor.id,
            start_date : moment(startDate).format('YYYY-MM-DD'),
            end_date : moment(endDate).format('YYYY-MM-DD'),
        };
        
        orderGuideApi.upload(params).then((res) => {
            const result = res.data.data;
            const alertProps = {
                title: 'Confirmation',
                onOk : resetUpload(),
                message: result.message
            };
            dispatch(showModal(AlertModal, alertProps));
        })
        .catch(error => {
        });

    };
    
    const preupload = () => {
        orderGuideApi.getMatchingTemplates(distributor.id, file).then((res) => {
            const template = res.data.data;
            if(!!template){
                if(template.length===0){
                    dispatch(showModal(NoTemplateModal, { onOk: requestTemplate }));    
                }else{
                    // if (moment().isSame(moment(startDate).format('YYYY-MM-DD'), 'day')){
                    //     upload();
                    // }else{
                        delayedClickUpload();
                    // }
                }                
            }else{
                dispatch(showModal(NoTemplateModal, { onOk: requestTemplate }));
            }
        })
        .catch(error => {
        });
    };
    
    const requestTemplate = () => {
        const merchantIds = selectedMerchants.map(sm => { return sm.id; });

        orderGuideApi.uploadTemplate(distributor.id, file, merchantIds).then((res) => {
            const template = res.data.data;
            const alertProps = {
                title: 'Confirmation',
                message: 'Successful request',
                onOk : resetUpload(),
            };
            dispatch(showModal(AlertModal, alertProps));
        })
        .catch((error) => {
            const res = error.response;
            const alertProps = {
                title: 'Error!',
                message: (!!res.data.data) ? res.data.data.message : 'There was an error while submiting your template'
            };
            dispatch(showModal(AlertModal, alertProps));
        });
    };

    useEffect(() => {
        if(Object.entries(selectedMerchantStore) && selectedMerchantsStore.length>0){
            if(merchants.length>0){
                setSelectedMerchants(selectedMerchantsStore);
                const nOpts = merchants.map(m => {
                    return {
                        label: m.nickname + (m.id === selectedMerchantStore.id ? ' (' + selectedMerchantsStore.length + ')' : '') ,
                        value: m.id,
                        id: m.id,
                    }
                });
                setOptions(nOpts);
                const merch = nOpts.filter(opt => opt.id === selectedMerchantStore.id)[0];
                changeMerchant({ label: merch.label, value: merch.id, id: merch.id }, false);
                setShowMore(true);
            }            
        }else{
            setOptions(merchants.map(m => {
                return {
                    label: m.nickname,
                    value: m.id,
                    id: m.id,
                }
            }));
            setMerchant([]);
            setShowMore(false);
        }
    }, [merchants]);

    
    useEffect(() => {
        const newOptions = merchants.map(m => {
            return {
                label: m.nickname + (m.id === merchant.id ? ' (' + selectedMerchants.length + ')' : '') ,
                value: m.id,
                id: m.id,
            }
        });
        setOptions(newOptions);
        setMerchant(newOptions.filter(m => (m.id === merchant.id))[0]);
        setShowMore(true);
    }, [selectedMerchants]);


    useEffect(() => {
        if(merchant){
            setHistoryMap(<OrderGuideHistory merchant={merchant} distributor={distributor}/>);
        }
    }, [merchant]);


    useEffect(() => {
        const newEndDate = moment(startDate).add(days, 'days');
        setEndDate(moment(newEndDate).toDate());
    }, [startDate]);
  

    const delayedClickUpload = debounce(() => upload(), 1500);

  return (
    <Fragment>
        <Row className='pt-3'>
            <Col xs={3} className='text-left text-gray'>
                Customer
            </Col>
            <ShowIf show={showMore}>
                <Col xs={3} className='text-left text-gray'>
                    Order Guide Date
                </Col>
                <Col xs={3} className='text-left text-gray'>
                    Next Price Change
                </Col>
            </ShowIf>
        </Row>
        <Row className='py-2'>
            <Col xs={3} className='text-left'>
                <OrderGuideUploadSelect id='customer' placeholder='Select Customer' options={options} set={(e) => changeMerchant(e, true)} val={merchant}/>
            </Col>
            <ShowIf show={showMore}>
                <Col xs={3}>
                    <DatePicker id='startDateInput'
                        className={`selectInput`}
                        selected={startDate}
                        dateFormat={"MMMM dd, yyyy"}
                        onChange={date => setStartDate(date)} 
                        minDate={moment().toDate()}/>
                </Col>
                <Col xs={3}>
                    <DatePicker id='endDateInput'
                        className={`selectInput`}
                        selected={endDate}
                        dateFormat={"MMMM dd, yyyy"}
                        onChange={date => setEndDate(date)} 
                        minDate={moment(startDate).toDate()}/>
                </Col>
            </ShowIf>
        </Row>
        <ShowIf show={showMore}>
            <ShowIf show={!showSelectedFile}>
                <Row className='py-2'>
                    <Col className='text-center'>
                        <Row className='m-1 upload-file-outline d-flex flex-direction-column align-items-center justify-content-center' onClick={openFileDialog}>
                            <Col>
                                <MdCloudUpload  color={colors.lightBlueText} style={{fontSize:'40px'}}/><br/><br/>
                                Drag Order Guide here or click to Upload
                            </Col>
                        </Row>
                        <input className='d-none' onChange={() => selectFile()} type='file' id='file-upload' accept=".xls,.xlsx,.csv"/>
                    </Col>
                </Row>
            </ShowIf>
            <ShowIf show={showSelectedFile}>
                <Row className='py-2'>
                    <Col xs={3} className='text-left'>
                        <img src={excelIcon} style={{widh: '2em', height: '2em', paddingRight: '1em'}} />
                        {file ? file.name : ''}
                    </Col>
                    <Col xs={1} className='text-left'>
                        <MdDelete  color={colors.lightBlueText} onClick={() => deleteFile()} style={{fontSize:'20px'}}/>
                    </Col>  
                </Row>
                <Row className='py-4'>
                    <Col xs={1} className='text-left'>
                        <Button onClick={() => preupload()} color='green' block>Upload</Button>
                    </Col>
                </Row>
            </ShowIf>        
            
            {historyMap}
        </ShowIf>
    </Fragment>
    
  );
};

export default OrderGuideUpload;