import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import useSidebar from './useSidebar';
import { MdTrendingUp } from 'react-icons/md';
import { IoMdCloudUpload } from 'react-icons/io';
import ShowIf from '../../SharedComponents/ShowIf/ShowIf';
import { localStorageConst } from '../../config/constants';

const SidebarBody = () => {
  const dispatch = useDispatch();
  const activeLocation = useSelector(({ user }) => user.activeLocation);
  const companyLogoUrl = activeLocation && activeLocation.company && activeLocation.company.logo_url;

  const distributor = JSON.parse(localStorage.getItem(localStorageConst.keys.distributor));
  const {clickClose} = useSidebar.useActions(dispatch);

  return (
    <Row className='sidebar-body py-3 px-3'>
      <Col xs={12}>        
        <h4 className='fb-heading-title'>SETTINGS</h4>
        <ul className='main-navigation'>
          <ShowIf show={distributor.isPartner}>
            <li><NavLink to='/dashboard/weekly' activeClassName='active' onClick={clickClose}><MdTrendingUp color='#ffffff' className='analytics-menu' />Dashboard</NavLink></li>
            <li><NavLink to='/order-guide' activeClassName='active' onClick={clickClose}><IoMdCloudUpload color='#ffffff' className='analytics-menu' />Upload Order Guide</NavLink></li>
          </ShowIf>
          
          <ShowIf show={!distributor.isPartner}>
            <li><NavLink to='/order-guide' activeClassName='active' className='order-guide-menu' onClick={clickClose}>Order Guide</NavLink></li>
          </ShowIf>
                    
          <li><NavLink to='/logout' className='log-out-menu'>Log Out</NavLink></li>
        </ul>        
      </Col>      
    </Row>

  );
};

export default SidebarBody;
