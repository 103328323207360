import React, { Component } from 'react';
import Highcharts from 'highcharts';

class Highchart extends Component {
    componentDidMount = () => {
        this.chart = new Highcharts[this.props.type || 'Chart'](
            this.props.container,
            this.props.options
        );
    };
    componentWillUnmount = () => {
        if(!!this.chart){
            this.chart.destroy();
        }      
    };
    render() {
        return (
            <div className='chart' id={this.props.container} ></div>
        );
    }
}

export default Highchart;